import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../Helpers/getCroppedImg";

const SettingsSignModal = ({ isOpen, onRequestClose, onSave }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(file);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragOver: () => console.log('over'),
        accept: "image/*",
        multiple: false,
    });

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const handleSave = async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedArea);
            console.log(croppedImage);
            onRequestClose();
        } catch (e) {
            console.error(e);
        }
    };

    const handleClose = () => {
        setImageSrc(null);
        onRequestClose();
    };

    if (!isOpen) return null;

    return (
        <div className="settings__modal-content settings__modal-content_sign">
            <div className="dashboard__modal-title">Добавить подпись</div>

            {imageSrc ? (
                <div className="crop-wrapper">
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={3 / 3}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
            ) : (
                <div className="dashboard__modal-load-area" draggable={true}>
                    <div className="dashboard__modal-caption">
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>Перетащите изображение в область загрузки или выберите расположение</p>
                        </div>
                    </div>
                </div>
            )}

            {imageSrc && (
                <div className="dashboard__modal-buttons">
                    <button className="dashboard__menu-button button__destination" onClick={handleClose}>
                        Закрыть
                    </button>
                    <button className="dashboard__menu-button button__save" onClick={handleSave}>
                        Сохранить
                    </button>
                </div>
            )}

            <div className={`settings__exit`} onClick={onRequestClose}>
                <img src={`/img/modal__exit.svg`} alt={``} />
            </div>
        </div>
    );
};

export default SettingsSignModal;
