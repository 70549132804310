const wordCasesForDocuments = {
    calendarDaysCase: {singular: `ый`, plural: `ых`},
    workingDaysCase: {singular: `ий`, plural: `их`},
    daysCase: {singular: `день`, plural: `дней`, dual: `дня`},
}

export const resolveWordCasesForDocuments = (daysInputValue) => {
    if (daysInputValue) {
        const parsedNumber = Object.assign([], '' + daysInputValue);
        const lastNumberInDaysCount = Number(parsedNumber[parsedNumber.length - 1]);
        let result = [];
        if (Number(daysInputValue) > 10) {
            result = ['plural', 'plural'];
        } else if (lastNumberInDaysCount === 1) {
            result = ['singular', 'singular'];
        } else if ((lastNumberInDaysCount > 1 && lastNumberInDaysCount < 5)) {
            result = ['dual', 'plural'];
        } else {
            result = ['plural', 'plural'];
        }

        return {
            calendar: wordCasesForDocuments.calendarDaysCase[result[1]],
            working: wordCasesForDocuments.workingDaysCase[result[1]],
            days: wordCasesForDocuments.daysCase[result[0]],
        };
    } else return null;
};