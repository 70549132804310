import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import moment from "moment";

const RECOGNITION_TEMPLATE = `Не распознано`;
const MonitoringContract = ({contract, contractDisplay, callbackOnClick, isSelected, showEventLogModalCallback }) => {
    const dispatch = useDispatch();

    const [hover, setHover] = useState(false);
    const [contractor, setContractor] = useState(RECOGNITION_TEMPLATE);

    const iconPath = (title) => `/img/monitoring/content-icons/monitoring__${title}.svg`;

    useEffect(() => {
        const contractorName = contract.sides_of_contract && contract.sides_of_contract[0]?.name;
        if (contractorName) {
            setContractor(contractorName);
        }
    }, []);

    const handleMouseLeave = (e) => setHover(false);
    const handleMouseOver = (e) => setHover(true);

    const handleClick = (e) => {
        // console.log(contract);
        callbackOnClick(contract);
    }

    const showEventLogModal = (e) => {
        showEventLogModalCallback()
    }

    return (
        <div
            className={clsx({ 'contract__selected': isSelected }, `monitoring__contract-entity`)}
            onClick={handleClick}
        >
            <div className={`monitoring-contract__row`}>
                <div className={`monitoring-contract__content-left`}>
                    <div className={`monitoring-contract__icon`}>
                        <img
                            src={iconPath('contract')}
                            alt={``}
                        />
                    </div>
                    <div
                        className={`monitoring-contract__name`}
                        onMouseOver={handleMouseOver}
                        onMouseLeave={handleMouseLeave}

                    >
                        {contract.name_of_contract ?? contract.subject_of_contract}
                    </div>

                    {contractDisplay === 'column' && (
                        <div className={clsx(
                            `monitoring-contract__name-on-hover`,
                            { [`hover`]: hover })}
                        >
                            {contract.name_of_contract}
                        </div>
                    )}

                    <div className={clsx(`monitoring-contract__side`, {[`side__transparent`]: contractor === RECOGNITION_TEMPLATE})}>
                        {contractor}
                    </div>
                </div>
                <div className={`monitoring-contract__status-row`}>
                    <div className={`monitoring-status__date`}>
                        {moment(contract.created_at).format('DD/MM/YY HH:mm')}
                    </div>
                    <div
                        data-contract-id={contract?.id}
                        className={
                        clsx(
                            `monitoring-status__status`,
                            { 'status__control': !!contract.is_on_control }
                        )}
                    >
                        {(contract.is_on_control) ? 'На контроле' : 'Создано' }
                    </div>
                    <div
                      className={`monitoring-status__notifications`}
                      onClick={showEventLogModal}
                    >
                        <img
                            className={`monitoring-notifications__icon`}
                            src={iconPath('notifications')}
                            alt={``}
                        />
                        <span className={`monitoring-notifications__name`}>
                            12
                        </span>
                    </div>
                </div>
            </div>
        </div>);
};

export default MonitoringContract;