import React from "react";
import clsx from "clsx";

const Loader = ({ isOnLoad }) => {
    return (
        <div className={clsx({ hidden: !isOnLoad }, "documents__load")}>
            <img src={`../../img/documents/control__load.svg`} alt={``} />
            Идет загрузка...
        </div>
    );
};

export default Loader;
