import axios from "axios";
import { current } from "@reduxjs/toolkit";

// eslint-disable-next-line no-restricted-globals
const localhostSettings = `${location.protocol}//${location.host}/`;

//console.log(localhostSettings);
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

// eslint-disable-next-line no-restricted-globals
export const APP_URL =
    process.env.NODE_ENV === "development"
        ? localhostSettings // в первом случае используется прокси
        : process.env.REACT_APP_API_URL;

const tokens = {
    bearer: () => localStorage.getItem("access_token")
};

/**
 * Список открытых вебсокет-соединений
 * @type {{add: Object<name, url>, items: {}}}
 */
const socketChannelsList = {
    items: {},

    set add(newChannel) {
        const { name, socket } = newChannel;
        this.items[name] = socket;
    }
};

/**
 * Инстанцировать сокет, если его нет, записать его и вернуть его инстанс
 * @param socket {Object<name, url>} объект канала (по умолчанию - уведомления)
 * @returns {*}
 */
export const useSocket = (socket = null) => {
    const { socketName, url } = socket;

    /**
     * Добавляет соединение в случае, если его нет. Если оно есть - берет существующее
     */
    const addOnEmpty = () => {
        try {
            if (Object.keys(socketChannelsList.items).length <= 0) {
                socketChannelsList.add = {
                    name: socketName ?? "notifications",
                    socket: new WebSocket(`${url}?token=${tokens.bearer()}`)
                };
            }
        } catch (e) {
            console.error(e?.message ?? "Что-то пошло не так (попытка подключения веб-сокета)");
        }
    };

    addOnEmpty();
    return socketChannelsList.items[socketName];
};

export const useBearerToken = () => {
    const createInstanceWithToken = (token) => {
        return axios.create({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    const bearerToken = tokens.bearer();

    const axiosInstance = createInstanceWithToken(bearerToken);

    // обработка исключений, распространяющаяся на все запросы к беку, использующие этот хук
    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response?.status === 401) {
                axiosInstance
                    .post(`${APP_URL}auth/access_token`, {
                        refresh: localStorage.getItem("refresh_token") ?? null
                    })
                    .then((response) => {
                        localStorage.setItem("access_token", response.data?.access);
                        // console.log(sessionStorage);
                    })
                    .catch((error) => {
                        console.log("%cError:" + error, process.env.REACT_APP_ERROR_STYLES);
                    });

                console.log(
                    "%cError:" + "Ошибка валидации токена, попробуйте еще раз!",
                    process.env.REACT_APP_ERROR_STYLES
                );
            }

            return error;
        }
    );

    return axiosInstance;
};
