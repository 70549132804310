import React, {useEffect, useState} from "react";

const DocumentSides = ({sidesList = [], callbackOnEdit}) => {
    const [sides, setSides] = useState(sidesList);

    useEffect(() => {
        setSides(sidesList);
    }, [sidesList]);

    const handleSideChange = (e) => {
        const {target} = e;
        if (target) {
            const currentRowIndex = Number(target.getAttribute(`data-row-id`));
            if (currentRowIndex || currentRowIndex === 0) {
                const sidesNew = sides;
                const updatedElementKey = (target.name)
                    ? target.name
                    : (target.tagName === 'SELECT' ? `name_of_contract` : `name`);

                if (sidesNew[currentRowIndex].updated) {
                    sidesNew[currentRowIndex].updated[updatedElementKey] = target.value;
                } else {
                    sidesNew[currentRowIndex].updated = {[updatedElementKey]: target.value}
                }
                setSides(sidesNew);
                callbackOnEdit(sidesNew);
            }
        }
    };

    /**
     * Проверка того, что стороной является не поставщик или покупатель, а произвольный вывод на кириллице
     * @param contractorName
     * @returns {boolean}
     */
    const sideIsNotDefault = (contractorName) => {
        if (contractorName) {
            return (contractorName !== 'vendor' && contractorName !== 'buyer');
        } else return false;
    }

    return (
        <>
            {(sides && sides.length > 0) ? (
                <div className={`documents__events-element`}>
                    <div className={`documents__event-title`}>
                        СТОРОНЫ
                    </div>
                    <div className={`documents__event-sides`}>
                        {sides.map((side, index) => {
                            return (
                                <div
                                    key={`events-sides__${index}`}
                                    className={`event-sides__row row__with-inn`}
                                >
                                    <select
                                        onChange={handleSideChange}
                                        className={`event-sides__select transform__capitalize`}
                                        defaultValue={side?.defaults?.value ?? `buyer`}
                                        data-row-id={Number(index)}
                                    >
                                        {sideIsNotDefault(side?.defaults?.value) && (
                                            <option value={side.defaults.value}>
                                                {side.defaults.value}
                                            </option>
                                        )}
                                        <option value={`buyer`}>Покупатель</option>
                                        <option value={`vendor`}>Поставщик</option>
                                    </select>
                                    <div className={`event-sides__row`}>
                                        <input
                                            className={`event-sides__input input__grey`}
                                            defaultValue={side?.defaults?.title ?? `Имя Фамилия`}
                                            placeholder={`Введите имя`}
                                            data-row-id={Number(index)}
                                            onInput={handleSideChange}
                                        />

                                        <input
                                            name={'inn'}
                                            className={`event-sides__input input__grey`}
                                            defaultValue={side?.defaults?.inn ?? null}
                                            placeholder={`Введите ИНН`}
                                            data-row-id={Number(index)}
                                            onInput={handleSideChange}
                                        />
                                    </div>

                                </div>);
                        })}
                    </div>
                </div>
            ) : (``)}
        </>
    );
}

export default DocumentSides;