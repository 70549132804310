import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useTheme} from "../Providers/ThemeProvider";


const InputValidator = ({children, message}) => {
    const [validatorActive, setValidatorActive] = useState((message));
    const isDarkTheme = useTheme('dark', () => true);
    const [validationImage, setValidationImage] = useState(null);

    useEffect(() => {
        if (message && message.length > 0) {
            setValidatorActive(true);
            setValidationImage(`/img/field__${(isDarkTheme) ? `invalid-dark` : `invalid`}.svg`);
            setTimeout(() => {
                setValidatorActive(false);
            }, 5000);
        } else {
            setValidatorActive(false);
        }
    }, [message])

    const handleValidatorClose = () => setValidatorActive(false);

    return (
        <React.Fragment>
            {children}
            <img
                className={clsx(`form__password-icon`, `form__validation-icon`, {[`form__icon-disabled`]: !validatorActive})}
                src={validationImage}
                alt={``}
                onClick={handleValidatorClose}
            />

            <div className={clsx('validator__message', {[`validator__message-showing`]: validatorActive})}>
                {message ?? '-'}
                <img
                    className={`validator__marker`}
                    src={`../../img/validator__${(isDarkTheme) ? 'marker-dark' : 'marker'}.svg`}
                    alt={``}
                />
            </div>
        </React.Fragment>
    )
}

export default InputValidator;