import React, { useState, useCallback } from "react";

const ModalStopControl = ({ isOpen, onRequestClose, onSave }) => {
    if (!isOpen) return null;

    return (
        <div className="settings__modal-content stop-control-modal">
            <div className="stop-control-modal__title">Результат судебного спора</div>
            <div className="stop-control-modal__subtitle">Выберите один из вариантов:</div>

            <div className={`radio-list`}>
                <div className={`radio-list__item`}>
                    <input type={`checkbox`} name={`result`} id="success" />
                    <label for="success">Удовлетворено</label>
                </div>
                <div className={`radio-list__item`}>
                    <input type={`checkbox`} name={`result`} id="success_partly" />
                    <div className="double-label">
                        <label for="success_partly">
                            Удовлетворено частично <input className="input-label" type="text" /> руб.
                        </label>
                        <label for="success_partly" className="second">
                            пользователь должен внести сумму
                        </label>
                    </div>
                </div>
                <div className={`radio-list__item`}>
                    <input type={`checkbox`} name={`result`} id="rejected" />
                    <label for="rejected">Отказано</label>
                </div>
            </div>

            <button className="button__save modal__button">Подтвердить</button>

            <div className={`settings__exit`} onClick={onRequestClose}>
                <img src={`/img/modal__exit.svg`} alt={``} />
            </div>
        </div>
    );
};

export default ModalStopControl;
