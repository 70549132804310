import React, {useEffect, useState} from "react";
import PeriodInput from "./PeriodInput";

const ReactionInput = ({contractEvent, index, onEventTypeChange}) => {
    if (contractEvent.hasOwnProperty('event')) {
        contractEvent = contractEvent.event;
    };

    const [eventState, setEventState] = useState(contractEvent);
    const [period, setPeriod] = useState(contractEvent.period);
    const [type, setType] = useState(contractEvent.type);
    const [periodType, setPeriodType] = useState(contractEvent.periodType);
    const [date, setDate] = useState(contractEvent.date);

    useEffect(() => {
            setType(contractEvent.type);
    }, []);

    const handleTypeChange = (e) => {
        const {target} = e;
        if (target && target.value) {
            const currentSelectId = Number(target.getAttribute(`data-event-id`));
            if (currentSelectId || currentSelectId === 0) {
                const eventsNew = eventState;
                eventsNew.type = target.value;
                eventsNew.period = period;
                eventsNew.date = date;
                setEventState(eventsNew);
                setPeriod(eventsNew.period);
                setType(eventsNew.type);
                onEventTypeChange(eventState, index);
            }
        }
    }

    const callbackOnInputChange = (updatedValues) => {
        const eventsNew = eventState;
        eventsNew.period = updatedValues.period;
        eventsNew.date = updatedValues.date;
        eventsNew.periodType = updatedValues.periodType;
        setEventState(eventsNew);
        setPeriod(updatedValues.period);
        setPeriodType(updatedValues.periodType);
        setDate(updatedValues.date);
        onEventTypeChange(eventState, index);
    }

    return (
        <div className={`event-sides__row row__wrappable`}>
            <select
                className={`event-sides__select select__date-type`}
                onChange={handleTypeChange}
                data-event-id={index}
                defaultValue={type}
            >
                <option value={`period_before`}>
                    Период времени до наступления события
                </option>
                <option value={`period_after`}>
                    Период времени после наступления события
                </option>
                <option value={`in_date`}>
                    Календарная дата
                </option>
                <option value={`until_date`}>
                    Не позднее календарной даты
                </option>
            </select>

            <PeriodInput
                type={type}
                period={period}
                periodType={periodType}
                date={date}
                onInputChange={callbackOnInputChange}
            />

        </div>
    );
}

export default ReactionInput;