import {configureStore} from "@reduxjs/toolkit";

const USER = 'USER';
const LAST_PASSWORD_UPDATE_LOCAL = 'LAST_PASSWORD_UPDATE_LOCAL';
const MONITORING_VIEW_TYPE = 'MONITORING_VIEW_TYPE';

const defaultStoreCondition = () => {
    if (localStorage.getItem('state')) {
        return JSON.parse(localStorage.getItem('state'))
    } else {
        return {
            user: {},
            lastUpdateLocal: null,
        }
    }
};

export const reducer = (state = defaultStoreCondition(), action) => {
    switch (action.type) {
        case USER:
            return {
                ...state,
                user: action.user,
            }
        case LAST_PASSWORD_UPDATE_LOCAL:
            return {
                ...state,
                lastUpdateLocal: action.lastUpdateLocal
            }
        case MONITORING_VIEW_TYPE:
            return {
                ...state,
                monitoringViewType: action.monitoringViewType,
            }
        default:
            return state
    }
}

export const store = configureStore({
    reducer: reducer, preloadedState: defaultStoreCondition()
});

store.subscribe(() => {
    console.log(store.getState());
    localStorage.setItem('state', JSON.stringify(store.getState()));
})
