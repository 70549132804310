import React, {useEffect, useRef, useState} from "react";

import './styles/claimSendDocument.scss';

const ClaimSendDocument = ({
                            document,
                            deleteDocumentCallback,
                           }) => {

  const [showPreview, setShowPreview] = useState(false);

  const togglePreview = () => {
    setShowPreview(!showPreview)

  }

  const deleteDocument = () => {
    deleteDocumentCallback()
  }
  
  return (
    <>
      <div className={`claim-send-document`}>
        <div className={`claim-send-document__title`}>
          <p>{ document.title }</p>

          {document.isAutoCalculatable && (
            <button className={`claim-send-document--auto-calculation`}>Авторасчет</button>
          )}

        </div>
        <div className={`claim-send-document__additional`}>
          <div className={`claim-send-document__amount`}>
            <input type={`number`} defaultValue={document.pagesAmount ?? 1} />
            <p>л.</p>
          </div>
          <div className={`claim-send-document__actions`}>

            {!showPreview ? (
              <img src={`../../img/eye__icon.svg`} alt={``} onClick={togglePreview} />
            ) : (
              <img src={`../../img/form__password-hide.svg`} alt={``} onClick={togglePreview} />
            )}

            {document.isDeletable && (
              <img src={`../../img/cross__icon.svg`} alt={``} className={`claim-send-document__document-delete`} onClick={deleteDocument} />
            )}

          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimSendDocument;