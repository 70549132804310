import React from "react";

const ViolationIcon = ({period}) => {
    return (
        <div className={`content-row__bottom violation`}>
            <img src={`../../../../img/monitoring/warning__icon.svg`} alt={``}/>
            <p>Нарушение: просрочка с {period}</p>
        </div>
    );
}

export default ViolationIcon;