import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";

import Sidemenu from "../../Components/sidemenu/Sidemenu.jsx";
import DocumentViewer from "../../Components/doc-viewer/DocumentViewer.jsx";
import Hint from "../../Components/monitoring/Hint.jsx";

import "./styles/takeToCourt.scss";
import DocumentLoadScreen from "../../Components/doc-viewer/utils/DocumentLoadScreen.jsx";

const TakeToCourt = () => {
    const navigate = useNavigate();
    const [resizing, setResizing] = useState(false);
    const [downClientRect, setDownClientRect] = useState(null);
    const [containerDelta, setContainerDelta] = useState([0, 0]);
    const [deltaFixed, setDeltaFixed] = useState(0);
    const [isOnload, setIsOnload] = useState(true);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const setContainerWidth = (container, delta) => {
            container.setAttribute(
                "style",
                `width: calc(50% - 20px ${delta > 0 ? "+" : "-"} ${convertToFixed(delta)}px`
            );
        };

        const convertToFixed = (valueToConvert, digitsCount = 0) => Math.abs(valueToConvert).toFixed(digitsCount);
        const claimContainer = document.querySelector(".claim-viewer__claim-container");
        const documentContainer = document.querySelector(".claim-viewer__document-container");

        setContainerWidth(claimContainer, containerDelta[0]);
        setContainerWidth(documentContainer, containerDelta[1]);
    }, [containerDelta]);

    /**
     * Обработчик начала драга
     * @param e
     */
    const handleMouseDown = (e) => {
        if (!resizing) {
            setResizing(true);
            const rect = e.target.getBoundingClientRect();
            const rectWidthModifier = rect.width / 2;
            setDownClientRect(rect.left + rectWidthModifier);
        }
    };

    /**
     * Обработчик окончания драга
     * @param e
     */
    const handleMouseUp = (e) => {
        if (resizing) {
            setResizing(false);
            setDeltaFixed(containerDelta[0]);
        }
    };

    /**
     * Обработчик движения мыши
     * @param e
     */
    const handleMouseMove = (e) => {
        if (resizing) {
            const scrollDeltaDistance = e.clientX + document.body.scrollLeft - downClientRect + deltaFixed;
            setContainerDelta([scrollDeltaDistance, -scrollDeltaDistance]);
        }
    };

    const handleDocumentLoad = () => {
        setIsOnload(false);
    };

    const takeToCourt = () => {
        navigate("/courts/send");
    };

    return (
        <>
            <DocumentLoadScreen isOnLoad={isOnload} neuralEventsPresented={status && status === "success"} />
            <div className="take-to-court" onMouseUp={handleMouseUp}>
                <div className="take-to-court__sidebar">
                    <Sidemenu />
                </div>
                <div className="take-to-court__container">
                    <a className="button__back" href="/monitoring">
                        <img src="/img/arrow__back.svg" alt="" />
                    </a>

                    <div className="take-to-court__header">
                        <span>Название</span>
                        <button>
                            <img src="/img/dots-burger.svg" />
                        </button>
                        <div className="take-to-court__save">
                            <span>Сохранение…</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                            >
                                <path
                                    d="M14.7706 15.8307H7.68731C6.60553 15.8305 5.54513 15.5294 4.62462 14.9611C3.70411 14.3929 2.95974 13.5799 2.4747 12.6129C1.98966 11.646 1.78305 10.5632 1.87797 9.48558C1.97288 8.40797 2.36558 7.37799 3.01218 6.51071C3.65878 5.64344 4.5338 4.97306 5.53948 4.57447C6.54515 4.17588 7.64184 4.06479 8.70702 4.25362C9.77219 4.44244 10.7639 4.92374 11.5713 5.64373C12.3786 6.36373 12.9699 7.29405 13.279 8.33073H14.7706C15.7652 8.33073 16.719 8.72582 17.4223 9.42908C18.1256 10.1323 18.5206 11.0862 18.5206 12.0807C18.5206 13.0753 18.1256 14.0291 17.4223 14.7324C16.719 15.4356 15.7652 15.8307 14.7706 15.8307Z"
                                    stroke="#A1A0A3"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className={`claim-create__viewer`} onMouseMove={handleMouseMove}>
                        <div
                            className={clsx(
                                `claim-viewer__claim-container claim-viewer__claim-container_take-to-court`,
                                { [`viewer__no-events`]: resizing }
                            )}
                        >
                            <DocumentViewer
                                documentUri={process.env.REACT_APP_CLAIMS_MOCK}
                                callbackOnDocumentLoad={handleDocumentLoad}
                                setStatus={setStatus}
                                className={"take-to-court__document-viewer"}
                            />
                        </div>

                        <div className={`claim-viewer__separator`}>
                            <img src={"/img/claims/claims__separator.svg"} alt={``} onMouseDown={handleMouseDown} />
                        </div>

                        <div className={clsx(`claim-viewer__document-container`, { [`viewer__no-events`]: resizing })}>
                            <DocumentViewer
                                documentUri={process.env.REACT_APP_CLAIMS_MOCK}
                                callbackOnDocumentLoad={handleDocumentLoad}
                                setStatus={setStatus}
                                className={"take-to-court__document-viewer"}
                            />
                        </div>
                    </div>

                    <div className={`claim-create__controls`}>
                        <div className={`controls__left`}>
                            <div className={`control-button__container`}>
                                <button className={`button__send`} onClick={takeToCourt}>
                                    Подать в суд
                                </button>
                                <Hint text='Иск будет доступен в разделе Мониторинг вкладка Иски статус "Суд"' />
                            </div>
                            <div className={`control-button__container`}>
                                <button className={`button__save-claim`}>Сохранить</button>
                                <Hint text='Иск будет доступен в разделе Мониторинг вкладка Иски статус "Создано"' />
                            </div>
                        </div>
                        <div className={`controls__right`}></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TakeToCourt;
