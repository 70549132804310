import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

const DocumentViewer = ({ documentUri, callbackOnDocumentLoad, setStatus, className }) => {
    const [documentViewer, setDocumentViewer] = useState(null);

    const iframe = useRef(null);

    const documentViewerInstance = (htmlContainer) => {
        const customIframeStyles = document.createElement("link");

        customIframeStyles.setAttribute("href", "../../styles/iframeClaims.css");
        customIframeStyles.setAttribute("rel", "stylesheet");

        const html = htmlContainer;

        iframe.current.contentWindow.document.open();
        iframe.current.contentWindow.document.write(html);
        iframe.current.contentWindow.document.head.append(customIframeStyles);
        iframe.current.contentWindow.document.close();

        setDocumentViewer(html);
        callbackOnDocumentLoad();
    };

    useEffect(() => {
        let uriPath = process.env.REACT_APP_CLAIMS_MOCK;
        if (documentUri && process.env.REACT_APP_BACKEND_PATH) {
            uriPath = process.env.REACT_APP_BACKEND_PATH + documentUri;
        }
        axios
            .post(`${process.env.REACT_APP_PARSER_URL}/convert/single`, {
                filepath: uriPath,
                type: "claim"
            })
            .then((response) => {
                const { data } = response;

                setStatus && setStatus(data.status);
                documentViewerInstance(data.html);
            })
            .catch((error) => {
                console.log("%cError" + error, process.env.REACT_APP_ERROR_STYLES);
            });
    }, []);

    return (
        <iframe ref={iframe} className={`documents__document-viewer ${className}`}>
            {documentViewer}
        </iframe>
    );
};

export default DocumentViewer;
