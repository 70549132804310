import React, {useEffect, useState} from "react";
import clsx from "clsx";
import ModalReactionContent from "./modal/ModalReactionContent";
import ModalIntegrationContent from "./modal/ModalIntegrationContent";
import ModalSpecificationContent from "./modal/ModalSpecificationContent";

const DocumentsModal = ({modalOpen, modalType, onModalClose}) => {
    const [type, setType] = useState('specification'); // specification reaction integration

    useEffect(() => {
        setType(modalType);
    }, [modalType]);

    const handleModalClose = () => onModalClose();

    return (
        <div className={clsx(`documents__modal`, {[`documents__modal-open`]: modalOpen}, {[`with__width-small`]: modalType === 'specification'})}>
            <div className={`documents__modal-container`}>
                <div className={`documents__modal-wrapper`}>
                    <div className={`settings__exit`} onClick={handleModalClose}>
                        <img src={`/img/modal__exit.svg`} alt={``}/>
                    </div>
                    <div className={`documents__modal-content`}>
                        {type === 'specification' && (
                            <ModalSpecificationContent />
                        )}
                        {type === 'reaction' && (
                            <ModalReactionContent />
                        )}
                        {type === 'integration' && (
                            <ModalIntegrationContent />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentsModal;