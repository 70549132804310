import React, {useEffect, useState} from "react";

import './styles/auth.scss';
import {useTheme} from "../../Helpers/Providers/ThemeProvider";
import clsx from "clsx";

const AuthScreen = ({content = null, fullscreen = false, token = null }) => {
    const isDarkTheme = useTheme('dark', () => true);

    return (
        <div className={`auth__wrapper`}>
            <div className={clsx(`auth__side-background`, { [`hidden`]: fullscreen })}>
            </div>
            <div className={clsx(`auth__content`, { [`width__full`]: fullscreen })}>
                {token && (
                    <div className={`auth__token`}>
                        <img src={`../../img/delta__token-confirmed.svg`} alt={``} />
                        Аккаунт подтверждён, пожалуйста, войдите
                    </div>
                )}
                <div className={`auth__logo`}>
                    <img
                        className={`auth__logo-image`}
                        src={(isDarkTheme) ? `../../img/delta__logo-dark.svg` : `../../img/delta__logo.svg`}
                        alt={``} />
                </div>
                {content}
            </div>
        </div>
    )
}

export default AuthScreen;