import React
  from "react";

const MonitoringJudgeItem = ({
                               workItem
                             }) => {

  return (
    <div className={`monitoring-item`}>
      <h3>Судебная работа</h3>
      <div className={`monitoring-item__process`}>
        <div className={`monitoring-item__stage`}>
          <h4>Подано</h4>
          <div className={`monitoring-item__stage--info`}>
            <p>2 шт.</p>
             <p className={`monitoring-item__stage--separator`}></p>
            <div className={`monitoring-item__stage--sum`}>
              <p>Σ</p>
              <p>2 500 тыс. руб.</p>
            </div>
          </div>
          <img src="/img/bell.svg" alt=""/>
        </div>
        <div className={`monitoring-item__stage`}>
          <h4>Вынесено решение</h4>
          <div className={`monitoring-item__stage--info`}>
            <p>1 шт.</p>
             <p className={`monitoring-item__stage--separator`}></p>
            <div className={`monitoring-item__stage--sum`}>
              <div className={`monitoring-item__stage--sum-success`}>
                <h5>Удовлетворительно</h5>
                <p>Σ</p>
                <p>5 423 тыс. руб.</p>
              </div>
              <div className={`monitoring-item__stage--sum-rejected`}>
                <h5>Отказано</h5>
                <p>Σ</p>
                <p>5 423 тыс. руб.</p>
              </div>
            </div>
          </div>
          <img src="/img/bell.svg" alt=""/>
        </div>
        <div className={`monitoring-item__stage`}>
          <h4>Автоознакомление с материалами дела</h4>
          <div className={`monitoring-item__stage--info`}>
            <div className={`monitoring-item__stage--sum`}>

              <div className={`monitoring-item__stage--info-case`}>
                <img src="/img/circle.svg" alt=""/>
                <p>29.12.2023</p>
                <p className={`monitoring-item__stage--separator`}></p>
                <p>А40-202324/2023</p>
                <img src="/img/directory.svg" alt=""/>
              </div>
              <div className={`monitoring-item__stage--info-case`}>
                <img src="/img/circle.svg" alt=""/>
                <p>15.11.2023</p>
                <p className={`monitoring-item__stage--separator`}></p>
                <p>А40-132004/2022</p>
                <img src="/img/directory.svg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/img/chart__small.svg" alt=""/>
    </div>
  );
}

export default MonitoringJudgeItem;