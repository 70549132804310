import React from "react";

const ModalReactionContent = () => {
    return (
        <>
            <div className={`documents__modal-title`}>
                Реакция на нарушение
            </div>
            <div className={`documents__modal-reaction`}>
                <div className={`modal-reaction__option`}>
                    <div className={`reaction-option__side`}>
                        <div className={`modal-reaction__email`}>
                            <div className={`reaction-email__title`}>
                                Уведомлять по электронной почте
                            </div>
                        </div>

                        <div className={`modal-reaction__obligations`}>
                            <div className={`reaction-obligations__title`}>
                                Применять к следующим обязательствам
                            </div>

                            <div className={`reaction-obligations__list`}>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`payment`} />
                                    <label for={`payment`}>Оплата</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`supply`}/>
                                    <label htmlFor={`supply`}>Поставка</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`notification`}/>
                                    <label htmlFor={`notification`}>Уведомление</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`refund`}/>
                                    <label htmlFor={`refund`}>Возврат</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`reaction-option__side side__small`}>
                        <div className={`reaction__input-column`}>
                            <input
                                type={`email`}
                                className={`event-sides__input`}
                                placeholder={`эл. почта`}
                            />
                        </div>
                    </div>
                </div>

                <div className={`modal-reaction__option`}>
                    <div className={`reaction-option__side`}>
                        <div className={`modal-reaction__email`}>
                            <div className={`reaction-email__title`}>
                                Формировать претензию и направлять по электронной почте
                            </div>
                        </div>

                        <div className={`modal-reaction__obligations`}>
                            <div className={`reaction-obligations__title`}>
                                Применять к следующим обязательствам
                            </div>

                            <div className={`reaction-obligations__list`}>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`payment`}/>
                                    <label htmlFor={`payment`}>Оплата</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`supply`}/>
                                    <label htmlFor={`supply`}>Поставка</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`refund`}/>
                                    <label htmlFor={`refund`}>Возврат</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`reaction-option__side side__small`}>
                        <div className={`reaction__input-column`}>
                            <input
                                type={`email`}
                                className={`event-sides__input`}
                                placeholder={`эл. почта`}
                            />
                        </div>
                    </div>
                </div>

                <div className={`modal-reaction__option option__disabled`}>
                    <div className={`reaction-option__side`}>
                        <div className={`modal-reaction__email`}>
                            <div className={`reaction-email__title`}>
                                Формировать претензию и направлять контрагенту по ЭДО
                            </div>
                        </div>

                        <div className={`modal-reaction__obligations`}>
                            <div className={`reaction-obligations__title`}>
                                Применять к следующим обязательствам
                            </div>

                            <div className={`reaction-obligations__list`}>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`payment`}/>
                                    <label htmlFor={`payment`}>Оплата</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`supply`}/>
                                    <label htmlFor={`supply`}>Поставка</label>
                                </div>
                                <div className={`reaction-obligations__checkbox`}>
                                    <input type={`checkbox`} name={`refund`}/>
                                    <label htmlFor={`refund`}>Возврат</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`reaction-option__side side__small`}>
                        <div className={`reaction__input-column`}>
                            <input
                                type={`email`}
                                className={`event-sides__input`}
                                placeholder={`ИНН контрагента / идентификатор участника ЭДО`}
                            />
                        </div>
                    </div>
                </div>

                <div className={`reaction__button-container`}>
                    <button className={`button__save-reaction`}>
                        Сохранить
                    </button>
                    <div className={`reaction-obligations__checkbox`}>
                        <input type={`checkbox`} name={`refund`}/>
                        <label htmlFor={`refund`}>применять для всех договоров на контроле</label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalReactionContent;