export const GLOBAL_AVATAR_PLACEHOLDER = `/img/sidemenu__avatar-placeholder.svg`;
export const GLOBAL_USERNAME_PLACEHOLDER = 'Имя Фамилия';

export const NEW_OBLIGATION_TEMPLATE = {
    // значащие блоки
    // id: obligationRandomId.join('-'), // id в таком же формате, какой отдает ии
    type: 'Новое обязательство',
    type_date: "абсолютная",
    type_day: "календарных",
    date: "02.02.2024",

    name_events: 'Новое обязательство',
    name_of_contract: 'Тест',
    previous_event_index: "1",
    number: '10',

    is_unread: true,
    is_obligation: 'obligation', // так обязательства возвращает ИИ
    currency: "",
    sum: null,
    days_count: null,
    created_at: "2024-05-14T11:36:04.875000+03:00", // нам без разницы, какая тут дата
    updated_at: "2024-05-14T11:36:04.875000+03:00", // нам без разницы, какая тут дата
    who: [],
};