import React, {useEffect, useState} from "react";
import {APP_URL, useBearerToken} from "../../API/requests";

const ModalContractEventLog = ({
                                   // contractId,
                                   showEventLogModalCallback
                               }) => {

    const axiosInstance = useBearerToken();

    const showEventLogModal = (e) => {
        showEventLogModalCallback()
    }

    const printEventLog = (e) => {
        // TODO ajax печати журнала событий
    }

    const downloadEventLog = (e) => {
        // TODO ajax скачивания журнала событий
    }

    return (
        <div className={`modal-event-log__container`}>
            <div className={`modal-event-log__wrapper`}>
                <div className={`modal-event-log__header`}>
                    <h2>Журнал событий</h2>
                    <img
                        className={`modal-event-log__header--close-btn`}
                        onClick={showEventLogModal}
                        src={`/img/monitoring/close__icon.svg`}
                        alt={``}
                    />
                </div>
                <div className={`modal-event-log__events`}>
                    <div className={`event`}>
                        <div className={`event__date`}>
                            <h3>Дата:</h3>
                            <p>20.11.2023</p>
                        </div>
                        <div className={`event__contract-name`}>
                            <h3>Договор:</h3>
                            <a href="">Договор поставки товара N 26 от 14 июня 2023 г.</a>
                        </div>
                        <div className={`event__sides`}>
                            <h3>Стороны:</h3>
                            <ul>
                                <li>ООО "Модный мир" (Поставщик)</li>
                                <li>ООО "Магазин "Все для красоты" (Покупатель)</li>
                            </ul>
                        </div>
                        <div className={`event__price`}>
                            <h3>Цена:</h3>
                            <p>449 500,00 руб.</p>
                        </div>
                    </div>
                    <div className={`event`}>
                        <div className={`event__date`}>
                            <h3>Дата, №:</h3>
                            <p>14.06.2023, 1</p>
                        </div>
                        <div className={`event__name`}>
                            <h3>Событие:</h3>
                            <p>Подписание Спецификации</p>
                        </div>
                        <div className={`event__deadline`}>
                            <h3>Срок по договору: </h3>
                            <p>___________</p>
                        </div>
                    </div>
                    <div className={`event`}>
                        <div className={`event__date`}>
                            <h3>Дата, №:</h3>
                            <p>[дата п.п.], [№ п.п.]</p>
                        </div>
                        <div className={`event__name`}>
                            <h3>Событие:</h3>
                            <p>Оплата товара Покупателем
                                Срок по договору: Оплата товара осуществляется в течение 20 рабочих дней с момента
                                подписания Акта
                                приема-передачи товара.
                                Выявлено нарушение: Произошло нарушение срока оплаты товара.</p>
                        </div>
                    </div>
                    <div className={`event`}>
                        <div className={`event__date`}>
                            <h3>Дата, №:</h3>
                            <p>[дата п.п.], [№ п.п.]</p>
                        </div>
                        <div className={`event__name`}>
                            <h3>Событие:</h3>
                            <p>Поставка товара на склад Покупателя
                                Срок по договору: Поставщик обязуется осуществить поставку товара в течение 3 рабочих
                                дней с момента подписания Спецификации.</p>
                        </div>
                    </div>
                    <div className={`event`}>
                        <div className={`event__date`}>
                            <h3>Дата, №:</h3>
                            <p>(дата отправки претензии)</p>
                        </div>
                        <div className={`event__name`}>
                            <h3>Событие:</h3>
                            <p>Отправка претензии должнику</p>
                        </div>
                        <div className={`event__deadline`}>
                            <h3>Срок исполнения претензии:</h3>
                            <p>30.09.2023</p>
                        </div>
                    </div>
                    <div className={`event`}>
                        <div className={`event__date`}>
                            <h3>Дата, №:</h3>
                            <p>(дата наступления обязательства по претензии ИЛИ исполнение обязательства: п.п.
                                /накладная/ возражения)</p>
                        </div>
                        <div className={`event__name`}>
                            <h3>Событие:</h3>
                            <p>Просрочка исполнения претензии ИЛИ Исполнение обязательства</p>
                        </div>
                    </div>
                </div>
                <div className={`modal-event-log__buttons`}>
                    <div
                        className={`modal-event-log__buttons-item`}
                        onClick={printEventLog}
                    >
                        <p>Печать</p>
                        <img
                            onClick={showEventLogModal}
                            src={`/img/monitoring/print.svg`}
                            alt={``}
                        />
                    </div>
                    <div
                        className={`modal-event-log__buttons-item`}
                        onClick={downloadEventLog}>
                        <p>Скачать</p>
                        <img
                            onClick={showEventLogModal}
                            src={`/img/monitoring/download.svg`}
                            alt={``}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalContractEventLog;