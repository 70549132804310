import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import moment from "moment";

import "moment/locale/ru";

import Sidemenu from "../Components/sidemenu/Sidemenu";
import ThemeToggleButton from "../Components/themes/ThemeToggleButton";
import {APP_URL, STORAGE_URL, useBearerToken} from "../API/requests";
import SettingsSaveButton from "../Components/forms/SettingsSaveButton";
import ModalFileForm from "../Components/forms/ModalFileForm";
import {getLocalPasswordUpdate} from "../Helpers/getLocalPasswordUpdate";

import {GLOBAL_AVATAR_PLACEHOLDER, GLOBAL_USERNAME_PLACEHOLDER} from "../Helpers/Constants/global";
import ModalCommonForm from "../Components/forms/ModalCommonForm";
import InputValidator from "../Helpers/Validators/InputValidator";
import SettingsSignModal from "../Components/forms/SettingsSignModal";
import TariffCard from "../Components/settings/TariffCard";
import ProfileActions from "../Components/settings/ProfileActions";

/**
 * Заглушка, подставляемая после закрытия формы ввода аватара
 * @type {JSX.Element}
 */
const defaultFileCaption = (
    <>
        Выбрать расположение файла<span className={`modal-input__file-types`}>&nbsp;jpg,png</span>
    </>
);

const USER_NAME_CAPTION = "Имя пользователя";
const USER_EMAIL_CAPTION = "Электронная почта";
const USER_PASSWORD_CAPTION = "Пароль";
const MODAL_MAIL_CHANGED = "Почта изменена успешно";
const MODAL_MAIL_CONFIRMATION = "Подтверждение адреса электронной почты";
const MODAL_CODE_SENT = "Мы отправили код подтверждения";
const MODAL_VERIFY_CODE = "Подтвердите свой прошлый адрес электронной почты ";
const MODAL_CODE_VERIFIED = "Введите новый адрес электронной почты и текущий пароль";
const PASSWORD_CHANGE_CAPTION = "Изменение пароля";

const Settings = () => {
    moment.locale("ru");
    document.title = `Настройки | Дельта Про`;

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    // локальное обновление пароля, действует в течение браузерной сессии либо сессии внутри приложения (до разлогина)
    const lastUpdateLocal = useSelector((state) => state.lastUpdateLocal);

    /** useState hooks */

    const [modalVisible, setModalVisible] = useState(false); // состояние модального окна настроек: открыто-закрыто
    const [modalField, setModalField] = useState("email"); // состояние хранимого в модалке поля ввода (по умолчанию - смена email)
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    /**
     * обработчики цикла смены почты.
     * Почта меняется в три этапа:
     * 1. Отправка кода на ящик юзера
     * 2. Отправка кода подтверждения из письма на сервер
     * 3. Собственно смена почты
     * todo сделать одним стейтом
     */
    const [emailCodeField, setEmailCodeField] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [mailChanged, setMailChanged] = useState(false);

    const [fileInputText, setFileInputText] = useState(defaultFileCaption); // заглушка названия файла, подгружаемого пользователем (по умолчанию - плейсхолдер с просьбой загрузить файл)

    /**
     * Состояния строки прогресса загрузки файлов в кастомное поле ввода
     * 1. Текущее значение прогресса загрузки
     * 2. Отображение либо сокрытие строки прогресса
     * 3. Иконка строки прогресса (загрузка в процессе либо успешный ввод файла)
     * todo сделать одним стейтом
     * 4. Состояние открытия модального окна для подписи
     * 5. Изображение подписи
     */
    const [progressValue, setProgressValue] = useState(0);
    const [progressVisible, setProgressVisible] = useState(false);
    const [responseIcon, setResponseIcon] = useState("progress");
    const [isSignModalOpen, setIsSignModalOpen] = useState(false);
    const [croppedSign, setCroppedSign] = useState(null);

    // Превью аватара пользователя.
    // Аватар меняется в два этапа:
    // сначала загружается превью,
    // потом оно либо применяется, либо происходит отмена.
    const [avatarPreview, setAvatarPreview] = useState(null);

    const [validationResponse, setValidationResponse] = useState({});

    /**
     * Клиентская дата обновления пароля.
     * Имеет значение в пределах сессии и имеет целью нивелировать
     * разницу между клиентским и серверным временем в случае,
     * если время на клиенте незначительно спешит или отстает.
     */
    const [timeUpdateState, setTimeUpdateState] = useState(null);

    /** useRef hooks */

    const modalRef = useRef(null);
    const modalTitleRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordConfirmationRef = useRef(null);
    const fileRef = useRef(null);
    const emailCodeRef = useRef(null);
    const emailUpdateRef = useRef(null);

    const navigate = useNavigate();

    const axiosInstance = useBearerToken();

    /** useEffect hooks */

    useEffect(() => {
        axiosInstance
            .post(`${APP_URL}auth/access_token`, {
                refresh: localStorage.getItem("refresh_token")
            })
            .then((response) => {
                localStorage.setItem("access_token", response.data?.access);
            });
    }, []);

    useEffect(() => {
        // обновление пользовательской даты срабатывает при любом обновлении инстанса юзера
        setTimeUpdateState(getLocalPasswordUpdate(lastUpdateLocal, user.last_password_update_date));
    }, [user, lastUpdateLocal]);

    /** axios hooks */

    /**
     * Отправить код подтверждения смены email-адреса на почтовый ящик пользователя
     */
    const sendCodeQuery = () => {
        setSaveButtonDisabled(true);

        axiosInstance
            .get(`${APP_URL}me/change/email/send_code`)
            .then((response) => {
                setEmailCodeField(true);
                setSaveButtonDisabled(false);
            })
            .catch((error) => {
                setSaveButtonDisabled(false);
            });
    };

    /**
     * Подтвердить отправленный пользователю код подтверждения смены email-адреса
     */
    const confirmCodeQuery = () => {
        if (emailCodeRef.current.value) {
            setSaveButtonDisabled(true);

            axiosInstance
                .get(`${APP_URL}me/change/email/${emailCodeRef.current.value}`)
                .then((response) => {
                    setSaveButtonDisabled(false);
                    setCodeVerified(true);
                    setEmailCodeField(false);
                })
                .catch((error) => {
                    setValidationResponse({
                        ...validationResponse,
                        email_change_code: error.response.data?.confirmation_code
                    });

                    setTimeout(() => {
                        setValidationResponse({});
                    }, 10000);
                    setSaveButtonDisabled(false);
                });
        } else {
            setValidationResponse({
                ...validationResponse,
                email_change_code: `Введите код`
            });

            setTimeout(() => {
                setValidationResponse({});
            }, 10000);
        }
    };

    /**
     * Изменить email-адрес
     */
    const changeEmailQuery = () => {
        setSaveButtonDisabled(true);

        axiosInstance
            .put(`${APP_URL}me/change/email`, {
                email: emailUpdateRef.current.children[0].value,
                password: emailUpdateRef.current.children[3].value
            })
            .then((response) => {
                setSaveButtonDisabled(false);

                axiosInstance.get(`${APP_URL}me/`).then((response) => {
                    setMailChanged(true);
                    dispatch({type: "USER", user: response.data});
                });
            })
            .catch((error) => {
                setSaveButtonDisabled(false);
                setValidationResponse({
                    ...validationResponse,
                    password: error.response.data?.password,
                    email: error.response.data?.email
                });

                setTimeout(() => {
                    setValidationResponse({});
                }, 10000);
                setSaveButtonDisabled(false);
            });
    };

    /**
     * Загрузить на сервер превью аватара для предварительного просмотра в профиле
     */
    const previewAvatarQuery = () => {
        setSaveButtonDisabled(true);

        const formData = new FormData();
        formData.append("preview", fileRef.current.children[0].files[0]);
        setProgressVisible(true);
        axiosInstance
            .post(`${APP_URL}me/change/avatar/preview`, formData, {
                onUploadProgress: (progressEvent) => {
                    const currentProgress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgressValue(currentProgress);
                }
            })
            .then((response) => {
                setSaveButtonDisabled(false);

                const {data} = response;
                const responseUrl = data?.preview;

                setProgressVisible(false);
                setResponseIcon("complete");
                setAvatarPreview(responseUrl);
                setModalField("avatar_preview");
            })
            .catch((error) => {
                setSaveButtonDisabled(false);
                setProgressVisible(false);
                setFileInputText(defaultFileCaption);
            });
    };

    /**
     * Изменить текущее аватар на ранее загруженное превью (с помощью метода previewAvatarQuery)
     * @returns {Promise<axios.AxiosResponse<any>>}
     */
    const changeAvatarQuery = () => {
        setSaveButtonDisabled(true);

        return axiosInstance
            .put(`${APP_URL}me/change/avatar`, {
                avatar_url: avatarPreview
            })
            .then((response) => {
                setSaveButtonDisabled(false);
                const {data} = response;
                // console.log(data);
                dispatch({type: "USER", user: data});
                setModalVisible(false);
                setAvatarPreview(false);
            })
            .catch((error) => {
                setSaveButtonDisabled(false);
            });
    };

    /**
     * Изменить пароль пользователя
     */
    const changePasswordQuery = () => {
        if (passwordConfirmationRef.current.value === passwordRef.current.value) {
            setSaveButtonDisabled(true);
            axiosInstance
                .put(`${APP_URL}me/change/password`, {
                    current_password: modalRef.current.value,
                    new_password: passwordRef.current.value
                })
                .then((response) => {
                    setSaveButtonDisabled(false);
                    modalTitleRef.current.innerText = PASSWORD_CHANGE_CAPTION;
                    dispatch({
                        type: "USER",
                        user: {
                            ...user,
                            ["last_password_update_date"]: response.data.last_password_update_date
                        }
                    });
                    dispatch({
                        type: "LAST_PASSWORD_UPDATE_LOCAL",
                        lastUpdateLocal: moment().utc().local().format()
                    });
                    setModalVisible(false);
                })
                .catch((error) => {
                    setSaveButtonDisabled(false);
                    modalTitleRef.current.innerText = PASSWORD_CHANGE_CAPTION;
                    const {data} = error.response;
                    const previousText = modalTitleRef.current.innerText;
                    modalTitleRef.current.innerText = data.current_password;
                    setTimeout(() => {
                        modalTitleRef.current.innerText = previousText.toString();
                    }, 2000);
                });
        } else {
            const previousText = modalTitleRef.current.innerText;
            modalTitleRef.current.innerText = `Подтвердите пароль`;
            setTimeout(() => {
                modalTitleRef.current.innerText = previousText.toString();
            }, 2000);
        }
    };

    /**
     * Изменить прочие данные пользователя (на данный момент доступна смена имени)
     */
    const changeMeQuery = () => {
        setSaveButtonDisabled(true);

        axiosInstance
            .put(`${APP_URL}me/change`, {
                changes: [
                    {
                        [modalRef.current.name]: modalRef.current.value
                    }
                ]
            })
            .then((response) => {
                setSaveButtonDisabled(false);
                dispatch({type: "USER", user: response.data});
                setTimeout(() => {
                    setModalVisible(false);
                }, 100);
            })
            .catch((e) => {
                setSaveButtonDisabled(false);
            });
    };

    /** handlers */

    /**
     * Обработчик выхода из приложения,
     * в этой точке стирается хранимая в сторе запись о пользователе
     * и локальная синхронизация времени обновления пароля
     */
    const handleLogout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");

        dispatch({
            type: "USER",
            user: {}
        });
        dispatch({
            type: "LAST_PASSWORD_UPDATE_LOCAL",
            lastUpdateLocal: null
        });

        navigate("/");
    };

    /**
     * Обработчик нажатия на кнопку "Изменить", вызывает модальное окно
     * @param e
     */
    const handleFieldEdit = (e) => {
        setModalField(e.target.id);
        setModalVisible(true);
    };

    /**
     * Обработчик выхода из модального окна с помощью кнопки "Отмена".
     * Отменяет изменения в случае, если процесс изменения происходит в несколько этапов.
     * @param e
     */
    const handleEditCancel = (e) => {
        setModalVisible(false);
        setEmailCodeField(false);
        setCodeVerified(false);
        setFileInputText(defaultFileCaption);
        setAvatarPreview(false);
    };

    /**
     * Обработчик нажатия на кнопку "Сохранить" внутри модального окна.
     * Применяет изменения либо переводит процесс изменения на следующий этап.
     * @param e
     */
    const handleEditSubmit = (e) => {
        if (avatarPreview) {
            changeAvatarQuery();
        } else {
            if (modalField === "avatar") {
                previewAvatarQuery();
            } else if (modalField === "password") {
                changePasswordQuery();
            } else {
                changeMeQuery();
            }
        }
    };

    const handleEmailSubmit = () => {
        if (mailChanged) {
            setMailChanged(false); // сбросить состояние модального окна при выходе
            return handleEditCancel();
        }
        if (codeVerified) {
            changeEmailQuery();
        } else if (!emailCodeField) {
            sendCodeQuery();
        } else {
            confirmCodeQuery();
        }
    };

    /**
     * Обработчик подтверждения удаления аккаунта пользователя.
     * Конфирм не требуется, так как удаление происходит из кастомного модального окна.
     */
    const handleDeleteSubmit = () => {
        axiosInstance.delete(`${APP_URL}me/`).then((response) => {
            handleLogout();
        });
    };

    const handleAvatarChange = (e) => {
        setModalField(e.target.id);
        setModalVisible(true);
    };

    const handleAvatarSave = (e) => {
        handleFileChange(e);
    };

    const handleFileUpload = (e) => {
        fileRef.current.children[0].click();
    };

    const handleFileChange = (e) => {
        handleEditSubmit(e);
    };

    const handleFilenameChange = (e) => {
        setFileInputText(fileRef.current.children[0].files[0].name);
    };

    const handleAddPromo = (e) => {
        setModalField("promo");
        setModalVisible(true);
    };

    const handleAccountDelete = (e) => {
        setModalField("account_delete");
        setModalVisible(true);
    };

    /** navigate hooks */

    const navigateToPolicy = () => navigate("/policy");

    /**
     * Отображаемые поля пользователя в профиле
     * @type {[
     * {title: string, key: string, content: (*|string)},
     * {title: string, key: string, content: *},
     * {title: string, key: string, content: string}
     * ]}
     */
    const profileItems = [
        {key: `username`, title: USER_NAME_CAPTION, content: user.username ?? GLOBAL_USERNAME_PLACEHOLDER},
        {key: `email`, title: USER_EMAIL_CAPTION, content: user.email},
        {
            key: `password`,
            title: USER_PASSWORD_CAPTION,
            content: `Обновлён ` + timeUpdateState
        }
    ];

    /**
     * Хелпер для получения заголовка этапа смены email-адреса
     * @returns {string|null}
     */
    const labelOnEmailChange = () => {
        if (mailChanged) return null;
        if (!emailCodeField && !codeVerified) return MODAL_VERIFY_CODE + `${user.email}, чтобы изменить его`;
        if (emailCodeField && !codeVerified) return MODAL_CODE_SENT + ` на ${user.email}`;
        if (codeVerified) return MODAL_CODE_VERIFIED;
    };

    /**
     * Список вариантов ввода, которые доступны с помощью компонента модального окна
     * @type {{
     * promo: {label: null, title: string, key: string},
     * password: {label: string, title: string, key: string},
     * account_delete: {label: string, title: string, key: string},
     * avatar_preview: {label: string, title: string, key: string},
     * avatar: {label: string, title: string, key: string},
     * email: {label: (string|null), title: (string), key: string},
     * username: {label: string, title: string, key: string}
     * }}
     */
    const modalFields = {
        email: {
            key: `email`,
            title: mailChanged ? MODAL_MAIL_CHANGED : MODAL_MAIL_CONFIRMATION,
            label: labelOnEmailChange()
        },
        username: {key: `username`, title: `Изменение имени`, label: `Имя`},
        password: {key: `password`, title: PASSWORD_CHANGE_CAPTION, label: `Пароль`},
        avatar: {key: `avatar`, title: `Загрузка новой фотографии профиля`, label: `Аватар`},
        avatar_preview: {key: `avatar`, title: `Предпросмотр аватара`, label: `Аватар`},
        promo: {key: `promo`, title: `Активация промокода`, label: null},
        account_delete: {
            key: `account_delete`,
            title: `Удалить аккаунт`,
            label: `Удаление аккаунта приведет к удалению всех документов, созданных Вами в программе`
        }
    };

    return (
        <>
            <div className={clsx(`settings__modal-container`, {[`settings__modal-visible`]: modalVisible})}>
                <div className={`settings__modal-wrapper`}>
                    <div className={`settings__modal-content`}>
                        {modalField === "account_delete" && (
                            <div className={`settings__exit`} onClick={handleEditCancel}>
                                <img src={`/img/modal__exit.svg`} alt={``}/>
                            </div>
                        )}
                        <div className={`modal__title`} ref={modalTitleRef}>
                            {modalFields[modalField].title}
                        </div>
                        <div className={`modal__input-group`}>
                            <div className={`modal-input__label`}>{modalFields[modalField].label}</div>
                            {modalField !== "avatar" && modalField !== "avatar_preview" ? (
                                modalField !== "account_delete" &&
                                modalField !== "email" && (
                                    <ModalCommonForm
                                        modalField={modalField}
                                        passwordRef={passwordRef}
                                        passwordConfirmationRef={passwordConfirmationRef}
                                        modalRef={modalRef}
                                        validationResponse={validationResponse}
                                    />
                                )
                            ) : (
                                <ModalFileForm
                                    avatarPreview={avatarPreview}
                                    fileRef={fileRef}
                                    fileInputText={fileInputText}
                                    responseIcon={responseIcon}
                                    progressVisible={progressVisible}
                                    progressValue={progressValue}
                                    callbackOnFileUpload={() => handleFileUpload()}
                                    callbackOnFilenameChange={() => handleFilenameChange()}
                                />
                            )}

                            {emailCodeField && modalField === "email" && (
                                <InputValidator
                                    message={validationResponse["email_change_code"]}
                                    children={
                                        <input
                                            className={`modal-input__input-field`}
                                            ref={emailCodeRef}
                                            placeholder={`Введите код здесь`}
                                            name={`email_code`}
                                        />
                                    }
                                />
                            )}
                            {!mailChanged && codeVerified && modalField === "email" && (
                                <div className={`modal-input__email-change`} ref={emailUpdateRef}>
                                    <InputValidator
                                        message={validationResponse["email"]}
                                        children={
                                            <input
                                                className={`modal-input__input-field`}
                                                ref={emailCodeRef}
                                                placeholder={`Новый адрес`}
                                                name={`email`}
                                            />
                                        }
                                    />
                                    <InputValidator
                                        message={validationResponse["password"]}
                                        children={
                                            <input
                                                className={`modal-input__input-field`}
                                                ref={emailCodeRef}
                                                type={`password`}
                                                placeholder={`Введите пароль`}
                                                name={`email_code`}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </div>

                        <div className={`modal__button-group`}>
                            {!mailChanged && (
                                <button className={`modal__button button__cancel`} onClick={handleEditCancel}>
                                    Отменить
                                </button>
                            )}

                            <SettingsSaveButton
                                getInnerText={() => {
                                    if (!codeVerified) {
                                        if (modalField === "promo") return "Активировать";
                                        else if (modalField === "account_delete") return "Удалить";
                                        else
                                            return modalField === "email" && !emailCodeField
                                                ? "Отправить код"
                                                : "Сохранить";
                                    } else {
                                        return "Готово";
                                    }
                                }}
                                mailChanged={mailChanged}
                                modalField={modalField}
                                callbackOnClick={
                                    modalField === "avatar"
                                        ? handleAvatarSave
                                        : modalField === "account_delete"
                                            ? handleDeleteSubmit
                                            : modalField === "email"
                                                ? handleEmailSubmit
                                                : handleEditSubmit
                                }
                                disabled={saveButtonDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={clsx({[`settings__modal-visible`]: modalVisible}, `settings__container`)}>
                <Sidemenu/>
                <div className={`settings__content-wrapper`}>
                    <h2 className={`settings__title`}>Настройки</h2>

                    <div className={`settings__profile-container`}>
                        <div className={`settings-profile__user-data`}>
                            <div className={`user-data__row`}>
                                <img
                                    id={`avatar`}
                                    onClick={handleAvatarChange}
                                    className={`user-data__avatar`}
                                    src={
                                        user.avatar_url
                                            ? `${STORAGE_URL}/${user.avatar_url}`
                                            : GLOBAL_AVATAR_PLACEHOLDER
                                    }
                                    alt={``}
                                />
                                <img className={`user-data__avatar avatar__edit`} src={`/img/avatar__edit.svg`}/>
                                <div className={`user-data__info`}>
                                    <div className={`user-data__name`}>{user.username}</div>
                                    <div className={`user-data__docs`}>
                                        <div className={`user-data__links`}>
                                            <img src={`/img/docs__shared.svg`} alt={``}/>3
                                        </div>
                                        <div className={`user-data__links`}>
                                            <img src={`/img/docs__templates.svg`} alt={``}/>
                                            12 шаблонов
                                        </div>
                                        <div className={`user-data__links`}>
                                            <img src={`/img/docs__documents.svg`} alt={``}/>
                                            42 документа
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`user-data__row`}>
                                <div className={`user-data__docs docs__mobile`}>
                                    <div className={`user-data__links`}>
                                        <img src={`/img/docs__shared.svg`} alt={``}/>3
                                    </div>
                                    <div className={`user-data__links`}>
                                        <img src={`/img/docs__templates.svg`} alt={``}/>
                                        12 шаблонов
                                    </div>
                                    <div className={`user-data__links`}>
                                        <img src={`/img/docs__documents.svg`} alt={``}/>
                                        42 документа
                                    </div>
                                </div>
                            </div>

                            <div className={`user-data__edit`}>
                                {profileItems.map((profileItem) => {
                                    return (
                                        <div key={`edit__${profileItem.key}`} className={`user-data__edit-field`}>
                                            <div className={`edit-field__field-content`}>
                                                <div className={`field-content__caption`}>{profileItem.title}</div>
                                                <div className={`field-content__content`}>{profileItem.content}</div>
                                            </div>
                                            <div className={`edit-field__edit-button`}>
                                                <button
                                                    id={profileItem.key}
                                                    className={`button__edit`}
                                                    onClick={handleFieldEdit}
                                                >
                                                    Изменить
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className={`user-data__edit-field`}>
                                    <div className={`edit-field__edit-button`}>
                                        <button className={`button__edit`} onClick={() => setIsSignModalOpen(true)}>
                                            Добавить подпись
                                        </button>
                                    </div>
                                </div>

                                {window.matchMedia("(max-width: 850px)").matches && (
                                    <div className={`user-data__edit-field content__theme`}>
                                        <div className={`edit-field__field-content content__theme`}>
                                            <div className={`field-content__caption`}>Тема оформления</div>
                                            <div className={`field-content__content content__theme`}>
                                                <ThemeToggleButton/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <button className={`button__exit`} onClick={handleLogout}>
                                Выйти
                            </button>

                            <ProfileActions
                                handleAddPromo={handleAddPromo}
                                navigateToPolicy={navigateToPolicy}
                                handleAccountDelete={handleAccountDelete}
                            />
                        </div>

                        <TariffCard/>
                    </div>
                </div>
            </div>

            <div
                className={clsx(`settings__modal-container`, {
                    [`settings__modal-visible`]: isSignModalOpen
                })}
            >
                <div className="settings__modal-wrapper">
                    <SettingsSignModal isOpen={isSignModalOpen} onRequestClose={() => setIsSignModalOpen(false)}/>
                </div>
            </div>
        </>
    );
};

export default Settings;
