import React from "react";

import IntegrationStatusIcon from "./IntegrationStatusIcon";
import {useNavigate} from "react-router-dom";

const IntegrationServiceContainer = ({service, onStatusChange}) => {
    const navigate = useNavigate();

    const {service_name, active, settings} = service;

    const onIntegrationUpdate = (e) => {
        console.log(e.target);
        const id = e.target.getAttribute('data-integration-id');
        return navigate(`/integrations/${id}/edit`);
    }

    return (
        <div className={`integrations__item`}>
            <div className={`integrations__row`}>
                <button
                    onClick={onStatusChange}
                    className={`integration-item__status-button`}
                >
                    <IntegrationStatusIcon active={(service.login)}/>
                </button>
                <div className={`integration-item__title`}>{service_name}</div>
            </div>

            <div className={`integrations__row`}>
                <button
                    data-integration-id={service.id}
                    className={`integrations__settings-icon`}
                    onClick={onIntegrationUpdate}
                >
                    <img src={`/img/documents/btn__integration.svg`} alt={``}/>
                </button>
            </div>
        </div>
    );
}

export default IntegrationServiceContainer;