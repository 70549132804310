import React from "react";
import {useSearchParams} from "react-router-dom";
import {useTheme} from "../Helpers/Providers/ThemeProvider";

const ConfirmReset = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const isDarkTheme = useTheme('dark', () => true);

    const email = searchParams.get('email');

    return (
      <div className={`app__reset-container`}>
          <div className={`app__reset-wrapper`}>
              <div className={`app__reset-logo`}>
                  <img
                      className={`app__reset-image`}
                      src={(isDarkTheme) ? `/img/delta__logo-dark.svg` : `img/delta__logo.svg`}
                      alt={``}
                  />
              </div>
              <div className={`app__reset-title`}>
                  <span className={`desktop__hidden`}>Мы</span> отправили <span className={`desktop__hidden`}>вам</span> письмо на <a className={`app__reset-link`} href={`mailto: ${email}`}>{email}</a>
              </div>

              <div className={`app__reset-text`}>
                  Пожалуйста, перейдите по ссылке в письме для восстановления пароля
              </div>
          </div>
      </div>
    );
};

export default ConfirmReset;