import React, {useRef} from "react";

const IntegrationEditInput = ({caption, name, value, onInputChange}) => {
    const inputRef = useRef(null);
    const callbackOnChange = (e) => {
        const {name, value} = inputRef.current;
        onInputChange(name, value);
    }

    return (
        <div className={`integrations__form-input-group`}>
            <span>{caption}</span>
            <input
                ref={inputRef}
                name={name}
                className={`integrations__form-input`}
                defaultValue={value}
                placeholder={caption}
                onChange={callbackOnChange}
            />
        </div>
    )
}

export default IntegrationEditInput;