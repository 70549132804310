import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import clsx from "clsx";

import Sidemenu from "../../Components/sidemenu/Sidemenu";

import './styles/claimCreate.scss';

const ClaimCreate = () => {
    // const singleConvert = `${process.env.REACT_APP_PARSER_URL}/convert/single`;
    const navigate = useNavigate();

    const [resizing, setResizing] = useState(false);
    const [downClientRect, setDownClientRect] = useState(null);
    const [containerDelta, setContainerDelta] = useState([0, 0]);
    const [deltaFixed, setDeltaFixed] = useState(0);

    useEffect(() => {

        axios.post(`${process.env.REACT_APP_PARSER_URL}/convert/multiple`, {
            filepaths: [
                { filepath: process.env.REACT_APP_CLAIMS_MOCK, type: 'claim' },
                { filepath: process.env.REACT_APP_CLAIMS_MOCK, type: 'claim' }
            ]
        }).then((response) => {
            const {data} = response;

            ['claim', 'document'].forEach((containerType, index) => {
                handleFileLoaded(
                    data[index].html,
                    `.claim-viewer__${containerType}-container`
                );
            })
        }).catch((error) => {
            console.log('%cError' + error, process.env.REACT_APP_ERROR_STYLES);
        });
    }, []);

    useEffect(() => {
        const setContainerWidth = (container, delta) => {
            container.setAttribute(
                'style',
                `width: calc(50% - 20px ${(delta > 0) ? '+' : '-'} ${convertToFixed(delta)}px`);
        }

        const convertToFixed = (valueToConvert, digitsCount = 0) => Math.abs(valueToConvert).toFixed(digitsCount);
        const claimContainer = document.querySelector('.claim-viewer__claim-container');
        const documentContainer = document.querySelector('.claim-viewer__document-container');

        setContainerWidth(claimContainer, containerDelta[0]);
        setContainerWidth(documentContainer, containerDelta[1]);
    }, [containerDelta]);

    /**
     * Обработчик загрузки файла, вставляет приходящий айфрейм в контейнер и применяет к нему часть настроек форматирования
     * @param htmlContainer
     * @param querySelector
     */
    const handleFileLoaded = (htmlContainer, querySelector) => {
        const customIframeStyles = document.createElement('link');

        // @fixme возможно, не лучшее решение
        customIframeStyles.setAttribute('href', '../../styles/iframeClaims.css');
        customIframeStyles.setAttribute('rel', 'stylesheet');

        const iframe = document.createElement('iframe');
        const html = htmlContainer;

        document.querySelector(querySelector).innerHTML = '';
        document.querySelector(querySelector).appendChild(iframe);

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.head.append(customIframeStyles)
        iframe.contentWindow.document.close();
    }

    /**
     * Обработчик начала драга
     * @param e
     */
    const handleMouseDown = (e) => {
        if (!resizing) {
            setResizing(true);
            const rect = e.target.getBoundingClientRect();
            const rectWidthModifier = rect.width / 2;
            setDownClientRect(rect.left + rectWidthModifier);
        }
    };

    /**
     * Обработчик окончания драга
     * @param e
     */
    const handleMouseUp = (e) => {
        if (resizing) {
            setResizing(false);
            setDeltaFixed(containerDelta[0]);
        }
    }

    /**
     * Обработчик движения мыши
     * @param e
     */
    const handleMouseMove = (e) => {
        if (resizing) {
            const scrollDeltaDistance = e.clientX + document.body.scrollLeft - downClientRect + deltaFixed;
            setContainerDelta([
                scrollDeltaDistance,
                -scrollDeltaDistance
            ]);
        }
    }

    const handleClaimSend = (e) => {
        return navigate('/claims/send');
    }

    return (
        <div className={`claim-create__container`}
             onMouseUp={handleMouseUp}
        >
            <div className={`claim-create__sidemenu`}>
                <Sidemenu/>
            </div>
            <div className={`claim-create__view-container`}>
                <a className="button__back" href="/monitoring">
                    <img src="/img/arrow__back.svg" alt=""/>
                </a>

                <div className={`claim-create__viewer`} onMouseMove={handleMouseMove}>

                    <div className={clsx(`claim-viewer__claim-container`, {[`viewer__no-events`]: resizing})}></div>

                    <div className={`claim-viewer__separator`}>
                        <img
                            src={'/img/claims/claims__separator.svg'}
                            alt={``}
                            onMouseDown={handleMouseDown}
                        />
                    </div>

                    <div className={clsx(`claim-viewer__document-container`, {[`viewer__no-events`]: resizing})}></div>
                </div>

                <div className={`claim-create__controls`}>
                    <div className={`controls__left`}>
                        <div className={`control-button__container`}>
                            <button className={`button__send`}
                                    onClick={handleClaimSend}>
                                Отправить должнику
                            </button>
                            <div className={`control-button__question`}>
                                <img src={`/img/claims/controls__question.svg`} alt={``}/>
                            </div>
                        </div>
                        <div className={`control-button__container`}>
                            <button className={`button__save-claim`}>Сохранить</button>
                            <div className={`control-button__question`}>
                                <img src={`/img/claims/controls__question.svg`} alt={``}/>
                            </div>
                        </div>
                    </div>
                    <div className={`controls__right`}></div>
                </div>
            </div>

        </div>
    );
}

export default ClaimCreate;