/**
 * словарь возможных ответов ии по типам договоров
 * todo получать от ИИ данные в заранее подготовленном формате
 * @type {string[][]}
 */
const availableTypesDictionary = [
    ['КУПЛЯ-ПРОДАЖА', 'КУПЛИ-ПРОДАЖИ', 'КУПЛИ ПРОДАЖИ', 'КУПЛЯ ПРОДАЖА',],
    ['ПОСТАВКА ТОВАРА', 'ПОСТАВКИ ТОВАРА', 'ПОСТАВКА ТОВАРОВ', 'ПОСТАВКИ ТОВАРОВ',],
    ['ОКАЗАНИЕ УСЛУГ', 'ОКАЗАНИЯ УСЛУГ', 'УСЛУГ', 'ОКАЗАНИЕ УСЛУГ (ВЫПОЛНЕНИЕ РАБОТ)'],
    ['АРЕНДА', 'АРЕНДЫ',],
    ['ЗАЙМ', 'ЗАЙМА', 'ЗАЙМОВ'],
];

export const typesDictionary = [
    "купля-продажа",
    "поставка товаров",
    "аренда помещений",
    "аренда транспортных средств",
    "лизинг",
    "подряд",
    "строительный подряд",
    "оказание услуг",
    "перевозка",
    "транспортная экспедиция",
    "заем",
    "кредит",
    "поручение",
    "комиссия",
    "агентирование (агент действует от своего имени)",
    "агентирование (агент действует от имени принципала)",
    "смешанный договор"
];

/**
 * Обертка для чтения результата ответа ИИ, парсит строковое содержимое в удобный для работы фронта вид
 * @param contract {Object<*>} инстанс документа, json-объект, приходящий из апи
 * @returns {{sides: *[], type: number, events: *[]}}
 */
export const parseContractResponse = (contract) => {
    const resolveEventType = (event) => {
        if (event.type_date === 'относительная') {
            return (event.previous_event_index) ? `period_after` : `period_before`;
        } else if (event.type_date === 'абсолютная') {
            if (event.after && event.after === true) {
                return `in_date`;
            } else return (!event.after) ? `in_date` : `until_date`;
        }
    };

    const resolvePeriodType = (event) => {
        if (event.type_day && event.type_day.includes(`календар`)) {
            return 2;
        } else return 1;
    };

    /**
     * Обработчик типов документов, приходящих с бекенда
     * @param type
     * @returns {*}
     */
    const resolveContractType = (type) => {
        if (type) {
            const typeInsensitive = type.toLowerCase();
            const typesList = typesDictionary.map((typeArray, index) => {
                let result = null;
                if (typeArray.includes(typeInsensitive)) {
                    result = typeInsensitive;
                } else result = type;

                return result;
            });
            return typesList.filter((resultEntity) => resultEntity !== null)[0];
        }
    }

    const sides = contract.sides_of_contract;
    const events = contract.events;
    const contractType = contract.type_of_contract;
    const sidesParsed = [];
    const eventsParsed = [];
    const contractTypeParsed = resolveContractType(contractType);
    console.log(contractTypeParsed);

    if (sides?.length === 0 || events?.length === 0) {
        console.log('документ в загрузке');
    }

    if (sides && sides.length > 0) {
        sides.map((side) => sidesParsed.push({
            defaults: {
                title: side.name,
                inn: side.INN ?? null,
                value: side.name_of_contract,
            }
        }));
    }

    if (events && events.length > 0) {
        events.map((event) => {
            const eventInstance = {
                event: {
                    sum: event.sum,
                    currency: event.currency,
                    title: event.type,
                    type: resolveEventType(event),
                    period: event.days_count ?? event.after ?? 0,
                    date: event.date,
                    periodType: resolvePeriodType(event),
                    is_obligation: event.is_obligation,
                    custom: event.custom,
                }
            };

            if (eventInstance.event?.custom) {
                eventInstance.event.contract = contract.id;
            }

            return eventsParsed.push(eventInstance);
        });
    }

    return {
        sides: sidesParsed,
        events: eventsParsed ?? {},
        type: contractTypeParsed ?? null,
    };
};