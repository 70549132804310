import React, {useEffect, useState} from "react";
import AuthScreen from "../Components/auth-screen/AuthScreen";
import LoginForm from "../Components/forms/LoginForm";
import {loginFormState, passwordTokenFormState, resetPasswordFormState} from "../Helpers/initialStateValues";
import LoginLinks from "../Components/forms/LoginLinks";
import ResetLinks from "../Components/forms/ResetLinks";
import {useSearchParams} from "react-router-dom";

const PasswordReset = () => {
    const token = useSearchParams()[0].get('token');

    return (
        <div className={`auth__container`}>
            <AuthScreen content={
                <LoginForm
                    formCaption={(token) ? `Создание нового пароля` : `Восстановление пароля`}
                    formFields={(token) ? passwordTokenFormState : resetPasswordFormState}
                    buttonCaptionOnSubmit={`Продолжить`}
                    links={<ResetLinks />}
                    formEndpoint={(token) ? `/password` : `/password_reset`}
                />}
                        fullscreen={!!(token)}
            />
        </div>
    )
}

export default PasswordReset;