import React from "react";
import clsx from "clsx";

const SortMenu = ({onOrderChange, onSubjectChange, order, subject}) => {
    return (
        <div className={`menu__sort`}>
            <div
                className={clsx(`filter__sort`, { 'order__asc': order === 'asc' })}
                onClick={onOrderChange}
            >
                <img src={`../../img/monitoring/sort__order.svg`} alt={``} />
            </div>
            <div
                className={clsx({ 'filter__active': subject === 'name' }, `filter__value`)}
                data-filter-type={`name`}
                onClick={onSubjectChange}
            >
                Имя
            </div>
            <div
                className={clsx({ 'filter__active': subject === 'date' }, `filter__value`)}
                data-filter-type={`date`}
                onClick={onSubjectChange}
            >
                Дата
            </div>
        </div>
    )
}

export default SortMenu;