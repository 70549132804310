import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import clsx from "clsx";

import {setGlobalTheme} from "../../Helpers/Providers/ThemeProvider";
import ThemeToggleButton from "../themes/ThemeToggleButton";
import {STORAGE_URL} from "../../API/requests";

import './styles/sidemenu.scss';
import {GLOBAL_AVATAR_PLACEHOLDER} from "../../Helpers/Constants/global";

const Sidemenu = () => {
    const user = useSelector((state) => state.user);
    const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'light');
    const [hoverActive, setHoverActive] = useState(false);
    const [hoverTransitionActive, setHoverTransitionActive] = useState(false);
    const [transitionTimeout, setTransitionTimeout] = useState(null);
    const [menuExpanded, setMenuExpanded] = useState(true); // true на время отладки, должно быть false

    useEffect(() => {
        localStorage.setItem('theme', theme);
    }, [theme]);

    useEffect(() => {
        if (hoverActive) {
            if (!hoverTransitionActive) {
                setHoverTransitionActive(true);
                const timeoutInstance = setTimeout(() => {
                    setHoverTransitionActive(false);
                    setMenuExpanded(true);
                }, 100);

                setTransitionTimeout(timeoutInstance);
            }
        } else {
            setHoverTransitionActive(false);
            clearTimeout(transitionTimeout);

            const transitionInstance = setTimeout(() => {
                // setMenuExpanded(false);
                setHoverTransitionActive(false);
            }, 100);
            setTransitionTimeout(transitionInstance);
        }
    }, [hoverActive]);

    const menuItems = [
        {key: `dashboard`, title: `Дашборд`, link: `/dashboard`, icon: `menu__dashboard`},
        {key: `monitoring`, title: `Мониторинг`, link: `/monitoring`, icon: `chart__big`},
        {key: `templates`, title: `Шаблоны`, link: `/templates`, icon: `menu__templates`},
        {key: `documents`, title: `Документы`, link: `/documents`, icon: `menu__documents`},
        {key: `contractors`, title: `Контрагенты`, link: `/contractors`, icon: `menu__contractors`},
        {key: `courts`, title: `Журнал судов`, link: `/journal`, icon: `judge`},
        {key: `support`, title: `Помощь`, link: `/support`, icon: `menu__support`},
    ];

    const handleThemeChange = () => {
        const toggleResult = (theme === 'dark') ? 'light' : 'dark';

        setGlobalTheme(toggleResult);
        setTheme(toggleResult);
    };

    const handleHoverActive = () => setHoverActive(true);
    const handleHoverInactive = () => setHoverActive(true); // true на время отладки, должно быть false

    return (
        <div
            className={clsx({'expanded': menuExpanded}, `sidemenu__container`)}
            onMouseOver={handleHoverActive}
            onMouseLeave={handleHoverInactive}
        >
            <div className={`sidemenu__wrapper`}>
                <a
                    href={`/settings`}
                    className={`sidemenu__user-container`}>
                    <div className={`sidemenu-user__wrapper`}>
                        <img
                            className={`sidemenu-user__avatar`}
                            src={(user.avatar_url) ? `${STORAGE_URL}/${user.avatar_url}` : GLOBAL_AVATAR_PLACEHOLDER}
                            alt={``}
                        />
                        <div className={clsx(`sidemenu-user__username`, {'hidden': !menuExpanded})}>
                            {user?.username ?? 'Имя Фамилия'}
                        </div>
                    </div>
                </a>

                <div className={`sidemenu__create-button`}>
                    <button className={`button__create`}>
                        <img src={`/img/button__add.svg`} alt={``}/>
                        <span className={clsx({'hidden': !menuExpanded})}>Создать</span>
                    </button>
                </div>

                <div className={clsx(`sidemenu__elements`, {'expanded': menuExpanded})}>
                    {menuItems.map((menuItem) => {
                        return (
                            <a
                                key={`item__${menuItem.key}`}
                                href={`/${menuItem.key}`}
                                className={`sidemenu-elements__element`}
                            >
                                <img src={`/img/${menuItem.icon}.svg`} alt={``}/>
                                <span className={clsx({'hidden': !menuExpanded}, `sidemenu-element__title`)}>{menuItem.title}</span>
                            </a>
                        );
                    })}
                </div>
            </div>

            <div className={`sidemenu__theme`}>
                <ThemeToggleButton isExpanded={menuExpanded}/>

                <div className={`sidemenu-theme__copyright`}>
                    © ДЕЛЬТА ТЕК, 2024
                </div>
            </div>
        </div>
    )
}

export default Sidemenu;