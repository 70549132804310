import React from "react";
import clsx from "clsx";

import { viewTypeFilters } from "../../Helpers/Constants/monitoring";
import { useThemeContext } from "../../Helpers/Providers/themes/themeContext";

const ViewType = ({ onViewFilterChange, monitoringDisplay }) => {
    const { theme } = useThemeContext();
    const isDarkTheme = theme === "dark";

    return (
        <div className={`menu__view-type`}>
            {viewTypeFilters.map((viewTypeFilter) => {
                return (
                    <div
                        key={`view__${viewTypeFilter.id}`}
                        data-filter-type={viewTypeFilter.id}
                        onClick={onViewFilterChange}
                        className={clsx(`filter__value`, `value__layout`, {
                            filter__active: monitoringDisplay === viewTypeFilter.id
                        })}
                    >
                        <img
                            src={`../../img/layout__${viewTypeFilter.title}.svg`}
                            alt={``}
                            className={clsx({
                                dark_theme: isDarkTheme
                            })}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ViewType;
