import React, {useEffect, useState} from "react";
import AuthScreen from "../Components/auth-screen/AuthScreen";
import LoginForm from "../Components/forms/LoginForm";
import {loginFormState} from "../Helpers/initialStateValues";
import LoginLinks from "../Components/forms/LoginLinks";

const Login = () => {
    const [searchParams, setSearchParams] = useState({});

    useEffect(() => {
        setSearchParams(decodeURI(window.location.search)
            .replace('?', '')
            .split('&')
            .map(param => param.split('='))
            .reduce((values, [key, value]) => {
                values[key] = value
                return values
            }, {}));
    }, [])

    return (
        <div className={`auth__container`}>
            <AuthScreen content={
                <LoginForm
                    formCaption={`Вход`}
                    formFields={loginFormState}
                    buttonCaptionOnSubmit={`Войти`}
                    links={<LoginLinks/>}
                    formEndpoint={`/login`}
                    token={searchParams['token']}
                />}
                        token={searchParams['token']}
            />
        </div>
    )
}

export default Login;