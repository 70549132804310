import React, { useEffect, useRef, useState } from "react";
import PRLogo from "./images/pr.svg";

import moment from "moment";

const NotificationItem = ({ title, text, date, remove, id }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
        const updateTimeAgo = () => {
            const dateMoment = moment(date, "DD.MM.YYYY HH:mm");
            setTimeAgo(dateMoment.fromNow());
        };
        updateTimeAgo();
        const intervalId = setInterval(updateTimeAgo, 60000);

        return () => clearInterval(intervalId);
    }, [date]);

    return (
        <div className="notification-item">
            <div className="notification-item__top">
                <div className="notification-item__title">
                    <div className="notification-item__avatar">
                        <img src={PRLogo} />
                    </div>
                    <h3> {title}</h3>
                </div>
                <button className="notification-item__button" onClick={() => remove(id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path
                            d="M9.02817 8.91508L12.3424 12.2293L12.2273 12.3443L8.91313 9.03012L8.50065 8.61765L8.08817 9.03012L4.77398 12.3443L4.65894 12.2293L7.97313 8.91508L8.38561 8.5026L7.97313 8.09012L4.65894 4.77594L4.77398 4.6609L8.08817 7.97508L8.50065 8.38756L8.91313 7.97508L12.2273 4.6609L12.3424 4.77594L9.02817 8.09012L8.61569 8.5026L9.02817 8.91508Z"
                            fill="#F6F6F6"
                            stroke="#F6F6F6"
                            strokeWidth="1.16667"
                        />
                    </svg>
                </button>
            </div>
            <p className="notification-item__text">{text}</p>
            <div className="notification-item__bottom">
                <span className="notification-item__time">{date}</span>
                <a href="" className="notification-item__link">
                    Перейти
                </a>
            </div>
        </div>
    );
};

export default NotificationItem;
