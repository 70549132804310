import React from "react";
import clsx from "clsx";

const SettingsSaveButton = ({
                                getInnerText,
                                mailChanged,
                                modalField,
                                callbackOnClick,
                                disabled,
                            }) => {
    const handleClick = (e) => callbackOnClick();

    return (
        <button
            className={clsx(
                `modal__button button__save`,
                { ['button__full-width']: mailChanged},
                { ['button__save-white']: modalField === 'promo'},
            )}
            onClick={handleClick}
            disabled={disabled}
        >
            {getInnerText()}
        </button>
    );

}

export default SettingsSaveButton;