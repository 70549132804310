import React from "react";
import clsx from "clsx";
import { useThemeContext } from "../../Helpers/Providers/themes/themeContext";

const MonitoringButton = ({ icon, title, action, disabled }) => {
    const { theme } = useThemeContext();
    const isDarkTheme = theme === "dark";

    return (
        <button
            className={clsx({ button__disabled: disabled }, `actions__button`, {
                "actions__button_dark-theme": isDarkTheme
            })}
            onClick={action}
            disabled={disabled}
        >
            <span className={`actions__button-text`}>{title}</span>
            <img
                src={`/img/monitoring/${icon}.svg`}
                className={clsx("actions__button-icon", { "actions__button-icon_dark-theme": isDarkTheme })}
                alt=""
            />
        </button>
    );
};

export default MonitoringButton;
