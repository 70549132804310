import React, {useState} from "react";

const EventDeleteIcon = () => {
    const [gradientFrom, setGradientFrom] = useState('#A1A0A3');
    const [gradientTo, setGradientTo] = useState('#A1A0A3');

    const handleMouseEnter = () => {
        setGradientFrom('#E3495A');
        setGradientTo('#B82D22');
    }
    const handleMouseLeave = () => {
        setGradientTo('#A1A0A3');
        setGradientFrom('#A1A0A3');
    }

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`event__delete-container`}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
            >
                <path
                    d="M2.5 5.0013H17.5M15.8333 5.0013V16.668C15.8333 17.5013 15 18.3346 14.1667 18.3346H5.83333C5 18.3346 4.16667 17.5013 4.16667 16.668V5.0013M6.66667 5.0013V3.33464C6.66667 2.5013 7.5 1.66797 8.33333 1.66797H11.6667C12.5 1.66797 13.3333 2.5013 13.3333 3.33464V5.0013M8.33333 9.16797V14.168M11.6667 9.16797V14.168"
                    stroke="url(#paint0_linear_5730_37313)" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"/>
                <defs>
                    <linearGradient id="paint0_linear_5730_37313" x1="17.2892" y1="1.38827" x2="-1.00548" y2="12.6297"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor={gradientFrom}/>
                        <stop offset="1" stopColor={gradientTo}/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default EventDeleteIcon;