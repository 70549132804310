import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";

const ModalOnLoadComplete = ({ callbackOnExit, documentLoaded, autoname, fileName }) => {
    const navigate = useNavigate();

    const [destination, setDestination] = useState('control');
    const [nestedAutoname, setNestedAutoname] = useState(autoname);
    const [contractId, setContractId] = useState(null);

    useEffect(() => {
        if (documentLoaded) {
            setContractId(documentLoaded);
        }
    }, []);

    useEffect(() => {
        setNestedAutoname(autoname);
    }, [autoname]);


    const callbackOnLoadComplete = () => {
        if (contractId) {
            const navigationRoute = (autoname) ? `/${destination}?contract=${contractId}` : `/monitoring`;
            if (!autoname) {
                console.log('%cError:' + 'no autoname', process.env.REACT_APP_ERROR_STYLES);
            }

            navigate(navigationRoute);
        } else alert('Документ не загружен либо апи вернула ошибочный ответ, сообщите разработчику');
    }

    const handleDestinationChange = (e) => {
        setDestination(e.target.value);
    }

    return (
        <div className={`settings__modal-content settings__modal-content--light-black`}>
            <div className={`dashboard__modal-complete`}>
                <div className={`dashboard__modal-complete-text`}>
                    Документ {(autoname) ? 'загружен' : 'обрабатывается...'}
                </div>
                {autoname && (
                    <div className={`dashboard__modal-complete-icon`}>
                        <img src={`../../img/dashboard__load-complete.svg`} alt={``}/>
                    </div>
                )}
            </div>

            <div className={`dashboard__modal-input-group`}>
                <div className={`dashboard__modal-input-label`}>
                    Название
                </div>
                <input
                    className={`dashboard__autoname-field`}
                    defaultValue={nestedAutoname}
                    placeholder={autoname ?? fileName}
                    disabled={!(autoname)}
                />
                <div className={`dashboard__modal-name-caption`}>
                    После обработки договора посредством ИИ его название автоматически обновится, соответствуя требованиям электронной подачи документов в суд. Измененное наименование отразится на странице «Мониторинг»
                </div>
            </div>

            <div className={`dashboard__modal-input-group`}>
                <div className={`dashboard__modal-input-label`}>
                    Место сохранения
                </div>
                <select
                    className={`dashboard__modal-select`}
                    onChange={handleDestinationChange}
                >
                    <option value={`control`}>Договоры</option>
                    <option value={`claims`}>Претензии</option>
                    <option value={`courts`}>Иски</option>
                </select>
            </div>

            <div className={`dashboard__modal-buttons dashboard__width-full`}>
                <button
                    className={clsx(`dashboard__menu-button`, `button__save`)}
                    onClick={callbackOnLoadComplete}
                >
                    {(autoname) ? 'Сохранить' : 'Сохранить'}
                </button>
            </div>

            <div className={`settings__exit`} onClick={callbackOnExit}>
                <img src={`/img/modal__exit.svg`} alt={``}/>
            </div>
        </div>
    );
}

export default ModalOnLoadComplete;