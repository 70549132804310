import React from "react";

const ModalIntegrationContent = () => {
    return (
        <>
            <div className={`documents__modal-title`}>
                Источники получения информации
            </div>

            <div className={`documents__modal-integration`}>
                <div className={`documents__modal-input-group`}>
                    <label for={`company`}>Компания</label>
                    <input
                        className={`event-sides__input`}
                        name={`company`}
                        placeholder={`ООО / ИП / АО / ЗАО…`}
                    />

                    <div className={`input-group__hint`}>
                        <img
                            src={`./img/input__hint.svg`}
                            alt={``}
                            className={`input-group__hint-image`}
                        />
                        <span className={`input-group__hint-text`}>
                            Присвойте имя и укажите путь к файлу/ресурсу для контроля каждого обязательства
                        </span>
                    </div>
                </div>
                <div className={`documents__modal-input-grid`}>
                    <div className={`documents__modal-input-group`}>
                        <label htmlFor={`company`}>Поставка товаров (работ, услуг)</label>
                        <input
                            className={`event-sides__input`}
                            name={`company`}
                            placeholder={`Пример`}
                        />
                    </div>
                    <div className={`documents__modal-input-group`}>
                        <label htmlFor={`company`}>Оплата</label>
                        <input
                            className={`event-sides__input`}
                            name={`company`}
                            placeholder={`Пример`}
                        />
                    </div>
                    <div className={`documents__modal-input-group`}>
                        <label htmlFor={`company`}>Уведомление</label>
                        <input
                            className={`event-sides__input`}
                            name={`company`}
                            placeholder={`Пример`}
                        />
                    </div>
                    <div className={`documents__modal-input-group`}>
                        <label htmlFor={`company`}>Возврат</label>
                        <input
                            className={`event-sides__input`}
                            name={`company`}
                            placeholder={`Пример`}
                        />
                    </div>
                    <div className={`documents__modal-input-group`}>
                        <label htmlFor={`company`}>Имя</label>
                        <input
                            className={`event-sides__input`}
                            name={`company`}
                            placeholder={`карточка сч. 60`}
                        />
                    </div>
                    <div className={`documents__modal-input-group`}>
                        <label htmlFor={`company`}>Путь</label>
                        <input
                            className={`event-sides__input`}
                            name={`company`}
                            placeholder={`ООО / ИП / АО / ЗАО…`}
                        />
                    </div>
                </div>

                <div className={`reaction__button-container`}>
                    <button className={`button__save-reaction`}>
                        Сохранить
                    </button>
                    <div className={`reaction-obligations__checkbox`}>
                        <input type={`checkbox`} name={`refund`}/>
                        <label htmlFor={`refund`}>применять для всех договоров на контроле</label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalIntegrationContent;