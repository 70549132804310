import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import {Navigate} from "react-router";

import Register from "../Pages/Register";
import Login from "../Pages/Login";
import Settings from "../Pages/Settings";
import ProtectedRoute from "../Components/routes/ProtectedRoute";
import PasswordReset from "../Pages/PasswordReset";
import ConfirmReset from "../Pages/ConfirmReset";
import Tariffs from "../Pages/Tariffs";
import Policy from "../Pages/Policy";
import ConfirmEmail from "../Pages/ConfirmEmail";
import Dashboard from "../Pages/Dashboard";
import Control from "../Pages/Documents/Control";
import Monitoring from "../Pages/Documents/Monitoring";
import Preview from "../Pages/Documents/Preview";
import Autotemplate from "../Pages/Documents/Autotemplate";
import Templates from "../Pages/Documents/Templates";
import MonitoringClaimEdit from "../Pages/Documents/MonitoringClaimEdit";
import ClaimSend from "../Pages/Claims/ClaimSend";
import ClaimCreate from "../Pages/Claims/ClaimCreate";
import TakeToCourt from "../Pages/Courts/TakeToCourt";
import CourtCreate from "../Pages/Courts/Create";
import Integrations from "../Pages/Integrations";
import {APP_URL} from "../API/requests";
import Counterparties from "../Pages/Counterparties";
import IntegrationEditForm from "../Components/settings/IntegrationEditForm";

const RoutesList = () => {
    const user = useSelector((state) => state.user);

    return (
        <BrowserRouter basename={"/"}>
            <Routes>
                <Route path={`*`} element={<Navigate to={`/`}/>}/>
                <Route path={`/`} element={<Login/>}/>
                <Route path={`/register`} element={<Register/>}/>
                <Route path={`/email_confirm`} element={<ConfirmEmail/>}/>
                <Route path={`/register_promo`} element={<Register promo={true}/>}/>
                <Route path={`/login`} element={<Login/>}/>
                <Route path={`/password_reset`} element={<PasswordReset/>}/>
                <Route path={`/reset_confirmation`} element={<ConfirmReset/>}/>
                <Route path={`/policy`} element={<Policy/>}/>

                <Route
                    path={`/settings`}
                    element={
                        <ProtectedRoute user={user}>
                            <Settings/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/integrations`}
                    element={
                        <ProtectedRoute user={user}>
                            <Integrations/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/tariffs`}
                    element={
                        <ProtectedRoute user={user}>
                            <Tariffs/>
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path={`/dashboard`}
                    element={
                        <ProtectedRoute user={user}>
                            <Dashboard/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/control`}
                    element={
                        <ProtectedRoute user={user}>
                            <Control/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/preview`}
                    element={
                        <ProtectedRoute user={user}>
                            <Preview/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/autotemplate`}
                    element={
                        <ProtectedRoute user={user}>
                            <Autotemplate/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/templates`}
                    element={
                        <ProtectedRoute user={user}>
                            <Templates/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/monitoring`}
                    element={
                        <ProtectedRoute user={user}>
                            <Monitoring/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/monitoring/test`}
                    element={
                        <ProtectedRoute user={user}>
                            <Monitoring/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/monitoring/:id/claims/edit`}
                    element={
                        <ProtectedRoute user={user}>
                            <MonitoringClaimEdit/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/monitoring/:id/take-to-court`}
                    element={
                        <ProtectedRoute user={user}>
                            <TakeToCourt/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/claims/send`}
                    element={
                        <ProtectedRoute user={user}>
                            <ClaimSend/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/claims/create`}
                    element={
                        <ProtectedRoute user={user}>
                            <ClaimCreate/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/claims/:id/create`}
                    element={
                        <ProtectedRoute user={user}>
                            <ClaimCreate/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`/courts/send`}
                    element={
                        <ProtectedRoute user={user}>
                            <CourtCreate/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={'/counterparties'}
                    element={
                        <ProtectedRoute user={user}>
                            <Counterparties/>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={'/integrations/:id/edit'}
                    element={
                        <ProtectedRoute user={user}>
                            <IntegrationEditForm />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesList;
