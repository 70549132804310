import React from "react";
import clsx from "clsx";

import {typeFilters} from "../../Helpers/Constants/monitoring";

const FilterMenu = ({activeTypeFilter, onTypeFilterChange}) => {
    return (
        <div className={`menu__filter`}>
            {typeFilters.map((typeFilter, index) => {
                return (
                    <div
                        key={`type__${typeFilter.id}`}
                        className={clsx(
                            `filter__value`,
                            {'filter__active': activeTypeFilter === typeFilter.id}
                        )}
                        data-filter-type={typeFilter.id}
                        onClick={onTypeFilterChange}
                    >
                        {typeFilter.title}
                    </div>);
            })}
        </div>
    )
}

export default FilterMenu;