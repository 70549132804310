import React, {useState} from "react";
import clsx from "clsx";

const TariffSubscriptions = ({tariffs, selectedTariff, onTariffChange}) => {
    const onTariffSelect = (tariffToSelect) => onTariffChange(
            (selectedTariff?.id !== tariffToSelect?.id) ? tariffToSelect : null
        );

    return (
        <div className={`tariffs-list__subscriptions`}>
            {tariffs.map((tariff, index) => {
                return (
                    <div key={`tariff_card__${index}`}
                         className={clsx(
                             {'tariff__selected': selectedTariff && tariff.id === selectedTariff.id}, `tariffs-list__subscription`
                         )}
                         onClick={() => onTariffSelect(tariff)}
                    >
                        <div className={`subscription__row`}>
                            <div className={`subscription__title`}>
                                {tariff.title}
                            </div>
                            {tariff.discount && (
                                <div className={`subscription__discount`}>
                                    {tariff.discount}
                                </div>
                            )}
                        </div>
                        <div className={`subscription__price`}>
                            {tariff.price} <span className={`subscription__period`}>/{tariff.period}</span>
                        </div>

                    </div>
                )
            })}
        </div>
    )
}

export default TariffSubscriptions;