import React from "react";

const IntegrationStatusIcon = ({active}) => {
    return (
        <div className={`integration-item__status-icon`}>
            {(active) ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M4.46589 12.001L0.699219 8.23438L1.64922 7.30104L4.48255 10.1344L5.41589 11.0677L4.46589 12.001ZM8.23255 12.001L4.46589 8.23438L5.39922 7.28438L8.23255 10.1177L14.3659 3.98438L15.2992 4.93438L8.23255 12.001ZM8.23255 8.23438L7.28255 7.30104L10.5826 4.00104L11.5326 4.93438L8.23255 8.23438Z"
                        fill="#51CF66"/>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path
                        d="M9.66732 1.27203L8.72732 0.332031L5.00065 4.0587L1.27398 0.332031L0.333984 1.27203L4.06065 4.9987L0.333984 8.72536L1.27398 9.66536L5.00065 5.9387L8.72732 9.66536L9.66732 8.72536L5.94065 4.9987L9.66732 1.27203Z"
                        fill="#E73F3F"/>
                </svg>
            )}
        </div>
    );
}

export default IntegrationStatusIcon;