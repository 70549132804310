export const getRupostFields = (user) => {
    return {
        recipient: [
            {
                placeholder: 'Адрес получателя',
                name: 'recipient_address',
                defaultValue: '',
                type: 'text'
            },
            {
                placeholder: 'Почтовый индекс получателя',
                name: 'recipient_postalCode',
                defaultValue: '',
                type: 'text'
            },
            {placeholder: 'Имя получателя', name: 'recipient_person_firstName', defaultValue: '', type: 'text'},
            {placeholder: 'Фамилия получателя', name: 'recipient_person_lastName', defaultValue: '', type: 'text'},
            {placeholder: 'Отчество получателя', name: 'recipient_person_patronymic', defaultValue: '', type: 'text'},
        ],
        sender: [
            {placeholder: 'Код отправителя', name: 'sender_code', type: 'text'},
            {placeholder: 'Наименование отправителя', name: 'sender_name', defaultValue: user.username, type: 'text'},
            {
                placeholder: 'Код подразделения отправителя',
                name: 'sender_departmentCode',
                defaultValue: '',
                type: 'text'
            },
            {
                placeholder: 'Наименование подразделения отправителя',
                name: 'sender_departmentName',
                defaultValue: '',
                type: 'text'
            },
            {placeholder: 'Адрес отправителя', name: 'sender_address', defaultValue: '', type: 'text'},
        ],
        document: [
            {placeholder: 'Номер бланка заказа', name: 'orderFormNumber', defaultValue: '', type: 'text'},
        ],
    }
};

/**
 * Хелпер для перевода snake_case в camelCase (нужен для обработки полей из дадаты, тк почта россии принимает только camelCase
 * @param item
 * @returns {{[p: string]: *|{[p: string]: any}}|*}
 */
export const camelize = item => {
    if (Array.isArray(item)) {
        return item.map(el => camelize(el));
    } else if (typeof item === 'function' || item !== Object(item)) {
        return item;
    }
    return Object.fromEntries(
        Object.entries(item).map(([key, value]) => [
            key.replace(/([-_][a-z])/gi, c => c.toUpperCase().replace(/[-_]/g, '')),
            camelize(value),
        ]),
    );
};

/**
 * Хелпер для преобразования формы отправки с множественным получателем, удобоваримый для почты россии.
 * Получает индекс элемента формдаты, вшитый в строку
 * @param string строка вида value[%index%], содержащая некоторый индекс
 * @returns {number|NaN} индекс в виде числа либо NaN, если в скобках написана ересь
 */
export const splitByBracket = (string) => Number(
    string.substring(string.indexOf('[') + 1, string.indexOf(']'))
);

/**
 * Преобразование элемента вида value[%index%] в вид value, т.е. без индекса
 * @param string строка вида value[%index%], содержащая некоторый индекс
 * @returns {string|*} строка вида value, не содержащая индекса
 */
export const withoutBrackets = (string) => (string.indexOf('[') > 0)
    ? string.substring(0, string.indexOf('[')) : string;