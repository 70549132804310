export const typeFilters = [
    {id: 'contracts', title: 'Договоры'},
    {id: 'claims', title: 'Претензии'},
    {id: 'incoming-claims', title: 'Входящие претензии'},
    {id: 'courts', title: 'Иски'},
];

export const viewTypeFilters = [
    {id: 'column', title: 'column'},
    {id: 'grid', title: 'grid'}
];