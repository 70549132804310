import moment from "moment";

/**
 * Получить локализованную и готовую к отображению строку,
 * содержащую время с периода последней смены пароля
 *
 * Дата может браться из локального хранилища (появляется при запросе на смену пароля из клиентских настроек даты и времени)
 * либо с сервера (из ответа на запрос на смену пароля)
 * @returns {string}
 */
const getLocalPasswordUpdate = (localUpdateDate, reduxUpdateDate) => {
    let lastUpdateTime = null;
    // https://stackoverflow.com/questions/25150570/get-hours-difference-between-two-dates-in-moment-js
    const now = moment(new Date());
    const end = moment(localUpdateDate);
    const duration = moment.duration(now.diff(end)).asMinutes();
    if (duration < 10) {
        lastUpdateTime = localUpdateDate;
    } else {
        lastUpdateTime = reduxUpdateDate;
    }

    return moment(lastUpdateTime)
        .utc()
        .add(moment(lastUpdateTime).utcOffset(), 'm')
        .startOf('minute')
        .from(moment().utc().add(moment(lastUpdateTime)
                .utcOffset(), 'm'
            )
        )
}

export { getLocalPasswordUpdate };