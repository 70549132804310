import React from "react";
import clsx from "clsx";

const DocumentLoadScreen = ({ isOnLoad, neuralEventsPresented, isPreview = false }) => {

    const errorMessage = (neuralEventsPresented)
        ? 'Загрузка документа...'
        : 'Идет обработка сервисом ИИ, подождите';

    return (
      <div className={clsx({ 'hidden': !isOnLoad }, 'documents__load')}>
          <img src={`../../img/documents/control__load.svg`} alt={``} />
          {!isPreview && errorMessage}
      </div>
    );
}

export default DocumentLoadScreen;