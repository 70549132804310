import React, {useEffect, useState} from "react";
import Sidemenu from "../sidemenu/Sidemenu";
import {useNavigate, useParams} from "react-router-dom";
import {APP_URL, useBearerToken} from "../../API/requests";
import IntegrationEditInput from "./IntegrationEditInput";

const IntegrationEditForm = () => {
    const navigate = useNavigate();
    const axiosInstance = useBearerToken();
    const {id} = useParams();
    const [service, setService] = useState({});
    const [submitButtonCaption, setSubmitButtonCaption] = useState('Сохранить');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const integrationRouteURL = `${APP_URL}/me/integrations/${id}/`;

    useEffect(() => {
        axiosInstance.get(integrationRouteURL).then((response) => {
            console.log(response);
            setService(response.data);
        }).catch((e) => console.log(e.message));
    }, []);

    const onCancel = () => {
        return navigate('/integrations');
    }

    const onSubmit = (e) => {
        e.preventDefault();
        axiosInstance.patch(integrationRouteURL, service).then(((response) => {
            console.log(response);
            const { status } = response;
            if (status === 200) {
                setSubmitButtonCaption('Успешно!');
                setSubmitDisabled(true);
                setTimeout(() => {
                    setSubmitDisabled(false);
                    setSubmitButtonCaption('Сохранить');
                    navigate('/integrations/');
                }, 500)
                console.log('test');
            }
        })).catch((error) => console.log(error));
    }

    const onChange = (name, value) => {
        const serviceToUpdate = service;
        serviceToUpdate[name] = value;
        console.log(serviceToUpdate);
    }

    return (
        <div className={`settings__container`}>
            <Sidemenu/>

            <div className={`settings__content-wrapper`}>
                <h1>
                    Настройка интеграции
                </h1>

                <form className={`integrations__form`} onSubmit={onSubmit}>
                    <div className={`integrations__form-container`}>
                        <h2>{service.service_name}</h2>

                        <IntegrationEditInput onInputChange={onChange} caption={`Логин`} name={`login`} value={service.login}/>
                        <IntegrationEditInput onInputChange={onChange} caption={`Ключ АПИ`} name={`api_key`} value={service.api_key}/>

                        <div className={`integration__buttons-list`}>
                            <button className={`integration__form-cancel`} onClick={onCancel}>Отменить</button>
                            <input
                                className={`integration__form-submit`}
                                type={`submit`}
                                value={submitButtonCaption}
                                disabled={submitDisabled}
                            />
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default IntegrationEditForm;