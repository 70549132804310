import React, { useContext } from "react";

export const themes = {
    dark: "dark",
    light: "light"
};

export const ThemeContext = React.createContext({});

export const useThemeContext = () => useContext(ThemeContext);
