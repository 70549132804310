import React, { useEffect, useState } from "react";
import clsx from "clsx";

import Sidemenu from "../../Components/sidemenu/Sidemenu";
import MonitoringContract from "../../Components/monitoring/MonitoringContract";
import { APP_URL, useBearerToken } from "../../API/requests";

import "./styles/documents.scss";
import "./styles/monitoring.scss";
import "./styles/autotemplates.scss";

const Templates = () => {
    const axiosInstance = useBearerToken();

    const [contractsList, setContractsList] = useState([]);
    const [activeTypeFilter, setActiveTypeFilter] = useState("contracts");
    const [monitoringDisplay, setMonitoringDisplay] = useState("column");
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [sortSubject, setSortSubject] = useState(null); // по какому полю сортируем (по умолчанию дата)
    const [sortOrder, setSortOrder] = useState(null); // в каком порядке (по умолчанию от последнего к первому)

    useEffect(() => {
        const sortQueryString = sortSubject && sortOrder ? `?sort_${sortSubject}=${sortOrder}` : ``;
        axiosInstance.get(`${APP_URL}/contracts/${sortQueryString}`).then((response) => {
            console.log(response.data);
            setContractsList(response.data?.contracts);
        });
    }, [sortOrder, sortSubject]);

    useEffect(() => {
        if (!sortOrder) {
            setSortOrder("desc");
        }
    }, [sortSubject]);

    const typeFilters = [
        { id: "user", title: "Пользовательские" },
        { id: "system", title: "Системные" }
    ];

    const viewTypeFilters = [
        { id: "column", title: "column" },
        { id: "grid", title: "grid" }
    ];

    const getFilterType = (e) => e.target.getAttribute("data-filter-type") ?? null;

    const handleTypeFilterChange = (e) => {
        const filterType = getFilterType(e);
        setActiveTypeFilter(filterType);
    };

    const handleViewFilterChange = (e) => {
        // console.log(e.target);
        const filterType = getFilterType(e);
        setMonitoringDisplay(filterType);
    };

    const handleSelectContract = (contract) => {
        // console.log(contract);
        if (selectedEntity && contract.id === selectedEntity.id) {
            setSelectedEntity(null);
        } else setSelectedEntity(contract);
    };

    const handleSubjectChange = (e) => {
        const { target } = e;
        const sortSubjectAttribute = target.getAttribute("data-filter-type");

        if (sortSubjectAttribute) {
            setSortSubject(sortSubjectAttribute);
        }
    };

    const handleSortOrderChange = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    return (
        <>
            <div className={clsx(`monitoring__container`)}>
                <Sidemenu />
                <div className={`monitoring__content-wrapper`}>
                    <div className={`monitoring__row`}>
                        <div className={`monitoring__button-back`}></div>
                        <div className={`monitoring__main`}>
                            <div className={`monitoring__main-title`}>Создать документ по шаблону</div>

                            <div className={`monitoring__main-menu`}>
                                <div className={`menu__search`}></div>
                                <div className={`menu__filter`}>
                                    {typeFilters.map((typeFilter, index) => {
                                        return (
                                            <div
                                                key={`type__${typeFilter.id}`}
                                                className={clsx(`filter__value`, `filter__template-type`, {
                                                    filter__active: activeTypeFilter === typeFilter.id
                                                })}
                                                data-filter-type={typeFilter.id}
                                                onClick={handleTypeFilterChange}
                                            >
                                                <img
                                                    src={`../../img/autotemplates/templates__${typeFilter.id}.svg`}
                                                    alt={``}
                                                />{" "}
                                                {typeFilter.title}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={`menu__sort`}>
                                    <div
                                        className={clsx(`filter__sort`, { order__asc: sortOrder === "asc" })}
                                        onClick={handleSortOrderChange}
                                    >
                                        <img src={`../../img/monitoring/sort__order.svg`} alt={``} />
                                    </div>
                                    <div
                                        className={clsx({ filter__active: sortSubject === "name" }, `filter__value`)}
                                        data-filter-type={`name`}
                                        onClick={handleSubjectChange}
                                    >
                                        Имя
                                    </div>
                                    <div
                                        className={clsx({ filter__active: sortSubject === "date" }, `filter__value`)}
                                        data-filter-type={`date`}
                                        onClick={handleSubjectChange}
                                    >
                                        Дата
                                    </div>
                                </div>

                                <div className={`menu__view-type`}>
                                    {viewTypeFilters.map((viewTypeFilter) => {
                                        return (
                                            <div
                                                key={`view__${viewTypeFilter.id}`}
                                                className={clsx(`filter__value`, `value__layout`, {
                                                    filter__active: monitoringDisplay === viewTypeFilter.id
                                                })}
                                                data-filter-type={viewTypeFilter.id}
                                                onClick={handleViewFilterChange}
                                            >
                                                <img src={`../../img/layout__${viewTypeFilter.title}.svg`} alt={``} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div
                                className={clsx(
                                    { display__grid: monitoringDisplay === "grid" },
                                    `monitoring__main-content`
                                )}
                            >
                                {contractsList && contractsList.map((contract, index) => {
                                    if (contract.status === "success") {
                                        return (
                                            <MonitoringContract
                                                key={`contract__${index}`}
                                                contract={contract}
                                                contractDisplay={monitoringDisplay}
                                                callbackOnClick={handleSelectContract}
                                                isSelected={selectedEntity && selectedEntity.id === contract.id}
                                            />
                                        );
                                    } else return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Templates;
