import React, {useState} from "react";
import clsx from "clsx";

const ModalOnLoadStart = ({
                              callbackOnAreaClick,
                              callbackOnDocumentLoad,
                              callbackOnExit,
                              selectedFileName,
                              buttonActive
                          }) => {

    const [files, setFiles] = useState([]);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            // const newFiles = Array.from(droppedFiles);
            callbackOnAreaClick(e, true, e.dataTransfer.files);
        }
    };

    return (
        <div className={`settings__modal-content`}>
            <div className={`dashboard__modal-title`}>
                Загрузить документ
            </div>

            <div className={`dashboard__modal-load-area`} onClick={callbackOnAreaClick}>
                <div
                    className={`dashboard__modal-caption`}
                    onDrop={handleDrop}
                    onDragOver={(event) => event.preventDefault()}
                >
                    <div className={`dashboard__modal-caption__icon`}>
                        <img src={`../../img/dashboard__modal-load.svg`} alt={``}/>
                    </div>
                    <div className={clsx(
                        `dashboard__modal-caption__text`,
                        {['file__loaded']: selectedFileName}
                    )}
                    >
                        {selectedFileName ?? `Перетащите документ в область загрузки или выберите расположение`}
                    </div>
                </div>
            </div>

            <div className={`dashboard__modal-buttons`}>
                <button
                    className={`dashboard__menu-button button__destination`}
                    onClick={callbackOnAreaClick}
                >
                    Выбрать расположение
                </button>
                <button
                    className={`dashboard__menu-button button__save`}
                    onClick={callbackOnDocumentLoad}
                    disabled={!buttonActive}
                >
                    Загрузить
                </button>
            </div>

            <div className={`settings__exit`} onClick={callbackOnExit}>
                <img src={`/img/modal__exit.svg`} alt={``}/>
            </div>
        </div>
    )
}

export default ModalOnLoadStart;