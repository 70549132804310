import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getRupostFields } from "../../Helpers/Constants/rupost";
import clsx from "clsx";

/**
 * Список получателей
 * @param props
 * @returns {Element}
 */
const RecipientsList = (props) => {
    const {
        typeSelected,
        recipientsList,
        handleInputChange,
        addRecipient,
        recipientsAmount,
        rupostFields,
        setRupostFields,
        isValidate
    } = props;

    const user = useSelector((state) => state.user);

    useEffect(() => {
        // console.log(rupostFields);
    }, [rupostFields]);

    const fieldData = {
        id: `${typeSelected}-input__`,
        placeholder: `${
            typeSelected === "diadoc"
                ? "ИНН контрагента"
                : typeSelected === "rupost"
                ? "Название получателя"
                : "Email получателя"
        }`
    };

    const handleRupostInputChange = (e) => {
        const { target } = e;
        const targetType = target.getAttribute("data-rupost-type");

        if (target.value.trim()) {
            target.classList.remove("form__input-invalid");
        }

        if (targetType) {
            const entityToUpdate = { [target.name]: target.value };
            const rupostFieldsToUpdate = rupostFields;
            // console.log(rupostFieldsToUpdate, entityToUpdate);
            Object.assign(rupostFieldsToUpdate, entityToUpdate);
            setRupostFields(rupostFieldsToUpdate);
            console.log(rupostFields);
        }
    };

    const rupostFieldsList = getRupostFields(user);

    return (
        <>
            {[...Array(1).keys()].map((value, index) => {
                return (
                    <div key={`recipient__${index}`} className={`claim-send__delivery-recipient`}>
                        <div className={`claim-send__delivery-recipient--name`}>
                            {typeSelected !== "rupost" && (
                                <input
                                    type={`text`}
                                    data-item-key={`${fieldData.id}${index}`}
                                    data-item-type={"requisite"}
                                    placeholder={fieldData.placeholder}
                                    onChange={handleInputChange}
                                    defaultValue={recipientsList[`${fieldData.id}${index}`]?.requisite ?? ""}
                                />
                            )}
                            {index === 0 && (
                                <button
                                    key={`add-recipient__${index}`}
                                    className={`claim-send__delivery-add-recipient`}
                                    onClick={addRecipient}
                                >
                                    Добавить получателя
                                </button>
                            )}
                        </div>
                        {typeSelected === "rupost" ? (
                            <React.Fragment>
                                {[rupostFieldsList.sender, rupostFieldsList.document].map(
                                    (rupostFieldSection, index) => {
                                        return (
                                            <React.Fragment key={`rupost-main-fields__${index}-${Math.random()}`}>
                                                <div className={`recipient__separator`}>
                                                    {index === 0 ? "Отправитель" : "Документ"}
                                                </div>
                                                {rupostFieldSection.map((rupostField, index) => {
                                                    return (
                                                        <input
                                                            data-rupost-type={index}
                                                            key={`rupost-field__${index}-${Math.random()}`}
                                                            name={rupostField.name}
                                                            placeholder={rupostField.placeholder}
                                                            type={rupostField.type}
                                                            defaultValue={
                                                                rupostFields[rupostField.name] ??
                                                                rupostField.defaultValue ??
                                                                ""
                                                            }
                                                            onChange={handleRupostInputChange}
                                                            className={clsx({
                                                                "form__input-invalid":
                                                                    isValidate &&
                                                                    !rupostFields[rupostField.name] &&
                                                                    rupostField.name !== "orderFormNumber"
                                                            })}
                                                        />
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    }
                                )}

                                {[...Array(recipientsAmount ?? 1).keys()].map((value, index) => {
                                    return (
                                        <React.Fragment key={`recipients-amount__${index}`}>
                                            <div className={`recipient__separator`}>Получатель №{index + 1}</div>
                                            {rupostFieldsList.recipient.map((rupostField) => {
                                                return (
                                                    <input
                                                        data-rupost-type={index}
                                                        key={`rupost-field__${index}-${Math.random()}`}
                                                        name={`${rupostField.name}[${index}]`}
                                                        placeholder={rupostField.placeholder}
                                                        type={rupostField.type}
                                                        defaultValue={
                                                            rupostFields[`${rupostField.name}[${index}]`] ??
                                                            rupostField.defaultValue ??
                                                            ""
                                                        }
                                                        className={clsx({
                                                            "form__input-invalid":
                                                                isValidate &&
                                                                !rupostFields[`${rupostField.name}[${index}]`] &&
                                                                rupostField.name !== "orderFormNumber"
                                                        })}
                                                        onChange={handleRupostInputChange}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default RecipientsList;
