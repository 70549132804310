import React from "react";
import AuthScreen from "../Components/auth-screen/AuthScreen";
import LoginForm from "../Components/forms/LoginForm";
import {registerFormState, registerPromoFormState} from "../Helpers/initialStateValues";
import RegisterLinks from "../Components/forms/RegisterLinks";

const Register = ({promo = false}) => {
    return (
        <div className={`auth__container`}>
            <AuthScreen content={
                <LoginForm
                    formCaption={`Регистрация`}
                    formFields={(promo) ? registerPromoFormState : registerFormState }
                    buttonCaptionOnSubmit={`Зарегистрироваться`}
                    links={<RegisterLinks promo={promo}/>}
                    formEndpoint={`/register`}
                />}
            />

        </div>
    )
}

export default Register;