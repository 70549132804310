import React from "react";

const ProfileActions = ({
                            handleAddPromo,
                            navigateToPolicy,
                            handleAccountDelete
                        }) => {
    return (
        <div className={`settings-profile__actions`}>
            <div className={`settings-profile__action`} onClick={handleAddPromo}>
                Ввести промокод
                <img src={`/img/arrow__right.svg`} alt={``}/>
            </div>
            <div className={`settings-profile__action`} onClick={navigateToPolicy}>
                Политика конфиденциальности
                <img src={`/img/arrow__right.svg`} alt={``}/>
            </div>
            <div className={`settings-profile__action`} onClick={handleAccountDelete}>
                Удалить аккаунт
                <img src={`/img/arrow__right.svg`} alt={``}/>
            </div>
        </div>
    );
}

export default ProfileActions;