import React, {
    useEffect,
    useRef,
    useState
} from "react";
import clsx from "clsx";

import { APP_URL, useBearerToken } from "../../API/requests";

import Sidemenu from "../../Components/sidemenu/Sidemenu";

import SortMenu from "../../Components/monitoring/SortMenu";
import FilterMenu from "../../Components/monitoring/FilterMenu";
import ViewType from "../../Components/monitoring/ViewType";
import MonitoringActions from "../../Components/monitoring/MonitoringActions";
import MonitoringResultsList from "../../Components/monitoring/MonitoringResultsList";

import ModalStopControl from "../../Components/monitoring/modal/ModalStopControl";

import "./styles/documents.scss";
import "./styles/monitoring.scss";

const Monitoring = () => {
    document.title = `Мониторинг | Дельта Про`;

    const axiosInstance = useBearerToken();

    const [contractsList, setContractsList] = useState([]);
    const [activeTypeFilter, setActiveTypeFilter] = useState("contracts");
    const [monitoringDisplay, setMonitoringDisplay] = useState("column");
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [sortSubject, setSortSubject] = useState("date");
    const [sortOrder, setSortOrder] = useState("desc");
    const [showModalEventLog, setShowModalEventLog] = useState(false);
    const [axiosLoading, setAxiosLoading] = useState(false);
    const [isOpenStopControlModal, setIsOpenStopControlModal] = useState(false);

    const searchTabRef = useRef(null);

    useEffect(() => {
        if (!axiosLoading) {
            handleSortQuery();
        }
    }, [sortOrder, sortSubject]);

    useEffect(() => {
        if (!sortOrder) {
            setSortOrder("desc");
        }
    }, [sortSubject]);

    const getFilterType = (e) => e.target.getAttribute("data-filter-type") ?? null;

    const handleSortQuery = () => {
        setAxiosLoading(true);
        const sortQueryString = sortSubject && sortOrder ? `?sort_${sortSubject}=${sortOrder}` : ``;
        axiosInstance.get(`${APP_URL}contracts/${sortQueryString}`).then((response) => {
            // console.log(response.data);
            setContractsList(response.data?.contracts);
            setAxiosLoading(false);
        });
    };

    const handleTypeFilterChange = (e) => {
        const filterType = getFilterType(e);
        setActiveTypeFilter(filterType);
    };

    const handleViewFilterChange = (e) => {
        const filterType = getFilterType(e);
        setMonitoringDisplay(filterType);
    };

    // @todo код дублируется в сравнении со страницей шаблонов, нужен рефакторинг
    const handleSelectContract = (contract) => {
        // console.log(contract);
        if (selectedEntity && contract.id === selectedEntity.id) {
            setSelectedEntity(null);
        } else setSelectedEntity(contract);
    };

    const handleSubjectChange = (e) => {
        const { target } = e;
        const sortSubjectAttribute = target.getAttribute("data-filter-type");

        if (sortSubjectAttribute) {
            if (sortSubjectAttribute === sortSubject) {
                setSortSubject(null);
            } else setSortSubject(sortSubjectAttribute);
        }
    };

    const searchQuery = (value) => {
        if (!axiosLoading) {
            setAxiosLoading(true);
            return value
                ? axiosInstance.get(`${APP_URL}contracts/search/${value}/`).then((response) => {
                      setAxiosLoading(false);
                      if (response.data) {
                          const { contracts } = response.data;
                          setContractsList(contracts ?? []);
                      }
                  })
                : handleSortQuery(); // обработка ввода пустой строки для сброса поиска
        } else return 0;
    };

    /**
     * Обработчик результатов поисковой выдачи
     * @param e
     */
    const handleSearch = (e) => {
        const { key } = e;

        if (key) {
            if (key === "Enter") {
                const { value } = searchTabRef.current;
                searchQuery(value);
            } else if (key === "Escape") {
                const { value } = searchTabRef.current;
                if (value) {
                    searchTabRef.current.value = "";
                    handleSortQuery();
                }
            }
        }
    };

    const handleSearchOnClick = (e) => {
        const { value } = searchTabRef.current;
        return value ? searchQuery(value) : handleSortQuery();
    };

    const handleSortOrderChange = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const showEventLogModal = (e) => {
        if (!showModalEventLog) {
            setShowModalEventLog(true);
        } else {
            setShowModalEventLog(false);
        }
    };

    const handleEntityUpdate = (entity, dataToUpdate) => {
        const contractsListLink = [...contractsList];
        const { id } = entity;

        if (contractsListLink.constructor === Array) {
            const indexToUpdate = contractsListLink.findIndex((innerEntity) => innerEntity.id === id);
            if (indexToUpdate || indexToUpdate === 0) {
                const elementToUpdate = contractsListLink[indexToUpdate];
                for (const [key, value] of Object.entries(dataToUpdate)) {
                    if (elementToUpdate.hasOwnProperty(key)) {
                        elementToUpdate[key] = value;
                    }
                }

                contractsListLink[indexToUpdate] = elementToUpdate;
                setContractsList(contractsListLink);
            }
        }
    };

    const handleEntityDelete = (entity) => {
        handleSortQuery();
    };

    const handleStopCourtsControl = () => {
        //открыть модалку
        setIsOpenStopControlModal(true);
    };

    return (
        <>
            <div className={clsx(`monitoring__container`)}>
                <Sidemenu />
                <div
                    className={clsx(`settings__modal-container`, {
                        [`settings__modal-visible`]: isOpenStopControlModal
                    })}
                >
                    <div className="settings__modal-wrapper">
                        <ModalStopControl
                            isOpen={isOpenStopControlModal}
                            onRequestClose={() => setIsOpenStopControlModal(false)}
                        />
                    </div>
                </div>
                <div className={`monitoring__content-wrapper`}>
                    <div className={`monitoring__row`}>
                        <div className={`monitoring__button-back`}></div>
                        <div className={`monitoring__main`}>
                            <div className={`monitoring__main-title`}>Мониторинг</div>

                            <div className={`monitoring__main-menu`}>
                                <div className={`menu__search`}>
                                    <img
                                        src={`../../img/monitoring/monitoring__search.svg`}
                                        className={`search__image`}
                                        alt={``}
                                        onClick={handleSearchOnClick}
                                    />

                                    <input
                                        ref={searchTabRef}
                                        className={`search__input`}
                                        placeholder={`Поиск по документам`}
                                        onKeyUp={handleSearch}
                                    />
                                </div>

                                <FilterMenu
                                    activeTypeFilter={activeTypeFilter}
                                    onTypeFilterChange={handleTypeFilterChange}
                                />

                                <SortMenu
                                    onOrderChange={handleSortOrderChange}
                                    onSubjectChange={handleSubjectChange}
                                    order={sortOrder}
                                    subject={sortSubject}
                                />

                                <ViewType
                                    monitoringDisplay={monitoringDisplay}
                                    onViewFilterChange={handleViewFilterChange}
                                />
                            </div>

                            {!axiosLoading ? (
                                <MonitoringResultsList
                                    monitoringDisplay={monitoringDisplay}
                                    contractsList={contractsList}
                                    onContractSelect={handleSelectContract}
                                    selectedEntity={selectedEntity}
                                    showEventLog={showModalEventLog}
                                    eventLogCallback={showEventLogModal}
                                    activeTypeFilter={activeTypeFilter}
                                />
                            ) : (
                                <>
                                    <div className={clsx("documents__load", "relative")}>
                                        <img src={`../../img/documents/control__load.svg`} alt={``} />
                                        Загрузка...
                                    </div>
                                </>
                            )}
                        </div>

                        <MonitoringActions
                            statesList={[]}
                            selectedEntity={selectedEntity}
                            callbackOnEntityChange={handleEntityUpdate}
                            callbackOnEntityDelete={handleEntityDelete}
                            activeTypeFilter={activeTypeFilter}
                            handleStopCourtsControl={handleStopCourtsControl}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Monitoring;
