import React from "react";

const BoundedDocuments = ({onModalShow}) => {
    const handleModalShow = (e) => {
        const {target} = e;
        onModalShow(target.getAttribute('data-modal-type'));
    };

    return (
        <div className={`documents__bounded-elements`}>
            <div className={`documents__bounded-title`}>
                СВЯЗАННЫЕ ДОКУМЕНТЫ
            </div>

            <div className={`documents__bounded-buttons`}>
                <button
                    className={`documents__button-specs`}
                    onClick={handleModalShow}
                    data-modal-type={`specification`}
                >
                            <span className={`documents__button-text`}>
                                Спецификация
                            </span>
                    <img src={`./img/documents/btn__specs.svg`} alt={``}/>
                </button>

                <button
                    className={`documents__button-integration`}
                    onClick={handleModalShow}
                    data-modal-type={`integration`}
                >
                            <span className={`documents__button-text`}>
                            Настроить интеграцию с внешними cервисами
                            </span>
                    <img src={`./img/documents/btn__integration.svg`} alt={``}/>
                </button>

                <button
                    className={`documents__button-reaction`}
                    onClick={handleModalShow}
                    data-modal-type={`reaction`}
                >
                            <span className={`documents__button-text`}>
                            Настроить реакцию на нарушение
                            </span>
                    <img src={`./img/documents/btn__reaction.svg`} alt={``}/>
                </button>
            </div>
        </div>
    );
}

export default BoundedDocuments;