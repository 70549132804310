import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {APP_URL, useBearerToken} from "../../API/requests";
import {parseContractResponse} from "../../Helpers/contracts";
import DocumentLoadScreen from "../../Components/doc-viewer/utils/DocumentLoadScreen";
import clsx from "clsx";
import DocumentViewer from "../../Components/doc-viewer/DocumentViewer";

import './styles/autotemplates.scss';

const Autotemplate = ({}) => {
    const navigate = useNavigate();

    const axiosInstance = useBearerToken();
    const [searchParams, setSearchParams] = useSearchParams();


    const [activeDocument, setActiveDocument] = useState('');
    const [isOnload, setIsOnload] = useState(true);
    const [status, setStatus] = useState(null);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        const selectedContract = searchParams.get('contract');
        // axiosInstance.get(`${APP_URL}contracts/`).then((response) => {console.log(response.data);})
        axiosInstance.get(`${APP_URL}contracts/${selectedContract}/?expand_events=true`).then((response) => {
            const {data} = response;
            if (data?.contract) {

                const contractInfo = data;
                if (contractInfo.contract) {
                    setActiveDocument(contractInfo.contract);
                }
                const contractResponse = parseContractResponse(contractInfo);
                setStatus(data.status);
                setTitle(data.name_of_contract);
            }
        })
    }, []);

    const handleDocumentControl = () => {
        return navigate('/monitoring');
    }


    const handleDocumentLoad = () => {
        setIsOnload(false);
    }

    const autotemplateMock = {
        contract: [
            {title: 'Место заключения', id: 'place', type: 'text'},
            {title: 'Дата заключения', id: 'date', type: 'date'},
            {title: 'Название организации и форма – Поставщик', id: 'vendor_org', type: 'person'},
            {title: 'ФИО подписанта – Поставщик', id: 'vendor_name', type: 'person'},
            {title: 'Название организации и форма – Заказчик', id: 'customer_org', type: 'person'},
            {title: 'ФИО подписанта – Заказчик', id: 'customer_name', type: 'person'},
        ],
        conditions: [
            {title: 'Срок предоставления документов', id: 'terms', type: 'number'},
        ],

        prices: [
            {title: 'Свой вариант ответа', id: 'price_1`', type: 'text'},
        ],

        payments: [
            {title: 'Свой вариант ответа', id: 'price_2', type: 'text'}
        ]
    };

    const priceFields = [
        {id: 'product_prices', title: 'Цены на продукцию', nestedElement: 'prices' },
        {id: 'payment_order', title: 'Порядок расчётов', nestedElement: 'payments' }
    ]

    return (
        <>
            <DocumentLoadScreen
                isOnLoad={isOnload}
                neuralEventsPresented={status && status === 'success'}
            />
            <div className={clsx(`documents__panel`, {'panel__onload': isOnload})}>
                <a className={`button__back`} href={`/dashboard`}>
                    <img src={`/img/arrow__back.svg`} alt={``}/>
                </a>

                <div className={`documents__viewer-container`}>
                    <div className={`documents__viewer-title`}>
                        {title}
                    </div>
                    <DocumentViewer
                        documentUri={activeDocument}
                        callbackOnDocumentLoad={handleDocumentLoad}
                    />
                </div>

                <div className={`documents__events-list events__autotemplate`}>

                    <div className={`documents__events-element`}>
                        <div className={`documents__event-title`}>
                            ДОГОВОР ПОСТАВКИ
                        </div>

                        {autotemplateMock.contract.map((contractEntity) => {
                            return (
                                <div key={contractEntity.id} className={`autotemplate__input-group`}>

                                    <div className={`autotemplate__label`}>
                                        {contractEntity.title}
                                    </div>
                                    <div className={`autotemplate__input-container`}>
                                        <div className={`autotemplate__input-icon`}>
                                            <img src={`../../img/autotemplates/icon__${contractEntity.type}.svg`}
                                                 alt={``}/>
                                        </div>
                                        <input
                                            className={`autotemplate__input-field`}
                                            placeholder={contractEntity.title}
                                            type={(contractEntity.type === 'date') ? 'datetime-local' : 'text'}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className={`documents__events-element`}>
                        <div className={`documents__event-title`}>
                            УСЛОВИЯ И СРОКИ ПОСТАВКИ
                        </div>

                        {autotemplateMock.conditions.map((contractEntity) => {
                            return (
                                <div key={contractEntity.id} className={`autotemplate__input-group`}>

                                    <div className={`autotemplate__label`}>
                                        {contractEntity.title}
                                    </div>
                                    <div className={`autotemplate__input-container`}>
                                        <div className={`autotemplate__input-icon`}>
                                            <img src={`../../img/autotemplates/icon__${contractEntity.type}.svg`}
                                                 alt={``}/>
                                        </div>
                                        <input
                                            className={`autotemplate__input-field`}
                                            placeholder={contractEntity.title}
                                            type={(contractEntity.type === 'date') ? 'datetime-local' : 'text'}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {priceFields.map((priceField) => {
                        return (
                            <div key={priceField.id} className={`documents__events-element`}>
                                <div className={`documents__event-title`}>
                                    {priceField.title.toUpperCase()}
                                </div>

                                <div className={`autotemplate__input-group`}>

                                    <div className={`autotemplate__label`}>
                                        НДС
                                    </div>
                                    <div className={`autotemplate__input-container`}>
                                        <fieldset>

                                            <div>
                                                <input type="radio" id={`vat_true_${priceField.id}`} name={`vat_${priceField.id}`} value="true" checked/>
                                                <label htmlFor={`vat_true_${priceField.id}`}>Входит</label>
                                            </div>
                                            <div>
                                                <input type="radio" id={`vat_false_${priceField.id}`} name={`vat_${priceField.id}`} value="false" checked/>
                                                <label htmlFor={`vat_false_${priceField.id}`}>Не входит</label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                {autotemplateMock[priceField.nestedElement].map((contractEntity) => {
                                    return (
                                        <div key={contractEntity.id} className={`autotemplate__input-group`}>

                                            <div className={`autotemplate__label`}>
                                                {contractEntity.title}
                                            </div>
                                            <div className={`autotemplate__input-container`}>
                                                <div className={`autotemplate__input-icon`}>
                                                    <img
                                                        src={`../../img/autotemplates/icon__${contractEntity.type}.svg`}
                                                        alt={``}/>
                                                </div>
                                                <input
                                                    className={`autotemplate__input-field`}
                                                    placeholder={contractEntity.title}
                                                    type={(contractEntity.type === 'date') ? 'datetime-local' : 'text'}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <button
                        className={`documents__button-control`}
                        onClick={handleDocumentControl}
                    >
                        Сохранить
                    </button>

                </div>
            </div>
        </>
    )
};

export default Autotemplate;