import React from "react";

const LoginLinks = () => {
    return (
        <div className={`form__links`}>
            <div className={`form-links__auth`}>
                Нет аккаунта? <a className={`form-links__link`} href={`/register`}>Зарегистрироваться</a>
            </div>
            <div className={`form-links__promo`}>
                <a className={`form-links__link`} href={`/password_reset`}>Забыли пароль?</a>
            </div>
        </div>
    );
}

export default LoginLinks;