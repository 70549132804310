import React from "react";

const TariffCard = () => {

    return (
        <div className={`settings-profile__tariff`}>
            <div className={`tariff__column`}>
                <div className={`tariff__background tariff-type__demo`}>
                    <div className={`tariff-background__logo`}>
                        <img src={`/img/delta__logo-dark.svg`} alt={``}/>
                    </div>
                    <div className={`tariff-background__title`}>Стартовый</div>
                    <div className={`tariff-background__price`}>БЕСПЛАТНО</div>
                </div>
                <div className={`tariff__content`}>
                    <div className={`tariff-content__main`}>
                        <div className={`tariff-content__description`}>
                            <div className={`tariff-description__title`}>
                                Для знакомства с сервисом и его возможностями
                            </div>
                            <div className={`tariff-description__features`}>
                                <div className={`tariff-features__feature`}>
                                    <img
                                        src={`/img/tariffs/features/tariff-features__cloud.svg`}
                                        alt={``}
                                    />
                                    Облачное хранилище на 10 Мб
                                </div>
                            </div>
                        </div>

                        <div className={`tariff-content__features`}>
                            <div className={`tariff-features__feature`}>
                                <img
                                    src={`/img/tariffs/features/tariff-features__templates.svg`}
                                    alt={``}
                                />
                                Безлимитные шаблоны
                            </div>
                            <div className={`tariff-features__feature`}>
                                <img
                                    src={`/img/tariffs/features/tariff-features__documents.svg`}
                                    alt={``}
                                />
                                Скачивание и печать до 10 готовых документов
                            </div>
                            <div className={`tariff-features__feature`}>
                                <img src={`/img/tariffs/features/tariff-features__box.svg`} alt={``}/>
                                100 документов за 1 минуту
                            </div>
                        </div>
                    </div>
                    <div className={`tariff-content__button`}>
                        <a className={`button__tariffs`} href={"/tariffs"}>
                            Смотреть все тарифы
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TariffCard;