import React, {useEffect, useRef, useState} from "react";
import moment from "moment";

const CustomCalendarInput = ({ defaultDateProp, onDateChange }) => {
    const [defaultDateState, setDefaultDateState] = useState(null);

    useEffect(() => {
        if (defaultDateProp) {
            const dateString = defaultDateProp.split('.'); // ожидается, что на входе строка вида "24.12.2023"
            const dateInstance = new Date(dateString.reverse());
            dateInstance.setHours(12); // игнорируем часовой пояс
            setDefaultDateState(dateInstance.toISOString().substring(0, 10))
        } else {
            const dateInstance = new Date();
            dateInstance.setHours(12);
            // setDefaultDateState(dateInstance.toISOString().substring(0, 10));
            setDefaultDateState(null);
        }
    }, []);

    return (
        <input
            className={`event-sides__input input__datetime`}
            type={`date`}
            defaultValue={defaultDateState}
            onChange={onDateChange}
        />
    );
}

export default CustomCalendarInput;