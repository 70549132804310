import React from "react";

const ResetLinks = () => {
    return (
        <div className={`form__links`}>
            <div className={`form-links__auth`}>
                Знаете пароль? <a className={`form-links__link`} href={`/login`}>Войти</a>
            </div>
        </div>
    );
}

export default ResetLinks;