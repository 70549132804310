import React, { useEffect, useRef, useState } from "react";

import RecipientsList from "./RecipientsList";
import DocumentsList from "./DocumentsList";
import ClaimSendPopupSuccess from "./ClaimSendPopupSuccess";
import ClaimSendPopupCancel from "./ClaimSendPopupCancel";

import Sidemenu from "../../Components/sidemenu/Sidemenu";
import Loader from "../../Components/Loader";

import { APP_URL } from "../../API/requests";
import { useBearerToken } from "../../API/requests";

import { camelize, splitByBracket, withoutBrackets } from "../../Helpers/Constants/rupost";

import "./styles/claimSend.scss";

/**
 * Форма отправки претензии
 * @returns {Element}
 * @constructor
 */
const ClaimSend = () => {
    const axiosInstance = useBearerToken();

    const [typeSelected, setTypeSelected] = useState(null);
    const [recipientsList, setRecipientsList] = useState({});
    const [showPopups, setShowPopups] = useState({ success: false, cancel: false });
    const [recipientsAmount, setRecipientsAmount] = useState(1);
    const [isFetchPostMesssage, setIsFetchPostMessage] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [filesToSend, setFilesToSend] = useState(null);
    const [rupostErrors, setRupostErrors] = useState([]);
    const [docxFiles, setDocxFiles] = useState([]);
    const [filesListState, setFilesListState] = useState([]);

    const [files, setFiles] = useState(null);
    const [isValidate, setIsValidate] = useState(false);

    const fileInputRef = useRef(null);

    const [rupostFields, setRupostFields] = useState({});

    useEffect(() => {
        console.log(rupostFields);
    }, [rupostFields]);

    useEffect(() => {
        const form = document.querySelector(".claim-send__delivery-recipient");

        if (typeSelected === "rupost") {
            const inputsForm = form.querySelectorAll("input");
            const newRecipientsList = {};

            Array.from(inputsForm).forEach((input) => {
                const name = input.getAttribute("name");
                newRecipientsList[name] = input.value;
            });
            setRupostFields(newRecipientsList);
        }
    }, [typeSelected]);

    useEffect(() => {
        if (Array.isArray(files)) {
            docxFiles.push(...files);
            setDocxFiles(docxFiles);

            setDocuments(null);
            setFilesToSend(null);

            for (const file of files) {
                const newDoc = {
                    id: file.lastModified,
                    title: file.name,
                    pagesAmount: 1,
                    isAutoCalculatable: false,
                    isDeletable: true
                };

                documents.push(newDoc);
            }

            setDocuments(documents);
            setFilesToSend(files);
            setFiles(null);
        }
    }, [files]);

    const togglePopup = (popupName) => {
        setShowPopups((prevState) => ({
            ...showPopups,
            [popupName]: !prevState[popupName]
        }));
    };

    const handleFilesChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const fetchDiadocPostMessage = (boxId) => {
        const form = new FormData();
        const messageFormData = {
            FromBoxId: "5c470b9f1aec44f983d4ceaa07c522cd@diadoc.ru",
            ToBoxId: boxId,
            IsDraft: false,
            LockDraft: false
        };

        for (const [key, value] of Object.entries(messageFormData)) form.append(key, value);
        docxFiles.forEach((file) => form.append("files", file));

        return axiosInstance
            .post(`${APP_URL}posts/post_message`, form, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
            .then((response) => {
                if (response.status_code && response.status_code === 200) {
                    console.log(response.status_code);
                    togglePopup("success");
                }
            })
            .catch((error) => {
                return error;
            });
    };

    /**
     * Ручка для получения организаций
     * @returns {Promise<*>}
     */
    const getBoxId = async () => {
        return axiosInstance
            .get(`${APP_URL}posts/get_my_organizations`)
            .then((response) => response.data.json.Organizations[0].Boxes[0].BoxId);
    };

    /**
     * Ручка для получения boxId по инн-номеру получателя.
     * Берет первую организацию и первый ящик из представленных
     * @param innNumber
     * @returns {Promise<*>}
     */
    const getBoxByInn = async (innNumber) => {
        return axiosInstance
            .get(`${APP_URL}posts/${innNumber}/organization`)
            .then((response) => {
                try {
                    if (response.data) {
                        const { json } = response.data;
                        if (json) {
                            // console.log(json.Boxes[0].BoxId);
                            return json?.Boxes[0]?.BoxId ?? null;
                        } else return response;
                    } else if (response.response) {
                        const errorInstance = response.response;
                        console.log(errorInstance);
                        setRupostErrors(errorInstance.data.detail);
                        setShowPopups({ success: true, button: { text: "Закрыть", callback: (e) => togglePopup(e) } });

                    }
                } catch (e) {
                    console.log(e);
                }
            })
            .catch((e) => console.log(e));
    };

    const handleClickToUploadFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const deleteDocument = (documentId) => {
        setDocuments(documents.filter((document) => document.id !== documentId));
        setFiles(null);
    };

    const sendDocument = (e) => {
        e.preventDefault();

        if (!fileInputRef.current.files.length) {
            setRupostErrors("Не загружен файл");
            setShowPopups({ success: true, button: { text: "Закрыть", callback: (e) => togglePopup(e) } });

            return;
        }

        try {
            switch (typeSelected) {
                case "diadoc":
                    handleSendToDiadoc(e).then((response) => console.log(response));
                    break;
                case "rupost":
                    handleSendToRupost(e);
                    break;
                case "email":
                    handleSendToEmail(e);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsFetchPostMessage(false);
        }
    };

    const addRecipient = (e) => {
        e.preventDefault();
        setRecipientsAmount(recipientsAmount + 1);
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        const id = e.target.getAttribute("data-item-key");
        if (id) {
            const inputType = e.target.getAttribute("data-item-type") ?? "requisite";
            const listToUpdate = recipientsList; // [id] = value;
            listToUpdate[id] = { ...listToUpdate[id], ...{ [inputType]: value } };
            setRecipientsList(listToUpdate);
        }
    };

    const handleTypeChange = (e) => {
        const { target } = e;
        setTypeSelected(target.value && target.value !== "" ? target.value : null);
        setRecipientsAmount(1);
        setRecipientsList({});
    };

    const handleSendToDiadoc = async (e) => {
        e.preventDefault();
        setIsFetchPostMessage(true);
        const statusesList = [];

        try {
            for (const [key, value] of Object.entries(recipientsList)) {
                await getBoxByInn(value?.requisite ?? "1").then(async (res) => {
                    console.log(res);

                    const postMessage = await fetchDiadocPostMessage(res);
                    statusesList.push(postMessage.status_code);

                    if (statusesList.every((value) => value === 200)) {
                        togglePopup("success");
                    }
                });
            }

            console.log("%cError:" + postMessage?.content, process.env.REACT_APP_ERROR_STYLES);
        } catch (e) {
            console.log(e);
        }
    };

    function checkRupostStatus(result) {
        return new Promise((resolve, reject) => {
            if (result) {
                const { requestCode } = result.data;

                if (requestCode) {
                    setTimeout(() => {
                        axiosInstance
                            .get(`${APP_URL}posts/letters/${requestCode}/status`)
                            .then((response) => {
                                if (response.status === 200) resolve();
                                else reject();
                            })
                            .catch((error) => {
                                console.log(error);
                                setRupostErrors(error.message);
                                reject();
                            });
                    }, 100);
                }
            }
        });
    }

    function getFileExtension(filename) {
        return filename.split(".").pop();
    }

    function binaryStringToUint8Array(binaryString) {
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    const checkPDF = async (files) => {
        const url = "http://185.84.162.3:766/api/v1/";
        let endpointUrl = null;

        const formData = new FormData();
        const file = files[0];
        const fileExtension = getFileExtension(file.name);
        let convertFile = null;
        let returnFile = null;

        formData.append("file", file);

        if (fileExtension.includes("doc")) endpointUrl = `libreConvertWordToPdf`
        else if (fileExtension === "pdf") endpointUrl = `convertPdfVersion`;

        if (endpointUrl) {
            convertFile = await axiosInstance.post(`${url}${endpointUrl} `, formData)
                .then((result) => result.data)
                .catch((err) => console.log(err));
        }

        if (convertFile && convertFile?.fileContent) {
            if (fileExtension.includes("doc") || fileExtension === "pdf") {
                returnFile = dataURLtoFile(`data:application/pdf;base64,${convertFile.fileContent}`,'document.pdf');
                console.log(returnFile);
            }
        }

        const formDataToCheckPDF = new FormData();
        formDataToCheckPDF.append("file", returnFile);

        return await axiosInstance
            .post(`${url}checkPdf `, formDataToCheckPDF)
            .then((result) => {
                if (result.data.data.type !== "error") {
                    const filesListUpdated = filesListState;
                    filesListUpdated.push(returnFile);
                    setFilesListState(filesListUpdated);
                }

                const requirements = result.data.data.requirements.filter((req) => !req.isMet);

                const reportMessage = requirements.map((req) => req.message).join("; ");
                setRupostErrors(reportMessage);
                setShowPopups({ success: true });

                return false;
            })
            .catch((err) => {
                const requirements = err.response.data.data.requirements.filter((req) => !req.isMet);

                const reportMessage = requirements.map((req) => req.message).join("; ");
                setRupostErrors(reportMessage);
                setShowPopups({ success: true });

                return false;
            });

    }

    function prInputsValidation(form) {
        return Array.from(form.querySelectorAll(".claim-send__delivery-recipient>input")).filter(
            (i) => i.getAttribute("name") !== "orderFormNumber" && !i.value.trim()
        );
    }

    const handleSendToRupost = async (e) => {
        e.preventDefault();
        const filesRefList = fileInputRef.current.files;

        const form = e.target;
        const notValidInputs = prInputsValidation(form);

        if (notValidInputs.length) {
            console.log(recipientsList);
            setRupostErrors("Заполните все обязательные поля!");
            setShowPopups({ success: true, button: { text: "Закрыть", callback: (e) => togglePopup(e) } });
            setIsValidate(true);

            return;
        }
        const formData = new FormData(form);

        [...fileInputRef.current.files].forEach((file) => formData.append("files", file));

        if (!formData.get("recipient_countryCodeOKSM")) {
            formData.append("recipient_countryCodeOKSM", 643); // код россии по умолчанию (считаем, что вся корреспонденция шлется внутри рф)
        }

        // console.log(filesRefList);

        [...filesRefList].map(async (file) => {
            await checkPDF([file]).then(async () => {
                formData.delete('files');

                if (!formData.get("recipient_countryCodeOKSM")) {
                    formData.append("recipient_countryCodeOKSM", 643); // код россии по умолчанию (считаем, что вся корреспонденция шлется внутри рф)
                }

                const senderAddress = formData.get("sender_address");
                const recipientAddressList = [];

                for (const [key, value] of formData.entries()) {
                    if (key.includes("recipient_address[")) {
                        recipientAddressList.push({ [key]: value });
                    }
                }

                console.log(recipientAddressList);

                const recipientAddress = formData.get("recipient_address");
                const addressesListSortedByKeys = {};
                const addressesListParsed = { sender: [], recipient: [] };

                /**
                 * Преобразование строчного формата адреса в объект с помощью обращения к dadata
                 * @param address адрес в виде строки
                 * @param key тип адреса (отправителя или получателя)
                 * @param index индекс получателя или отправителя
                 * @returns {Promise<*>} промис, на резолве возвращающий объект dadata с адресом
                 */
                const parseAddressFields = async (address, key, index = null) => {
                    return axiosInstance.post(`${APP_URL}posts/dadata/suggest`, { query: address }).then((response) => {
                        if (response.data && response.data.length > 0) {
                            const cleanAddress = Object.fromEntries(
                                Object.entries(response.data[0]?.data).filter(
                                    ([_, responseDataField]) => responseDataField !== null
                                )
                            );

                            addressesListParsed[key].push(camelize(cleanAddress));
                            for (let [key, value] of Object.entries(addressesListParsed)) {
                                // console.log(value);
                                Object.assign(
                                    addressesListSortedByKeys,
                                    Object.fromEntries(
                                        Object.entries(value).map(([innerKey, innerValue]) => [
                                            `${key}_${innerKey}`,
                                            innerValue
                                        ])
                                    )
                                );
                            }
                        }
                    });
                };

                recipientAddressList.forEach((recipientAddressEntity, index) => {
                    for (const [key, value] of Object.entries(recipientAddressEntity)) {
                        parseAddressFields(value, "recipient", index).then(() => console.log(addressesListSortedByKeys));
                    }
                });

                await parseAddressFields(recipientAddress, "recipient").then(() => {
                    parseAddressFields(senderAddress, "sender").then(async () => {
                        for (const [key, value] of Object.entries(addressesListSortedByKeys)) {
                            for (const [innerKey, innerValue] of Object.entries(value)) {
                                formData.set(
                                    `${key.substring(0, key.indexOf("_"))}_${innerKey}[${key.substring(
                                        key.indexOf("_") + 1,
                                        key.length
                                    )}]`,
                                    innerValue
                                );
                            }
                        }

                        const recipientsList = [];
                        const recipientsListSortedByKeys = [];

                        for (const [key, value] of formData.entries()) {

                            if (key.includes("recipient_")) {
                                recipientsList.push({
                                    [key.replace("recipient_", "")]: value
                                });

                            }
                        }

                        recipientsList.forEach((recipientField) => {
                            for (const [key, value] of Object.entries(recipientField)) {
                                const recipientFieldIndex = splitByBracket(key);

                                if (!isNaN(recipientFieldIndex)) {
                                    if (typeof recipientsListSortedByKeys[recipientFieldIndex] === "undefined") {
                                        recipientsListSortedByKeys[recipientFieldIndex] = { [withoutBrackets(key)]: value };
                                    } else {
                                        Object.assign(recipientsListSortedByKeys[recipientFieldIndex], {
                                            [withoutBrackets(key)]: value
                                        });
                                    }
                                }
                            }
                        });

                        formData.append("recipients", JSON.stringify(recipientsListSortedByKeys));

                        try {
                            console.log(filesListState);
                            filesListState.forEach((file) => {
                                const formDataWithFile = formData;
                                formDataWithFile.append("files", file);

                                return axiosInstance
                                    .post(`${APP_URL}posts/letters`, formData, {
                                        validateStatus: (status) => status >= 200 && status < 300
                                    })
                                    .then(async (response) => {
                                        if (response.status === 200) {
                                            if (response.data) {
                                                const resultsList =
                                                    response?.data &&
                                                    response.data.map((responseEntity) => {
                                                        if (responseEntity.data && responseEntity.data?.code === 200) {
                                                            return responseEntity.data;
                                                        } else return false;
                                                    });

                                                await Promise.all(resultsList.map((result) => checkRupostStatus(result))).then(
                                                    () => {
                                                        setShowPopups({ success: true });
                                                    }
                                                );
                                            }
                                        } else {
                                            setRupostErrors(response.response.data.detail);
                                            setShowPopups({ success: true });
                                        }
                                    });
                            });

                            setFilesListState([]);
                            console.log(filesListState);


                        } catch (error) {
                            console.log(111, error.message);
                        }
                    });
                });
            });
        })

    };

    const handleSendToEmail = (e) => {
        const formData = new FormData();

        [...fileInputRef.current.files].forEach((file) => {
            formData.append("files", file);
        });

        let recipientsString = "";
        Object.values(recipientsList).forEach((recipient, index) => {
            // console.log(recipient);
            recipientsString += recipient.requisite;
            if (index + 1 !== Object.keys(recipientsList).length) {
                recipientsString += ", ";
            }
        });

        formData.append("subject", "Входящая претензия (Дельта.Про)");
        formData.append("body", "Текст входящей претензии");
        formData.append("to", recipientsString);

        axiosInstance.post(`${APP_URL}posts/email`, formData);
    };

    return (
        <>
            <Loader isOnLoad={isFetchPostMesssage} />
            <div className={`claim-send__container`}>
                <Sidemenu />

                <div className={`claim-send__back-wrapper`}>
                    <div className={`claim-send__back`} onClick={() => togglePopup("cancel")}>
                        <img src={`/img/arrow__back.svg`} alt={``} />
                    </div>
                </div>

                <div className={`claim-send__content`}>
                    <h1>Документы для отправки</h1>

                    <form action={`#`} className={`claim-send__content--form`} onSubmit={sendDocument}>
                        <DocumentsList
                            documents={documents}
                            deleteDocument={deleteDocument}
                            handleClickToUploadFiles={handleClickToUploadFiles}
                            handleFilesChange={handleFilesChange}
                            fileInputRef={fileInputRef}
                        />

                        <div className={`claim-send__delivery`}>
                            <div className={`claim-send__delivery-select`}>
                                <select defaultValue={``} onChange={handleTypeChange}>
                                    <option value={``} disabled>
                                        Выбрать способ отправки
                                    </option>
                                    <option value={"rupost"}>По почте России (электронно)</option>
                                    <option value={"diadoc"}>Отправить по ЭДО</option>
                                    <option value={"email"}>Отправить по эл.почте</option>
                                </select>
                            </div>
                            <div className={`claim-send__delivery-recipients`}>
                                <RecipientsList
                                    amount={recipientsAmount}
                                    typeSelected={typeSelected}
                                    recipientsList={recipientsList}
                                    addRecipient={addRecipient}
                                    recipientsAmount={recipientsAmount}
                                    rupostFields={rupostFields}
                                    setRupostFields={setRupostFields}
                                    handleInputChange={handleInputChange}
                                    isValidate={isValidate}
                                />
                            </div>
                        </div>
                        <input type={"submit"} className={`claim-send__send-document`} value={`Отправить`} />
                    </form>

                    {showPopups.success && (
                        <ClaimSendPopupSuccess
                            errorInfo={rupostErrors}
                            togglePopupCallback={(e) => togglePopup(e)}
                            button={showPopups.button}
                        />
                    )}
                    {showPopups.cancel && <ClaimSendPopupCancel togglePopupCallback={(e) => togglePopup(e)} />}
                </div>
            </div>
        </>
    );
};

export default ClaimSend;
