import React, { useEffect, useState } from "react";
import { APP_URL, useBearerToken } from "../../API/requests";
import { useNavigate, useParams } from "react-router-dom";
import Sidemenu from "../../Components/sidemenu/Sidemenu";
import MonitoringClaimEditEvent from "../../Components/monitoring/MonitoringClaimEditEvent";

import "./styles/monitoringClaimEdit.scss";
import "./styles/monitoringClaimEdit.css";

const MonitoringClaimEdit = () => {
    const axiosInstance = useBearerToken();
    const navigate = useNavigate();

    const [contract, setContract] = useState("");

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            axiosInstance.get(`${APP_URL}/contracts/${id}?expand_events=true`).then((response) => {
                const { data } = response;

                if (data) {
                    console.log(data);
                    setContract(data);
                }
            });
        }
    }, []);

    const handleContractUpdate = async (contractToUpdate = null) => {
        return new Promise((resolve, reject) => {
            axiosInstance.patch(
                `${APP_URL}contracts/${contract.id}`,
                contractToUpdate ?? contract
            ).then((response) => {
                resolve(response);
            }).catch((e) => reject(e));
        })
    }

    const resumeControl = () => {
        handleContractUpdate().then(() => navigate('/monitoring'));
    };

    const createClaim = (e) => {
        e.preventDefault();
        return navigate('/claims/create');

        // TODO Добавить логику создания претензии
    };

    const onEventSave = (eventToSave) => {
        const contractToUpdate = contract;
        const eventIndex = contractToUpdate.events.findIndex((event) => event.id === eventToSave.id);
        contractToUpdate.events[eventIndex] = eventToSave;

        handleContractUpdate(contractToUpdate).then((response) => {
            console.log(response);
            setContract(contractToUpdate);
        });
    }

    return (
        <>
            <div className={`claim-edit__container`}>
                <Sidemenu />

                <div className={`claim-edit__back-wrapper`}>
                    <div className={`button__black claim-edit__back`} onClick={resumeControl}>
                        <img src={`/img/arrow__back.svg`} alt={``} />
                    </div>
                </div>

                <div className={`claim-edit__content`}>
                    <h1>Контролируемые обязательства и/или их исполнение</h1>
                    <form action={`#`} className={`claim-edit__content--form`}>
                        <div className={`claim-edit__content-title`}>
                            <h2>Договор поставки 45 от 03072023 5л.</h2>
                            <p>Цена договора: 1 200 000 руб.</p>
                        </div>

                        <div className={`claim-edit__content-rows`}>
                            {contract &&
                                contract.events.map((event, index) => {
                                    console.log(event);
                                    return <MonitoringClaimEditEvent key={`claim-edit_${index}`} event={event} onEventSave={onEventSave} />;
                                })}
                        </div>

                        <div className={`claim-edit__content-actions`}>
                            <button className={`content-actions__button--red`} onClick={createClaim}>
                                Создать претензию
                            </button>
                            <button className={`content-actions__button--transparent`} onClick={resumeControl}>
                                Продолжить контроль
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default MonitoringClaimEdit;
