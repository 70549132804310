import React, {useEffect, useRef, useState} from "react";
import {resolveWordCasesForDocuments} from "../../Helpers/cases";
import CustomCalendarInput from "./CustomCalendarInput";

const PeriodInput = ({type, period, periodType = 1, date, onInputChange}) => {
    const selectRef = useRef(null);
    const [periodTypeState, setPeriodTypeState] = useState(periodType);
    const [dateState, setDateState] = useState(date);
    const [periodState, setPeriodState] = useState(period);

    const eventTypeIsPeriod = (eventType) => {
        return (eventType === 'period_before' || eventType === 'period_after');
    };

    useEffect(() => {
        const currentRef = selectRef.current;
        if (currentRef) {
            const refChildren = selectRef.current.children;
            for (const refChild of Array.from(refChildren)) {
                if (refChild.value === periodTypeState) {
                    refChild.setAttribute('selected', true);
                }
            }
        }
    }, [period]);

    useEffect(() => {
        onInputChange({period: periodState, periodType: periodTypeState, date: dateState});
    }, [periodState, periodTypeState, dateState])

    const handleDateChange = (e) => {
        const {target} = e;
        setDateState(target.value);
    }

    const handlePeriodChange = (e) => {
        const {target} = e;
        setPeriodState(target.value);
    }

    return (eventTypeIsPeriod(type)) ? (
        <>
            <input
                className={`event-sides__input sides-input__small input__grey`}
                placeholder={'10'}
                defaultValue={periodState}
                onChange={handlePeriodChange}
            />

            <select
                ref={selectRef}
                className={`event-sides__select sides-select__small`}
                defaultValue={periodTypeState}
            >
                <option value={1}>Рабочих</option>
                <option value={2}>Календарных</option>
            </select>

            <input
                className={`event-sides__input sides-input__small input__grey`}
                placeholder={resolveWordCasesForDocuments(periodState)?.days}
                disabled={true}
            />
        </>
    ) : (
        <>
            <CustomCalendarInput
                defaultDateProp={dateState}
                onDateChange={handleDateChange}
            />
        </>
    );
}

export default PeriodInput;