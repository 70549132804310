import React
  from "react";

const MonitoringWorkItem = ({
                              workItem
                            }) => {

  return (

    // .monitoring-block временный, чтобы для верстки добавить 2 элемента, потом можно удалить
    <div className={`monitoring-block`}>
      <div className={`monitoring-item`}>
        <h3>Договорная работа</h3>
        <div className={`monitoring-item__process`}>
          <div className={`monitoring-item__stage`}>
            <h4>На контроле</h4>
            <div className={`monitoring-item__stage--info`}>
              <p>25 шт.</p>
              <p className={`monitoring-item__stage--separator`}></p>
              <div className={`monitoring-item__stage--sum`}>
                <p>Σ</p>
                <p>25 553 тыс. руб.</p>
              </div>
            </div>
          </div>
          <div className={`monitoring-item__stage`}>
            <h4>Не исполнено контрагентом</h4>
            <div className={`monitoring-item__stage--info`}>
              <p>3 шт.</p>
              <p className={`monitoring-item__stage--separator`}></p>
              <div className={`monitoring-item__stage--sum`}>
                <p>Σ</p>
                <p>5 423 тыс. руб.</p>
              </div>
            </div>
            <img src="/img/bell.svg" alt=""/>
          </div>
          <div className={`monitoring-item__stage`}>
            <h4>Исполнено</h4>
            <div className={`monitoring-item__stage--info`}>
              <p>12 шт.</p>
              <p className={`monitoring-item__stage--separator`}></p>
              <div className={`monitoring-item__stage--sum`}>
                <p>Σ</p>
                <p>25 553 тыс. руб.</p>
              </div>
            </div>
          </div>
        </div>
        <img src="/img/chart__small.svg" alt=""/>
      </div>

      {/* После добавления реактивности оставить только 1 monitoring-item */}
      <div className={`monitoring-item`}>
        <h3>Претензионная работа</h3>
        <div className={`monitoring-item__process`}>
          <div className={`monitoring-item__stage`}>
            <h4>Отправлено </h4>
            <div className={`monitoring-item__stage--info`}>
              <p>3 шт.</p>
              <p className={`monitoring-item__stage--separator`}></p>
              <div className={`monitoring-item__stage--sum`}>
                <p>Σ</p>
                <p>7 200 тыс. руб.</p>
              </div>
            </div>
          </div>
          <div className={`monitoring-item__stage`}>
            <h4>Не исполнено контрагентом</h4>
            <div className={`monitoring-item__stage--info`}>
              <p>2 шт.</p>
              <p className={`monitoring-item__stage--separator`}></p>
              <div className={`monitoring-item__stage--sum`}>
                <p>Σ</p>
                <p>2 500 тыс. руб.</p>
              </div>
            </div>
            <img src="/img/bell.svg" alt=""/>
          </div>
          <div className={`monitoring-item__stage`}>
            <h4>Входящие претензии</h4>
            <div className={`monitoring-item__stage--info`}>
              <p>1 шт.</p>
              <p className={`monitoring-item__stage--separator`}></p>
              <div className={`monitoring-item__stage--sum`}>
                <p>Σ</p>
                <p>500 тыс. руб.</p>
              </div>
            </div>
          </div>
        </div>
        <img src="/img/chart__small.svg" alt=""/>
      </div>
    </div>

  );
}

export default MonitoringWorkItem;