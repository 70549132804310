import React, {useRef} from "react";
import ReactionInput from "./ReactionInput";
import EventDeleteIcon from "../forms/EventDeleteIcon";

const DocumentObligation = ({
                                isCustom,
                                obligation,
                                onEventTypeChange,
                                onEventDelete,
                                onCustomEventChange,
                                index,
                                length
                            }) => {

    const customTitleRef = useRef(null);

    const obligationTypesList = ['Оплата', 'Поставка товаров (работ, услуг)', 'Уведомление', 'Возврат'];

    const handleCustomTitleChange = () => onCustomEventChange(index, customTitleRef.current.value);

    return (
        <>
            <div className={`documents__event-title`}>
                ОБЯЗАТЕЛЬСТВО {(isCustom && index + 1 === length) ? (
                <button
                    onClick={() => onEventDelete(index)}
                    className={`documents__delete-custom-obligation`}
                >
                    <EventDeleteIcon/> Удалить
                </button>
            ) : ''}
            </div>

            <div className={`documents__event-container`}>
                <div className={`documents__event-sides sides__event-container`}>
                    <React.Fragment key={`obligation__${index}`}>
                        <div className={`event-sides__row`} data-row-id={1}>
                            {
                                (isCustom && index + 1 === length) ? (
                                    <input
                                        ref={customTitleRef}
                                        className={`event-sides__select select__width-full`}
                                        defaultValue={obligation.event.title}
                                        onChange={handleCustomTitleChange}
                                    />
                                ) : (
                                    <select
                                        ref={customTitleRef}
                                        onChange={handleCustomTitleChange}
                                        className={`event-sides__select select__width-full`}
                                        defaultValue={obligation.event.title}
                                    >
                                        <option value={obligation.event.title}>{obligation.event.title}</option>
                                        {obligationTypesList.map((obligationType) => {
                                            if (obligationType !== obligation.event.title) {
                                                return (<option value={obligationType}>{obligationType}</option>)
                                            }
                                        })}
                                    </select>
                                )
                            }

                        </div>

                        <ReactionInput
                            contractEvent={obligation}
                            onEventTypeChange={onEventTypeChange}
                            index={index}
                        />
                    </React.Fragment>
                </div>
            </div>
        </>
    )
}

export default DocumentObligation;