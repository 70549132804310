import React, {useState} from "react";
import clsx from "clsx";

import Sidemenu from "../Components/sidemenu/Sidemenu";
import {useTheme} from "../Helpers/Providers/ThemeProvider";
import TariffSubscriptions from "../Components/settings/TariffSubscriptions";

const Tariffs = () => {
    document.title = `Тарифы | Дельта Про`;

    const [tariffOpen, setTariffOpen] = useState(false);
    const [selectedTariff, setSelectedTariff] = useState(null);

    const isDarkTheme = useTheme('dark', () => true);

    const handleTariffOpen = () => setTariffOpen(!tariffOpen);
    const resolveIconColor = () => {
        if (isDarkTheme) {
            return false;
        } else if (tariffOpen) {
            return true;
        }
    }

    const onTariffChange = (tariff) => {
        setSelectedTariff(tariff);
    }

    const subscribe = () => {
        if (selectedTariff) {
            console.log(`обработка клика на подписку по тарифу ${selectedTariff.title}`);
        }
    }

    /**
     *
     * @type {[{name: string, id: number, prices: [{period: string, price: string, discount: string, id: number, title: string},{period: string, price: string, discount: string, id: number, title: string},{period: string, price: string, discount: string, id: number, title: string},{period: string, price: string, discount: null, id: number, title: string}]},{name: string, id: number, prices: [{period: string, price: string, discount: string, id: number, title: string},{period: string, price: string, discount: string, id: number, title: string},{period: string, price: string, discount: string, id: number, title: string},{period: string, price: string, discount: null, id: number, title: string}]}]}
     */
    const tariffsListMock = [
        {
            id: 1, name: 'Личный', prices: [
                {id: 1, title: 'Год', discount: 'Скидка 30 000 ₽', price: '60 001 ₽', period: `год`},
                {id: 2, title: '6 мес', discount: 'Скидка 10 000 ₽', price: '35 001 ₽', period: `6 мес`},
                {id: 3, title: '3 мес', discount: 'Скидка 11%', price: '20 001 ₽', period: `ква1ртал`},
                {id: 4, title: 'Месяц', discount: null, price: '7 501 ₽', period: `мес`},
            ],
        },
        {
            id: 2, name: 'Бизнес', prices: [
                {id: 1, title: 'Год', discount: 'Скидка 30 000 ₽', price: '60 000 ₽', period: `год`},
                {id: 2, title: '6 мес', discount: 'Скидка 10 000 ₽', price: '35 000 ₽', period: `6 мес`},
                {id: 3, title: '3 мес', discount: 'Скидка 11%', price: '20 000 ₽', period: `квартал`},
                {id: 4, title: 'Месяц', discount: null, price: '7 500 ₽', period: `мес`},
            ],
        }
    ]

    const tariffs = [
        {id: 1, title: 'Год', discount: 'Скидка 30 000 ₽', price: '60 000 ₽', period: `год`},
        {id: 2, title: '6 мес', discount: 'Скидка 10 000 ₽', price: '35 000 ₽', period: `6 мес`},
        {id: 3, title: '3 мес', discount: 'Скидка 11%', price: '20 000 ₽', period: `квартал`},
        {id: 4, title: 'Месяц', discount: null, price: '7 500 ₽', period: `мес`},
    ];

    return (
        <>
            <div className={`settings__container`}>
                <Sidemenu/>
                <div
                    className={clsx({['settings__overflow-scroll']: tariffOpen}, `settings__content-wrapper`, `settings-content__tariffs`)}>
                    <h2 className={`settings__title`}>
                        Тарифы
                    </h2>
                    <a className={`button__back`} href={`/settings`}>
                        <img src={`/img/arrow__back.svg`} alt={``}/>
                    </a>

                    <div className={clsx(`settings__text-mobile`, {['hidden']: tariffOpen})}>
                        Управляйте своим тарифом
                    </div>

                    <a
                        className={clsx(`arrow__mobile-back`, {['hidden']: !tariffOpen})}
                        href={`/tariffs`}
                    >
                        <img src={`/img/arrow__mobile-back${(isDarkTheme) ? `-dark` : `-light`}.svg`} alt={``}/>
                        Тарифы
                    </a>

                    <div className={`settings__profile-container settings__tariffs-list-container`}>
                        <div
                            className={clsx(`settings__tariffs-list`, {['settings-profile__tariff-fullscreen']: tariffOpen})}>

                            <div className={`settings-profile__tariff`}>
                                <div className={`tariff__column`}>
                                    <div className={`tariff__background tariff-type__personal`}>
                                        <div className={`tariff-background__logo`}>
                                            <img src={`/img/delta__logo-dark.svg`}/>
                                        </div>
                                        <div className={`tariff-background__title`}>
                                            Личный
                                        </div>
                                        <div className={`tariff-background__price`}>
                                            3 000 ₽ /мес
                                        </div>
                                    </div>
                                    <div className={`tariff__content`}>
                                        <div className={`tariff-content__main`}>
                                            <div className={`tariff-content__description`}>
                                                <div className={`tariff-description__title`}>
                                                    Оптимально для индивидуального использования
                                                </div>
                                                <div className={`tariff-description__features`}>
                                                    <div className={`tariff-features__feature`}>
                                                        <img
                                                            src={`/img/tariffs/features/tariff-features__cloud.svg`}
                                                            alt={``}
                                                        />
                                                        Облачное хранилище 1 Гб
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`tariff-content__features`}>
                                                <div className={`tariff-features__feature`}>
                                                    <img
                                                        src={`/img/tariffs/features/tariff-features__templates.svg`}
                                                        alt={``}
                                                    />
                                                    Безлимитные шаблоны
                                                </div>
                                                <div className={`tariff-features__feature`}>
                                                    <img
                                                        src={`/img/tariffs/features/tariff-features__documents.svg`}
                                                        alt={``}
                                                    />
                                                    Безлимитные документы
                                                </div>
                                                <div className={`tariff-features__feature`}>
                                                    <img
                                                        src={`/img/tariffs/features/tariff-features__box.svg`}
                                                        alt={``}
                                                    />
                                                    Поделиться шаблоном
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`tariff-content__button`}>
                                            <button className={`button__tariffs`}>
                                                Подписаться
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={clsx({['tariffs-list__tariff-open']: tariffOpen}, `settings-profile__tariffs-list`)}>
                                <div className={`tariffs-list__left`}>
                                    <div className={`tariffs-list__tariff-container`}>
                                        <div className={`tariffs-list__tariff-wrapper`}>
                                            <div className={`tariffs-list__tariff-content`}>
                                                <img
                                                    src={`/img/delta__logo${(resolveIconColor()) ? `` : `-dark`}.svg`}
                                                    className={`tariffs-list__logo`}
                                                />
                                                <div className={`tariffs-list__title`}>
                                                    Бизнес
                                                </div>
                                                <div className={`tariffs-list__price`}>
                                                    {selectedTariff?.price ?? tariffs[0].price} / {selectedTariff?.title ?? tariffs[0].title}
                                                </div>
                                                <div className={`tariff-content__description`}>
                                                    <div className={`tariff-description__title`}>
                                                        Для знакомства с сервисом и его возможностями
                                                    </div>
                                                    <div className={`tariff-description__features`}>
                                                        <div className={`tariff-features__feature`}>
                                                            <img
                                                                src={`/img/tariffs/features/tariff-features__cloud-${(resolveIconColor()) ? `open` : `bright`}.svg`}
                                                                alt={``}
                                                            />
                                                            Облачное хранилище на 10 Гб
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`tariff-content__features`}>
                                                    <div className={`tariff-features__feature`}>
                                                        <img
                                                            src={`/img/tariffs/features/tariff-features__cloud-increased${(resolveIconColor()) ? `-open` : ``}.svg`}
                                                            alt={``}
                                                        />
                                                        Объем облачного хранилища х10
                                                    </div>
                                                    <div className={`tariff-features__feature`}>
                                                        <img
                                                            src={`/img/tariffs/features/tariff-features__box-${(resolveIconColor()) ? `open` : `bright`}.svg`}
                                                            alt={``}
                                                        />
                                                        100 документов за 1 минуту
                                                    </div>

                                                    <div className={`tariff-features__feature`}>
                                                        <img
                                                            src={`/img/tariffs/features/tariff-features__verified${(resolveIconColor()) ? `-open` : ``}.svg`}
                                                            alt={``}
                                                        />
                                                        Гибкое ценообразование и специальные предложения для бизнеса
                                                    </div>
                                                </div>
                                            </div>


                                            <button className={`button__tariffs tariff__mobile`}
                                                    onClick={handleTariffOpen}>
                                                Подробнее
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tariffs-list__right`}>
                                    <TariffSubscriptions
                                        tariffs={tariffs}
                                        selectedTariff={selectedTariff}
                                        onTariffChange={onTariffChange}
                                    />

                                    <button
                                        className={`modal__button button__save button__tariff`}
                                        onClick={subscribe}
                                        disabled={!selectedTariff}
                                    >
                                        Подписаться
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Tariffs;