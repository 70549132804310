import React, {useEffect, useMemo, useState} from "react";
import {TransitionGroup, CSSTransition} from "react-transition-group";

import {APP_URL, useBearerToken, useSocket} from "../../API/requests";

import NotificationItem from "./NotificationItem";

import "./styles.scss";
import moment from "moment";

const NotificationList = () => {
    const [notifications, setNotifications] = useState([]);
    const [notificationsLayout, setNotificationsLayout] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {
        setNotificationsLayout((
            <>
                {notifications && notifications.map((notification, index) => {
                    if (index > 5) return null;
                    else return (
                        <NotificationItem
                            key={`item-${notification.id}-${(Math.random() + 1).toString(36).substring(3)}`}
                            id={notification.id}
                            title={notification.title}
                            text={notification.text}
                            date={notification.created_at}
                            remove={remove}
                        />
                    )
                })}
            </>
        ));
    }, [notificationsCount]);


    const socket = useSocket({socketName: `notifications`, url: `ws://185.84.162.3:708/ws/notifications/`});

    socket.onopen = () => console.log("точка открытия сокета");
    socket.onmessage = (e) => {
        // console.log(e.data);
        const data = JSON.parse(JSON.parse(e.data).message).data;
        console.log(data, JSON.parse(JSON.parse(e.data).message));
        let newNotification = {id: Date.now()};

        console.log(data);

        const statusesList = {
            accepted: ['ACCEPTED', 'DELIVERED_TO_PRINT'],
            unknown: ['UNKNOWN', 'IN_PROGRESS'],
        }
        const getNotificationTitle = (type) => {
            if (statusesList.accepted.includes(type)) {
                return `Успешно отправлено`;
            } else if (statusesList.unknown.includes(type)) {
                return `Статус письма уточняется`;
            } else return `Ошибка отправки`;
        }

        if (data?.data) {
            const { type, acceptanceTimestamp, description } = data?.data;

            newNotification = {
                id: (Math.random() + 1).toString(36).substring(3),
                title: getNotificationTitle(type),
                text: `${data?.data?.requestCode}: статус отправления ${type} ${(type === 'FAILED') ? `Причина: ${description ?? 'Неизвестна'}` : ''}`,
                created_at: moment(acceptanceTimestamp).format('YYYY-MM-DD HH:mm:ss'),
            };

            const newNotificationsList = notifications;
            newNotificationsList.push(newNotification);

            setNotifications(newNotificationsList);
            setNotificationsCount(notificationsCount + 1);
        }
    };

    useEffect(() => {
        if (notifications.length > 0) {
            // window.onmousemove = () => deleteAllByUsersActive();
            // window.onkeydown = () => deleteAllByUsersActive();
            // window.onscroll = () => deleteAllByUsersActive();
        }

        // window.addEventListener("mousemove", deleteAllByUsersActive);
        // window.addEventListener("keydown", deleteAllByUsersActive);
        // window.addEventListener("scroll", deleteAllByUsersActive);
    }, [notifications]);

    const deleteAll = () => {
        setNotifications([]);
        setNotificationsCount(0);
    }

    const remove = (id) =>  {
        const removeResult = notifications.filter((n) => n.id !== id);
        setNotifications(removeResult);
        setNotificationsCount(removeResult?.length);
        // console.log(notifications, notifications.length, notificationsCount);
    }

    const deleteAllByUsersActive = () => setTimeout(deleteAll, 10000);


    return (
        <div className="notification-list">
            {notifications.length > 1 && (
                <button className="notification-list__delete" onClick={deleteAll}>
                    Закрыть все
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                            d="M9.02817 8.41508L12.3424 11.7293L12.2273 11.8443L8.91313 8.53012L8.50065 8.11765L8.08817 8.53012L4.77398 11.8443L4.65894 11.7293L7.97313 8.41508L8.38561 8.0026L7.97313 7.59012L4.65894 4.27594L4.77398 4.1609L8.08817 7.47508L8.50065 7.88756L8.91313 7.47508L12.2273 4.1609L12.3424 4.27594L9.02817 7.59012L8.61569 8.0026L9.02817 8.41508Z"
                            fill="#F6F6F6"
                            stroke="#F6F6F6"
                            strokeWidth="1.16667"
                        />
                    </svg>
                </button>
            )}
            {notificationsLayout}
        </div>
    );
};

export default NotificationList;
