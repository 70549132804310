import React, {useEffect, useRef, useState} from "react";
import {typesDictionary} from "../../Helpers/contracts";

const ContractTypeSelect = ({contractType, callbackOnChange}) => {
    const selectRef = useRef(null);
    const [type, setType] = useState(contractType);

    useEffect(() => {
        setType(contractType);

        // реакт не умеет ререндерить уже проставленный defaultValue
        const refChildren = selectRef.current.children;
        for (const refChild of Array.from(refChildren)) {
            if (refChild.value === contractType) {
                refChild.setAttribute('selected', true);
            }
        }
    }, [contractType]);

    const handleSelectChange = (e) => {
        callbackOnChange(e.target.value);
        setType(e.target.value);
    };

    return (
        <select
            ref={selectRef}
            className={`event-sides__select select__width-full`}
            defaultValue={type}
            onChange={handleSelectChange}
        >
            {typesDictionary.map((type, index) => {
                return (
                    <option
                        key={`element__${index}`}
                        value={type}>
                        {type}
                    </option>
                );
            })}
        </select>
    );
}

export default ContractTypeSelect;