import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import axios from "axios";
import {createSearchParams, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {useTheme} from "../../Helpers/Providers/ThemeProvider";
import InputValidator from "../../Helpers/Validators/InputValidator";
import {APP_URL, useBearerToken} from "../../API/requests";

const PASSWORD_SHOW = `../../img/form__password-show.svg`;
const PASSWORD_HIDE = `../../img/form__password-hide.svg`;
const PASSWORD_SHOW_DARK = `../../img/form__password-show-dark.svg`;
const PASSWORD_HIDE_DARK = `../../img/form__password-hide-dark.svg`;

const LoginForm = ({ formCaption, formFields, buttonCaptionOnSubmit, links, formEndpoint, token = null }) => {
    document.title = `${formCaption} | Дельта Про`;
    const dispatch = useDispatch();

    const [fieldsShowing, setFieldsShowing] = useState(formFields);
    const [submitActive, setSubmitActive] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);
    const [apiResponse, setApiResponse] = useState({});
    const [timer, setTimer] = useState(true);
    const [timerId, setTimerId] = useState();
    const [autofillComplete, setAutofillComplete] = useState(false);

    const isDarkTheme = useTheme('dark', () => true);
    const navigate = useNavigate();
    const axiosInstance = useBearerToken();

    const intervalRef = useRef(null);
    const formRef = useRef(null);


    useEffect(() => {
        if (!autofillComplete) {
            intervalRef.current = window.setInterval(() => {
                if (detectAutofill(document.querySelector('.form__input-field'))) {
                    setAutofillComplete(true);
                } else {
                }
            }, 200);
            for (let i = 0; i < intervalRef.current; i++) {
                clearInterval(i);
            }
        } else {
            clearInterval(intervalRef.current);
        }
    }, [autofillComplete])

    useEffect( () => {
        const callbackOnTimeout = () => {
            setApiResponse({});
        };

        if (timer) {
            // timer should start
            let id = setTimeout(callbackOnTimeout, 5000);
            setTimerId(id);
        } else if (!timer && timerId) {
            clearTimeout(timerId);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        }
    }, [timer]); // listen to changes on timer state

    /**
     *
     * @param e {Object<*>}
     * @param ignoreSubmit {Boolean} переход на новый инпут табом вместо энтера (в этом случае игнорируем сабмит, но позволяем открыть графу пароля)
     * @returns {number}
     */
    const handleButtonSubmit = (e, ignoreSubmit) => {
        const displayingFields = fieldsShowing.filter((field) => field.display);
        const hiddenFields = fieldsShowing.filter((field) => !field.display);
        if (hiddenFields.length !== 0) {

            const inputField = formRef.current.children[displayingFields.length - 1].children[0];
            if (inputField.value) {
                if (inputField.name === 'password_repeat') {
                    const previousField = formRef.current.children[displayingFields.length - 2].children[0];
                    if (previousField.value !== inputField.value) {
                        return 0;
                    }
                }
                setSubmitActive(false);
                setButtonActive(false);
                hiddenFields[0].display = true;
                setFieldsShowing(displayingFields.concat(hiddenFields));
            }

            if (hiddenFields.length === 1) {
                setSubmitActive(true);
                e.target.innerText = buttonCaptionOnSubmit;
            }
        } else {
            if (!ignoreSubmit) {
                handleSubmit(e);
            }
        }
    };

    const handleInputKeyPress = (e) => {
        if (buttonActive) {
            if (e.key === 'Enter' || e.key === 'Tab') {
                handleButtonSubmit(e, e.key === 'Tab');
            }
        }
    }

    const handleInputChange = (e) => {
        const {value} = e.target;
        setAutofillComplete(false);

        const fieldsShowingOnUpdate = fieldsShowing;
        fieldsShowingOnUpdate[e.target.getAttribute('data-index')]['value'] = value ?? null;

        setFieldsShowing(fieldsShowingOnUpdate);

        const displayingFields = fieldsShowingOnUpdate.filter((field) => field.display);
        const filledFields = displayingFields.filter((displayingField) => displayingField.value);
        if (filledFields.length !== 0 && filledFields.length === displayingFields.length) {
            if (filledFields[filledFields.length - 1].name === 'password_repeat') {
                setButtonActive((filledFields[filledFields.length - 1].value === filledFields[filledFields.length - 2].value));
            } else {
                setButtonActive(true);
            }
        } else {
            setButtonActive(false);
        }
    }

    const handlePasswordShow = (e) => {
        const currentSrc = e.target.getAttribute('src');
        const elementIndex = e.target.getAttribute('data-index');
        const inputField = formRef.current.children[elementIndex].children[0];

        if (currentSrc === PASSWORD_SHOW || currentSrc === PASSWORD_SHOW_DARK) {
            const attributeValue = (isDarkTheme) ? PASSWORD_HIDE_DARK : PASSWORD_HIDE;
            e.target.setAttribute('src', attributeValue);
            inputField.setAttribute('type', 'text');
        } else {
            const attributeValue = (isDarkTheme) ? PASSWORD_SHOW_DARK : PASSWORD_SHOW;
            e.target.setAttribute('src', attributeValue);
            inputField.setAttribute('type', 'password');
        }
    }

    const handleAxiosCall = () => {
        setApiResponse({});
        const queryFields = {};
        const formData = new FormData();
        Array.from(formRef.current).map((inputFieldRef) => queryFields[inputFieldRef.name] = inputFieldRef.value);

        if (formEndpoint === '/register' || formEndpoint === '/password') {
            if (queryFields.password === queryFields.password_repeat) {
                delete queryFields.password_repeat;
            } else {
                return 0;
            }
        }

        for (const [key, value] of Object.entries(queryFields)) {
            formData.append(key, value);
        }

        if (formEndpoint === '/login' && token) {
            formData.append('email_token', token);
        }

        if (formEndpoint === '/password') {
            formData.append('restore_token', new URLSearchParams(document.location.search).get('token'));

            axios.put(`${APP_URL}auth${formEndpoint}`, formData, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then((response) => {
                 handleUserLogin(response);
            }).catch((exception) => {
                const {data} = exception.response;
                if (data) {
                    const validationFieldsList = {};
                    validationFieldsList['password'] = data['token'];
                    setApiResponse(validationFieldsList);
                }
            });

        } else {
            axios.post(`${APP_URL}auth${formEndpoint ?? `/login`}`, formData, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then((response) => {
                    if (formEndpoint === '/password_reset') {
                        window.clearInterval(intervalRef.current);
                        navigate({
                            pathname: '/reset_confirmation',
                            search: `${createSearchParams({
                                email: queryFields.email,
                            })}`
                        });
                    } else if (formEndpoint === '/register') {
                        navigate(`/email_confirm?email=${queryFields.email}&username=${queryFields.username}`);
                    }

                    if (formEndpoint !== '/register') {
                        handleUserLogin(response);
                    }

                }).catch((exception) => {

                const {data} = exception.response;
                if (data) {
                    const validationFieldsList = {};
                    ['email', 'username', 'password'].filter((validationField) => {
                        if (validationField in data) {
                            validationFieldsList[validationField] = data[validationField];
                        }
                    })

                    if (data.detail) {
                        validationFieldsList['email'] = data.detail;
                    }

                    if (data.details) {
                        validationFieldsList['email'] = data.details;
                    }

                    setApiResponse(validationFieldsList);
                }

                const timeout = setTimeout(() => {
                }, 3000);
                setTimer(timeout);
            })
        }

    }

    const handleUserLogin = (response) => {
        const {data} = response;
        if (data['access'] && data['refresh']) {
            ['access', 'refresh'].map((token) => localStorage.setItem(`${token}_token`, data[token]));
            if (data.user) {
                dispatch({ type: 'USER', user: data.user });
                window.clearInterval(intervalRef.current);
                navigate('/dashboard');
            }
        }
    }

    const handleSubmit = (e) => {
        handleAxiosCall()
    }

    /**
     * Метод для распознания автозаполнения
     * @param element
     * @returns {boolean}
     */
    const detectAutofill = (element) => {
        if (element) {
            return window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button';
        }
    }

    return (
        <div className={`form__register`}>
            <h2 className={`form__caption`}>
                {formCaption}
            </h2>
            <form
                className={`form__fields`}
                ref={formRef}
                autoComplete={(formEndpoint === '/register') ? 'one-time-code' : ``}
            >
                {fieldsShowing.map((showingInputField, index) => {
                    return (
                        <div
                            key={`form__input-${index}`}
                            className={clsx(`form__input-group`, {[`form__input-hidden`]: !showingInputField.display})}
                        >
                            <InputValidator message={apiResponse[showingInputField.name]} children={(
                                <input
                                    autoComplete={(formEndpoint === '/register') ? 'one-time-code' : ``}
                                    ref={formRef[index]}
                                    className={clsx(
                                        `form__input-field`,
                                        {[`form__input-hidden`]: !showingInputField.display},
                                        {[`form__input-invalid`]: (apiResponse[showingInputField.name]?.validation)}
                                    )}
                                    type={showingInputField.type}
                                    name={showingInputField.name}
                                    placeholder={showingInputField.placeholder}
                                    onChange={handleInputChange}
                                    onKeyDown={handleInputKeyPress}
                                    data-index={index}
                                />
                            )}/>

                            {(showingInputField.name === 'password' || showingInputField.name === 'password_repeat') && (
                                <img
                                    className={`form__password-icon`}
                                    data-index={index}
                                    onClick={handlePasswordShow}
                                    src={(isDarkTheme) ? PASSWORD_SHOW_DARK : PASSWORD_SHOW}
                                    alt={``}
                                />
                            )}
                            {(showingInputField.name === `username`) && (
                                <div
                                    className={clsx('form__name-caption', {[`form__input-hidden`]: !showingInputField.display})}>
                                    <p>
                                        Имя будет отображаться рядом с документами вашего авторства
                                    </p>
                                    <p className={clsx(`form__name-register`, {[`form__input-hidden`]: !buttonActive})}>
                                        Нажимая “Зарегистрироваться”, вы принимаете <a className={`form-links__link`}
                                                                                       href={`/policy?unauthorized=true`} target={`_blank`}>политику
                                        конфиденциальности</a>
                                    </p>
                                </div>
                            )}
                        </div>
                    )
                })}
            </form>
            <button
                className={clsx(
                    `form__continue_button`,
                    {[`form__button-submit`]: submitActive},
                    {[`form__button-active`]: buttonActive || autofillComplete}
                )}
                onClick={handleButtonSubmit}
                disabled={(!buttonActive && !autofillComplete)}
            >
                {(submitActive) ? buttonCaptionOnSubmit : `Продолжить`}
            </button>

            {links}

        </div>
    )
}

export default LoginForm;