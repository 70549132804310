import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import Sidemenu from "../../Components/sidemenu/Sidemenu.jsx";
import ClaimSendDocument from "../Claims/ClaimSendDocument.jsx";
import Hint from "../../Components/monitoring/Hint.jsx";

import "./styles/createCourt.scss";

const CourtCreate = () => {
    const navigate = useNavigate();

    const [documents, setDocuments] = useState([
        {
            id: 1,
            title: "Претензия по договору поставки 45 Техномир 05102323",
            pagesAmount: 1,
            isAutoCalculatable: false,
            isDeletable: false
        },
        {
            id: 2,
            title: "Претензия по договору поставки 45 Техномир 05102323",
            pagesAmount: 1,
            isAutoCalculatable: false,
            isDeletable: true
        },
        {
            id: 3,
            title: "Претензия по договору поставки 45 Техномир 05102323",
            pagesAmount: 1,
            isAutoCalculatable: false,
            isDeletable: true
        },
        {
            id: 4,
            title: "Претензия по договору поставки 45 Техномир 05102323",
            pagesAmount: 1,
            isAutoCalculatable: false,
            isDeletable: true
        }
    ]);

    const [files, setFiles] = useState(null);

    useEffect(() => {
        if (Array.isArray(files)) {
            files.forEach((file) => {
                const newDoc = {
                    id: file.lastModified,
                    title: file.name,
                    pagesAmount: 1,
                    isAutoCalculatable: false,
                    isDeletable: true
                };
                documents.push(newDoc);
            });
            setDocuments(documents);
            setFiles(null);
        }
    }, [files]);

    const fileInputRef = useRef(null);

    const deleteDocument = (documentId) => {
        setDocuments(documents.filter((document) => document.id !== documentId));
    };

    const handleFilesChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const handleClickToUploadFiles = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const sendDocument = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div className={`court-create__container`}>
                <Sidemenu />

                <div className={`court-create__back-wrapper`}>
                    <div className={`court-create__back button__back`} onClick={() => navigate("/monitoring")}>
                        <img src={`/img/arrow__back.svg`} alt={``} />
                    </div>
                </div>

                <div className={`court-create__content`}>
                    <form action={`#`} className={`court-create__content--form`}>
                        <div className={`court-create__documents-wrapper court-create__stage`}>
                            <div className={`court-create__documents`}>
                                <h3>1. Сформировать приложение к иску</h3>

                                <div className={`court-create__documents-content`}>
                                    {documents.map((document) => (
                                        <ClaimSendDocument
                                            key={document.id}
                                            document={document}
                                            deleteDocumentCallback={() => deleteDocument(document.id)}
                                        />
                                    ))}
                                </div>
                                <div className="court-create__btns">
                                    <button className={`court-create__add-document`} onClick={handleClickToUploadFiles}>
                                        Добавить документ
                                    </button>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFilesChange}
                                        multiple
                                        ref={fileInputRef}
                                    />
                                    <button className={`court-create__add-document`}>
                                        Скачать все документы для самостоятельной отправки
                                    </button>
                                </div>
                            </div>

                            {/* Блок нужен для отступа вертикального скролбара от правого края блока */}
                            <div className={`court-create__empty-block`}></div>
                        </div>
                        <div className={`court-create__delivery court-create__stage court-create__stage_send`}>
                            <h3>2. Отправить иск ответчику</h3>
                            <div className="content">
                                <div className={`court-create__delivery-select`}>
                                    <select defaultValue={``}>
                                        <option value={``} disabled>
                                            Выбрать документы для отправки ответчику
                                        </option>
                                        <option>По почте России (электронно)</option>
                                        <option>Отправить по ЭДО</option>
                                    </select>
                                </div>
                                <button className={`court-create__load-button`}>Загрузить</button>
                            </div>
                        </div>
                        <div className={`court-create__delivery court-create__stage`}>
                            <div className="court-create__title">
                                <h3>3. Оплатить госпошлину</h3>
                                <Hint text={ 'тестовый текст 1' } />
                            </div>
                        </div>
                        <div className={`court-create__delivery court-create__stage`}>
                            <div className="court-create__title">
                                <h3>4. Подтвердить подачу иска в суд</h3>
                                <Hint text={ 'тестовый текст 2' } />
                            </div>
                        </div>

                        <button className={`court-create__send-document`} onClick={sendDocument}>
                            Отправлено в суд
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CourtCreate;
