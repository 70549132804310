import React, { useEffect, useRef, useState } from "react";

import "./styles/claimSend.scss";
import { useNavigate } from "react-router";

const ClaimSendPopupSuccess = ({ togglePopupCallback, errorInfo, button }) => {
    const navigate = useNavigate();

    if (!button) {
        button = { text: "Перейти", callback: () => navigate("/monitoring") };
    }
    const { text: buttonText, callback: buttonCallback } = button;

    const togglePopup = () => {
        togglePopupCallback("success");
    };

    return (
        <>
            <div className={`claim-send__popup-wrapper`}>
                <div className={`claim-send__popup claim-send__popup--success`}>
                    <div className={`claim-send__popup__header`}>
                        <h2>{errorInfo ? "Ошибка отправки" : "Отправлено адресату"}</h2>
                        <img
                            className={`claim-send__popup__header--close-btn`}
                            onClick={togglePopup}
                            src={`/img/monitoring/close__icon.svg`}
                            alt={``}
                        />
                    </div>

                    <div className={`claim-send__popup__content`}>
                        {errorInfo && (
                            <div className={`claim-send__popup__content`}>
                                <p>Список ошибок:</p>
                                <p>{errorInfo}</p>
                            </div>
                        )}

                        {errorInfo ? (
                            ""
                        ) : (
                            <>
                                <p>Претензия находится на странице Мониторинг.</p>
                                <p>Статус "на контроле"</p>
                            </>
                        )}
                    </div>
                    <div className={`claim-send__popup-actions`}>
                        <button
                            className={`claim-send__popup-actions-btn claim-send__popup-actions-btn--red claim-send__popup-actions-btn--lg`}
                            onClick={buttonCallback}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClaimSendPopupSuccess;
