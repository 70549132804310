import React from "react";
import {useTheme} from "../Helpers/Providers/ThemeProvider";

import './styles/policy.scss';
import {useSearchParams} from "react-router-dom";

const Policy = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const userUnauthorized = searchParams.get('unauthorized');

    document.title = `Политика конфиденциальности | Дельта Про`;
    const isDarkTheme = useTheme('dark', () => true);

    return (
        <div className={`policy__container`}>
            <div className={`policy__wrapper`}>
                {!userUnauthorized && (
                    <a className={`button__back`} href={`/settings`}>
                        <img src={`/img/arrow__back.svg`} alt={``}/>
                    </a>
                )}
                <div className={`policy__content`}>
                    <div className={`policy__logo`}>
                        <a className={`back__mobile`} href={`/settings`}>
                            <img src={`/img/arrow__back.svg`} alt={``}/>
                        </a>

                        <img
                            className={`auth__logo-image`}
                            src={(isDarkTheme) ? `/img/delta__logo-dark.svg` : `img/delta__logo.svg`}
                            alt={``}
                        />
                    </div>

                    <h2 className={`policy__header`}>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
                    <p className={`policy__paragraph`}>
                        Настоящая Политика конфиденциальности персональных данных (далее - Политика конфиденциальности)
                        действует в отношении всей информации, предоставляемой Пользователем программы <a className={`policy__link`}>ДЕЛЬТА.doc.
                        (далее – Программа)</a>,
                        размещенной в сети Интернет по адресу: www.doc.deltacg.ru.
                        Использование Программы означает безоговорочное согласие Пользователя с Политикой
                        конфиденциальности и предоставление <a className={`policy__link`}>Согласия на обработку персональных данных</a> в порядке и на
                        условиях, определенных настоящей Политикой и действующим законодательством.
                    </p>

                    <h3 className={`policy__header-small`}>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                    <p className={`policy__paragraph`}>
                        1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:<br/>
                        1.1.1. Информация, которую Пользователь предоставляет о себе самостоятельно при регистрации
                        (создании учетной записи) или в процессе использования Программы, включая персональные данные
                        Пользователя. Информация, обязательная для предоставления Пользователем в целях использования
                        Программы, помечена специальным образом.<br/>
                        1.1.2. Данные, которые автоматически передаются Программе в процессе ее использования с помощью
                        установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные
                        файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой
                        осуществляется доступ к Программе), технические характеристики оборудования и программного
                        обеспечения, используемых Пользователем, дата и время доступа к Программе, адреса запрашиваемых
                        страниц и иная подобная информация.<br/>
                        1.1.3. Иная информация, предоставленная Пользователем, для пользования ресурсам Программы.<br/>
                        1.2. Настоящая Политика конфиденциальности применяется только к <a className={`policy__link`}>Программе ДЕЛЬТА.doc</a>.
                        Администратор Программы не контролирует и не несет ответственности за сайты третьих лиц, на
                        которые Пользователь может перейти по ссылкам в Программе.<br/>
                    </p>
                    <h3 className={`policy__header-small`}>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h3>
                    <p className={`policy__paragraph`}>
                        2.1. Программа собирает и хранит только ту персональную информацию, которая необходима для
                        предоставления услуг пользования Программой.<br/>
                        2.2. Персональную информацию Пользователя Программа обрабатывает в следующих целях:<br/>
                        2.2.1. Идентификации Пользователя, зарегистрированного в Программе.<br/>
                        2.2.2. Предоставления Пользователю доступа к персонализированным ресурсам.<br/>
                        2.2.3. Установления с Пользователем обратной связи, включая направление уведомлений,
                        предложений, запросов, ответов на запросы Пользователя, возникающих у последнего в рамках
                        использования Программы.<br/>
                        2.2.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
                        мошенничества.<br/>
                        2.2.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.<br/>
                        2.2.6. Создания учетной записи для пользования всеми ресурсами Программы, если Пользователь
                        зарегистрировал свой личный кабинет в Программе.<br/>
                        2.2.7. Предоставления Пользователю эффективной клиентской и технической поддержки при
                        возникновении проблем, связанных с использованием Программы.<br/>
                        2.2.8. В иных целях, непосредственно связанных предоставлением Пользователю доступа ко всем
                        ресурсам Программы.<br/>
                    </p>

                    <h3 className={`policy__header-small`}>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                        ПОЛЬЗОВАТЕЛЕЙ</h3>
                    <p className={`policy__paragraph`}>
                        3.1. Программа хранит персональную информацию Пользователей в соответствии с внутренним
                        регламентом сервиса.<br/>
                        3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме
                        случаев добровольного предоставления Пользователем информации о себе для общего доступа
                        неограниченному кругу лиц.<br/>
                        3.3. Программа вправе передать персональную информацию Пользователя третьим лицам в следующих
                        случаях:<br/>
                        3.3.1. Пользователь выразил согласие на такие действия.<br/>
                        3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для
                        исполнения определенного соглашения или договора с Пользователем.<br/>
                        3.3.3. Передача предусмотрена российским или иным применимым законодательством в рамках
                        установленной законодательством процедуры.<br/>
                        3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока любым
                        законным способом, в том числе в информационных системах персональных данных с использованием
                        средств автоматизации или без использования таких средств. Обработка персональных данных
                        Пользователей осуществляется в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ «О
                        персональных данных».<br/>
                        3.5. Администратор Программы принимает необходимые организационные и технические меры для защиты
                        персональной информации Пользователя от неправомерного или случайного доступа, уничтожения,
                        изменения, блокирования, копирования, распространения, а также от иных неправомерных действий
                        третьих лиц.<br/>
                    </p>

                    <h3 className={`policy__header-small`}>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h3>
                    <p className={`policy__paragraph`}>
                        4.1. Пользователь обязан:<br/>
                        4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Программой.<br/>
                        4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения
                        данной информации.<br/>
                        4.2. Администратор Программы обязан:<br/>
                        4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей
                        Политике конфиденциальности.<br/>
                        4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без
                        предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен,
                        опубликование либо разглашение иными возможными способами переданных персональных данных
                        Пользователя, за исключением случаев, предусмотренных настоящей Политикой.<br/>
                        4.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных
                        Пользователя согласно порядку, обычно используемому для защиты такого рода информации в
                        существующем деловом обороте.<br/>
                        4.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему
                        Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо
                        уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае
                        выявления недостоверных персональных данных или неправомерных действий.<br/>
                    </p>
                    <h3 className={`policy__header-small`}>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
                    <p className={`policy__paragraph`}>
                        5.1. Администратор Программы, не исполнивший свои обязательства, предусмотренные настоящей
                        Политикой, несет ответственность в соответствии с действующим законодательством Российской
                        Федерации.<br/>
                        5.2. В случае утраты или разглашения конфиденциальной информации Администратор Программы не
                        несет ответственности, если данная конфиденциальная информация:<br/>
                        5.2.1. Стала публичным достоянием до ее утраты или разглашения.<br/>
                        5.2.2. Была получена от третьей стороны до момента ее получения Администратором Программы.<br/>
                        5.2.3. Была разглашена с согласия Пользователя.<br/>
                    </p>

                    <h3 className={`policy__header-small`}>6. РАЗРЕШЕНИЕ СПОРОВ</h3>
                    <p className={`policy__paragraph`}>
                        6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и
                        Администратором Программы, обязательным является предъявление претензии (письменного предложения
                        о добровольном урегулировании спора).<br/>
                        6.2. Получатель претензии в течение 30 (тридцати) календарных дней со дня получения претензии
                        письменно уведомляет заявителя претензии о результатах рассмотрения претензии.<br/>
                        6.3. При недостижении соглашения спор будет передан на рассмотрение в суд по месту нахождения
                        Администратора Программы.<br/>
                    </p>

                    <h3 className={`policy__header-small`}>7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
                    <p className={`policy__paragraph`}>
                        7.1. Администратор Программы вправе вносить изменения в настоящую Политику конфиденциальности
                        без согласия Пользователя.<br/>
                        7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения в программе, если
                        иное не предусмотрено новой редакцией Политики конфиденциальности.<br/>
                        7.3. О согласии Пользователя с условиями настоящей Политики и предоставления им Согласия на
                        обработку персональных данных свидетельствует регистрация Пользователя в Программе и пользование
                        ее ресурсами.<br/>
                    </p>


                </div>
            </div>
        </div>
    )
}

export default Policy;