import React
    , {useEffect} from "react";

const ModalOnLoadProgress = ({
                                 progressBarPercent,
                                 selectedFileName,
                                 callbackOnCancelBtnClick,
                                 errors
                             }) => {

    const getUploadIconName = () => {
        return progressBarPercent === 100 ? 'complete' : 'progress-down'
    }

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    return (
        <div className={`settings__modal-content settings__modal-content-centered`}>
            <div className={`dashboard__modal-title`}>
                Загрузка договора
            </div>

            <div className={`dashboard__modal-progress-bar`}>
                <div className={`progress-bar__percent`}>
                    <div className={`progress-bar__percent--text`}>
                        <p className={`progress-bar__paragraph`}>{selectedFileName}</p>
                        <p>{progressBarPercent}%</p>
                    </div>
                    <progress value={progressBarPercent} max="100"></progress>
                </div>
                <div className={`progress-bar__icon`}>
                    <img
                        src={`/img/upload__${(getUploadIconName())}.svg`}
                        alt={``}
                    />
                </div>
            </div>
            {errors?.status === true && (
                <div className={`dashboard__errors`}>
                    Ошибка: <span className={`dashboard__error`}>{errors?.error?.toString()}</span>
                </div>
            )}
            <button className={`dashboard__modal-progress-btn`} onClick={callbackOnCancelBtnClick}>
                Отменить
            </button>
        </div>
    );
}

export default ModalOnLoadProgress;