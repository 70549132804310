import React, { useEffect, useState } from "react";
import { ThemeContext, themes } from "./themes/themeContext";

/**
 *
 * @returns {string}
 */
export const getTheme = () => {
    let result = themes.dark;
    const localStorageThemeValue = `${window.localStorage?.getItem("theme")}`;
    if (Object.values(themes).includes(localStorageThemeValue)) {
        result = localStorageThemeValue;
    }

    const userMedia = window.matchMedia(`(prefers-color-scheme: light)`);
    if (userMedia.matches) {
        result = themes.light;
    }

    return result;
};

/**
 * Применить функцию, если тема на входе аналогична сохраненной в сторе
 * @param value вводимое значение темы
 * @param callback пользовательская функция
 * @returns {false}
 */
export const useTheme = (value, callback) => getTheme() === value && callback();

export const setGlobalTheme = (theme, setTheme) => {
    document.documentElement.dataset.theme = theme;
    window.localStorage.setItem("theme", theme);
};

//todo вместо gettheme использовать redux
const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(getTheme);

    useEffect(() => {
        setGlobalTheme(theme);
    }, [theme]);

    return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
