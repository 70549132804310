import React, {useCallback, useEffect, useState} from "react";
import clsx from "clsx";

import {APP_URL, useBearerToken} from "../../API/requests";
import Sidemenu from "../sidemenu/Sidemenu";
import RightSidemenu from "../sidemenu/RightSidemenu";

import ControlDocument from "./documents/ControlDocument";
import CreateDocumentTemplate from "./documents/CreateDocumentTemplate";

import ModalOnLoadStart from "./modal/ModalOnLoadStart";
import ModalOnLoadComplete from "./modal/ModalOnLoadComplete";
import ModalOnLoadProgress from "./modal/ModalOnLoadProgress";

import MonitoringWorkItem from "./monitoring/MonitoringWorkItem";
import MonitoringJudgeItem from "./monitoring/MonitoringJudgeItem";



const DashboardContent = ({inputDocumentRef}) => {
    const axiosInstance = useBearerToken();

    const [selectedFilename, setSelectedFilename] = useState(null);
    const [autoname, setAutoname] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [documentLoaded, setDocumentLoaded] = useState(null);

    const [buttonActive, setButtonActive] = useState(false);
    const [fileLoadCompleted, setFileLoadCompleted] = useState(false);
    const [errorData, setErrorData] = useState({});
    const [modalVisible, setModalVisible] = useState(false); // состояние модального окна: открыто-закрыто
    const [exitOnEscape, setExitOnEscape] = useState(false);

    // const [inputActive, setInputActive] = useState(false);
    const [progressBarPercent, setProgressBarPercent] = useState(0);
    const [currentAction, setCurrentAction] = useState('control');
    const [abortController, setAbortController] = useState(new AbortController());

    const handleKeyUp = useCallback((e) => {
        if (exitOnEscape && modalVisible && e.keyCode === 27) { // ESCAPE
            setModalVisible(false);
        }
    }, [modalVisible]);

    useEffect(() => {
        document.addEventListener("keyup", handleKeyUp);
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, [handleKeyUp]);

    useEffect(() => {
        if (modalVisible === false) {
            setFileLoadCompleted(false);
        }
    }, [modalVisible]);

    const lockExitOnEscape = (isUnlocked) => {
        setExitOnEscape(isUnlocked);
    }

    const callbackOnButtonClick = (e) => {
        const {target} = e;
        if (target.id) {
            setCurrentAction(target.id);
        }

        setModalVisible(true);
        // handleDocumentLoad(e);
    }

    const handleLoadAreaClick = (e, isDrag = false, filesOnDrag = null) => {
        lockExitOnEscape();
        if (isDrag && filesOnDrag) {
            inputDocumentRef.current.files = filesOnDrag;
            handleControlDocument();
        } else handleDocumentLoad(e);
    }

    const handleCancelBtnClick = (e) => {
        setFileLoadCompleted(false)
        setProgressBarPercent(0)

        abortController.abort();
        setAbortController(new AbortController());
    }

    const handleDocumentLoad = (e) => {
        inputDocumentRef.current.click();
    }

    const handleControlDocument = () => {
        const formData = new FormData();

        formData.append('contract', inputDocumentRef.current.files[0]);
        formData.append('name_of_contract', inputDocumentRef.current.files[0].name)
        formData.append('is_on_control', false);

        axiosInstance.post(`${APP_URL}contracts/`, formData, {
            onUploadProgress: (progressEvent) => {
                const progressBarPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgressBarPercent(progressBarPercent);
            },
            signal: abortController.signal
        }).then((response) => {
            setFileName(inputDocumentRef.current.files[0]?.name ?? null);
            const {data} = response;
            if (data.id) setDocumentLoaded(data.id);

            const getContractUrl = `${APP_URL}contracts/${data.id}?expand_events=true&expand_claims=true`;
            axiosInstance.get(getContractUrl).then((response) => {
                setProgressBarPercent(0)
                setFileLoadCompleted(true);

                // отладка получения ответа ии
                const responseInterval = setInterval(() => {
                    axiosInstance.get(getContractUrl).then((response) => {
                        if (response.data) {
                            if (response.data.status && response.data.status !== 'processing') {
                                // console.log(response.data.name_of_contract);
                                setAutoname(response.data.name_of_contract);
                                clearInterval(responseInterval);
                            }
                        }
                    });
                }, 5000)
            })
        }).catch((error) => {
            console.log('%cError:' + error, process.env.REACT_APP_ERROR_STYLES);
            setFileLoadCompleted(false);
            setErrorData({
                status: true,
                error: error,
            });
        })
    };

    const handleCreateTemplate = () => {
    };
    const handleModalToggle = () => {
        setModalVisible(!modalVisible);
        setSelectedFilename(null);
    }

    const callbackOnInputChange = () => {
        if (inputDocumentRef.current.files && inputDocumentRef.current.files[0]) {
            setSelectedFilename(inputDocumentRef.current.files[0].name)
            setButtonActive(true);
        }
    }

    const callbackOnDocumentLoad = (e) => {
        if (currentAction === 'control') {
            handleControlDocument();
        } else if (currentAction === 'template') {
            handleCreateTemplate();
        } else console.log('no current action');
    }

    return (
        <>
            <div className={clsx(
                `settings__modal-container`, {[`settings__modal-visible`]: modalVisible})}
            >
                <div className={`settings__modal-wrapper`}>
                    {(fileLoadCompleted) ?
                        <ModalOnLoadComplete
                            callbackOnExit={handleModalToggle}
                            documentLoaded={documentLoaded}
                            autoname={autoname}
                            fileName={fileName}
                        />
                        : ((progressBarPercent) ?
                            <ModalOnLoadProgress
                                progressBarPercent={progressBarPercent}
                                selectedFileName={selectedFilename}
                                callbackOnCancelBtnClick={handleCancelBtnClick}
                                errors={errorData}
                            /> :
                            <ModalOnLoadStart
                                callbackOnAreaClick={handleLoadAreaClick}
                                callbackOnDocumentLoad={callbackOnDocumentLoad}
                                selectedFileName={selectedFilename}
                                buttonActive={buttonActive}
                                callbackOnExit={handleModalToggle}
                            />)
                    }
                </div>
            </div>

            <div className={clsx(`dashboard__container`, {[`settings__modal-visible`]: modalVisible})}>
                <Sidemenu/>
                <div className={`dashboard__content-wrapper`}>
                    <div className={`dashboard__buttons-row`}>
                        <ControlDocument
                            callbackOnClick={callbackOnButtonClick}
                        />
                        <CreateDocumentTemplate
                            callbackOnClick={callbackOnButtonClick}
                        />
                        <form>
                            <input
                                ref={inputDocumentRef}
                                className={`form__input-hidden`}
                                type={`file`}
                                name={`document`}
                                onChange={callbackOnInputChange}
                            />
                        </form>
                    </div>
                    <div className={`dashboard__monitoring`}>
                        <h2>Мониторинг</h2>
                        <div className={`dashboard__monitoring--wrap`}>
                            <MonitoringWorkItem
                                // workItem={workItem}
                            />
                            <MonitoringJudgeItem
                                // workItem={judgeItem}
                            />
                        </div>
                    </div>
                </div>
                <RightSidemenu/>
            </div>
        </>
    );
}

export default DashboardContent;