import React from "react";
import clsx from "clsx";

import MonitoringContract from "./MonitoringContract";
import ModalContractEventLog from "./ModalContractEventLog";

const MonitoringResultsList = ({
    monitoringDisplay,
    contractsList,
    onContractSelect,
    selectedEntity,
    showEventLog,
    eventLogCallback,
    activeTypeFilter
}) => {
    return (
        <>
            <div className={clsx({ display__grid: monitoringDisplay === "grid" }, `monitoring__main-content`)}>
                {contractsList && contractsList.map((contract, index) => {
                    if (contract.status !== "failed") {
                        return (
                            <MonitoringContract
                                key={`contract__${index}`}
                                contract={contract}
                                contractDisplay={monitoringDisplay}
                                callbackOnClick={onContractSelect}
                                isSelected={selectedEntity && selectedEntity.id === contract.id}
                                showEventLogModalCallback={eventLogCallback}
                            />
                        );
                    } else return null;
                })}
            </div>
            {showEventLog && <ModalContractEventLog showEventLogModalCallback={eventLogCallback} />}
        </>
    );
};

export default MonitoringResultsList;
