import React from "react";
import {useSelector} from "react-redux";
import InputValidator from "../../Helpers/Validators/InputValidator";

const ModalCommonForm = ({modalField, passwordRef, passwordConfirmationRef, modalRef, validationResponse}) => {
    const user = useSelector((state) => state.user);

    return (
        <>
            <InputValidator message={validationResponse['common']} children={(
                <input
                    key={modalField}
                    className={`modal-input__input-field`}
                    ref={modalRef}
                    type={(modalField !== `password`) ? `text` : `password`}
                    name={(modalField === 'password') ? 'current_password' : modalField}
                    placeholder={(modalField === 'promo') ? 'Введите промокод' : (modalField === 'password') ? 'Старый пароль' : ''}
                    defaultValue={(modalField !== `password`) ? user[modalField] : ''}
                />
            )} />
            {(modalField === 'password') && (
                <>
                    <div className={`modal-input__label`}>
                        Новый пароль
                    </div>
                    <InputValidator message={validationResponse['new_password']} children={(
                        <input
                            ref={passwordRef}
                            className={`modal-input__input-field`}
                            type={`password`}
                            name={`new_password`}
                            placeholder={`Придумайте пароль`}
                        />
                    )} />
                    <div className={`modal-input__label`}>
                        Подтвердите пароль
                    </div>
                    <InputValidator message={validationResponse['password_confirmation']} children={(
                        <input
                            ref={passwordConfirmationRef}
                            className={`modal-input__input-field`}
                            type={`password`}
                            name={`password_confirmation`}
                            placeholder={`Подтвердите пароль`}
                        />
                    )} />
                </>

            )}
        </>
    )
}

export default ModalCommonForm;