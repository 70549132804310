import React from "react";
import clsx from "clsx";

import { setGlobalTheme } from "../../Helpers/Providers/ThemeProvider";
import { useThemeContext } from "../../Helpers/Providers/themes/themeContext";

const ThemeToggleButton = ({ isExpanded }) => {
    const { theme, setTheme } = useThemeContext();

    const handleThemeChange = () => {
        const toggleResult = theme === "dark" ? "light" : "dark";

        setGlobalTheme(toggleResult);
        setTheme(toggleResult);
    };

    return (
        <div className={clsx({ expanded: isExpanded }, `sidemenu-theme__wrapper`)}>
            <div
                onClick={handleThemeChange}
                className={`sidemenu-theme__toggle-element ${
                    theme === "dark" ? "sidemenu-theme__dark" : "sidemenu-theme__light"
                }`}
            >
                <div className={`sidemenu-theme__toggle-slider`}>
                    <img src={`/img/themes/${theme === "dark" ? "theme__dark" : "theme__light"}.svg`} alt={``} />
                </div>
            </div>

            <div className={clsx({ hidden: !isExpanded }, `sidemenu-theme__current-theme`)}>
                {theme === "dark" ? "Тёмная" : "Светлая"} тема
            </div>
        </div>
    );
};

export default ThemeToggleButton;
