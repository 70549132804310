import React from "react";
import {useTheme} from "../Helpers/Providers/ThemeProvider";
import {useSearchParams} from "react-router-dom";

const ConfirmEmail = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const isDarkTheme = useTheme('dark', () => true);
    const email = searchParams.get('email');
    const username = searchParams.get('username');

    return (
        <div className={`app__reset-container`}>
            <div className={`app__reset-wrapper`}>
                <div className={`app__reset-logo`}>
                    <img
                        className={`app__reset-image`}
                        src={(isDarkTheme) ? `/img/delta__logo-dark.svg` : `img/delta__logo.svg`}
                        alt={``}
                    />
                </div>
                <div className={`app__reset-title`}>
                    {username}, мы отправили <span className={`desktop__hidden`}>вам</span> письмо на <a className={`app__reset-link`} href={`mailto: ${email}`}>{email}</a>
                </div>

                <div className={`app__reset-text`}>
                    Пожалуйста, перейдите по ссылке в письме для подтверждения регистрации
                </div>
            </div>
        </div>
    );
}

export default ConfirmEmail;