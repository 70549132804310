import React from "react";

/**
 * Компонент для создания автошаблона
 * @param callbackOnClick
 * @returns {JSX.Element}
 * @constructor
 */
const CreateDocumentTemplate = ({callbackOnClick}) => {
    return (
        <button
            onClick={callbackOnClick}
            className={`dashboard__button`}
            id={'template'}
        >
            <div className={`dashboard-button__image`}>
                <img src="/img/dashboard__template.svg" alt=""/>
            </div>
            <div className={`dashboard-button__caption`}>
                Создать автошаблон
            </div>
        </button>
    );
}

export default CreateDocumentTemplate;