import React from "react";

import './styles/rightSidemenu.scss';
import './styles/rightSidemenu.css';
import {useNavigate} from "react-router-dom";

const RightSidemenu = ({}) => {
    const navigate = useNavigate();

    return (
        <div className={`right-sidemenu__wrapper`}>
            <div className={`right-sidemenu__calendar`}>
                <h3>Календарь судов</h3>
                <div className={`right-sidemenu__dates`}></div>
            </div>

            <div className={`right-sidemenu__tariff`}>
                <img src="/img/delta__logo.svg" alt="logo"/>
                <p>Следующий платёж 11/12/2023</p>
                <h4>Личный</h4>
                <a href="/">Смотреть все тарифы</a>
            </div>

            <div className={`right-sidemenu__integration`}>
                <h3>Интеграция</h3>
                <div className={`right-sidemenu__integration--list`}>
                    <div>
                        <p>1С</p>
                        <img src="/img/delta__token-confirmed.svg" alt=""/>
                    </div>
                    <div>
                        <p>Банк-клиент</p>
                        <img src="/img/delta__token-confirmed.svg" alt=""/>
                    </div>
                    <div>
                        <p>ЭДО</p>
                        <img src="/img/cross.svg" alt=""/>
                    </div>
                    <div>
                        <p>My.arbitr</p>
                        <img src="/img/delta__token-confirmed.svg" alt=""/>
                    </div>
                    <div>
                        <p>Почта России</p>
                        <img src="/img/cross.svg" alt=""/>
                    </div>
                </div>

                <button
                    className={`right-sidemenu__integration-button`}
                    onClick={() => navigate('/integrations')}
                >
                    <img
                        className={`right-sidemenu__integration--icon`}
                        src="/img/documents/btn__integration.svg"
                        alt=""
                    />
                </button>
            </div>
        </div>
    );
}

export default RightSidemenu;