import React from "react";

const CustomProgress = ({value}) => {
    return (
        <div className={`progress__container`}>
            <div className={`progress__scale`} style={{width: `${value}%`}}>
            </div>
        </div>
    )
}

export default CustomProgress;