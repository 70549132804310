import React from "react";

/**
 * Компонент для постановки документа на контроль
 * @param callbackOnClick
 * @returns {JSX.Element}
 * @constructor
 */
const ControlDocument = ({callbackOnClick}) => {

    return (
        <button
            className={`dashboard__button`}
            onClick={callbackOnClick}
            id={`control`}
        >
            <div className={`dashboard-button__image`}>
                <img src="/img/dashboard__document.svg" alt=""/>
            </div>
            <div className={`dashboard-button__caption`}>
                Поставить договор на контроль
            </div>
        </button>
    );
}

export default ControlDocument;