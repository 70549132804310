import React, {useEffect, useState} from "react";

import {resolveWordCasesForDocuments} from "../../Helpers/cases";

import ReactionInput from "./ReactionInput";
import DocumentObligation from "./DocumentObligation";

const EventContainer = ({
                            eventsList,
                            onEventDelete,
                            onCustomChange,
                            handleEventsChange
                        }) => {
    const [events, setEvents] = useState([]);
    const [eventsOnUpdate, setEventsOnUpdate] = useState(false);

    useEffect(() => {
        setEvents(eventsList);
        resolveWordCasesForDocuments(1);
    }, [eventsList]);

    useEffect(() => {
        if (eventsOnUpdate) {
            handleEventsChange(events);
            setEventsOnUpdate(false);
        }
    }, [eventsOnUpdate])

    const setEventTypeChange = (eventsNew, index) => {
        const eventsToUpdate = events;
        // console.log(eventsToUpdate, index);

        if (eventsToUpdate && eventsToUpdate.length > 0) {
            if (!eventsToUpdate[index]) {
                eventsToUpdate[index] = {};
            }
            eventsToUpdate[index].event = eventsNew;
            setEvents(eventsToUpdate)

            setEventsOnUpdate(true);
            // eslint-disable-next-line no-useless-concat
        } else console.log("%cError: ошибка обновления обязательства или события", process.env.REACT_APP_ERROR_STYLES)
    };

    const reactionExists = (contract, index) => {
        if (contract.event) {
            if (contract.event.date || contract.event.days_count) {
                return (
                    <ReactionInput
                        contractEvent={contract.event}
                        onEventTypeChange={setEventTypeChange}
                        index={index}
                    />
                )
            }
        }
    };

    const handleDeleteEvent = (index) => onEventDelete(index);
    const handleCustomChange = (index, value) => onCustomChange(index, value);

    return (
        <>
            {eventsList.map((contractEvent, index) => {
                let result;

                const {custom} = contractEvent.event;

                // contractEvent = contractEvent; // фикс структуры апи @todo убрать отсюда по возможности
                if (contractEvent.event.is_obligation === 'event') {
                    result = (
                        <div
                            key={`documents__event-${index}`}
                            className={`documents__events-element`}
                        >
                            <div className={`documents__event-title`}>
                                СОБЫТИЕ
                            </div>

                            <div className={`documents__event-container`}>
                                <div className={`documents__event-sides sides__event-container`}>
                                    <div className={`event-sides__row`} data-row-id={1}>
                                        <input
                                            className={`event-sides__input input__grey with__full-width`}
                                            placeholder={`Название события`}
                                            defaultValue={contractEvent?.event?.title ?? `Заключение договора и выставление счета`}
                                            />
                                        { /*
                                        <select
                                            className={`event-sides__select select__width-full`}
                                            defaultValue={1}
                                        >
                                            <option value={1}>
                                                {contractEvent?.event?.title ?? `Заключение договора и выставление счета`}
                                            </option>
                                        </select>
                                        */}

                                    </div>

                                    {reactionExists(contractEvent, index)}
                                </div>
                            </div>

                        </div>
                    );
                } else {
                    result = (
                        <DocumentObligation
                            isCustom={custom}
                            key={`documents__event-${index}`}
                            obligation={contractEvent}
                            onEventTypeChange={setEventTypeChange}
                            onEventDelete={handleDeleteEvent}
                            onCustomEventChange={handleCustomChange}
                            index={index}
                            length={eventsList.length}
                        />
                    );
                }

                return result;
            })}
        < />
    );

}
export default EventContainer;