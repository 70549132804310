import React from "react";

const IgnoreClaimButton = ({isIgnored, handleDisableControl}) => {
    return (
        <button className={`content-row__top-inputs--checkbox`} onClick={handleDisableControl}>
            {(isIgnored) ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                    <rect x="0.75" y="0.75" width="22.5" height="22.5" stroke="black"
                          strokeWidth="1.5"/>
                    <path d="M17 7.00004L7.00004 17M7 7L17 17" stroke="#252525" strokeWidth="1.5"
                          strokeLinecap="round"/>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                    <path d="M18 8L9.75 16L6 12.3636" stroke="black" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <rect x="0.75" y="0.75" width="22.5" height="22.5" stroke="black"
                          strokeWidth="1.5"/>
                </svg>
            )}
        </button>
    );
}

export default IgnoreClaimButton;