import React from "react";

const RegisterLinks = ({promo = false}) => {
    return (
        <div className={`form__links`}>
            <div className={`form-links__auth`}>
                Есть аккаунт? <a className={`form-links__link`} href={`/`}>Войти</a>
            </div>
            <div className={`form-links__promo`}>
                {promo && (
                    <a className={`form-links__link`} href={`/register`}>Назад</a>
                )}
                {!promo && (
                    <a className={`form-links__link`} href={`/register_promo`}>Есть промокод?</a>
                )}

            </div>
        </div>
    );
}

export default RegisterLinks;