import React from "react";
import {Navigate} from "react-router";

const ProtectedRoute = ({ user, children}) => {
    console.log(user);
    if (user) {
        if (Object.keys(user).length <= 0 || localStorage.getItem('access_token') == null) {
            return <Navigate to={'/'} replace />
        }
    } else return <Navigate to={'/'} replace />

    return children;
}

export default ProtectedRoute;
