import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useNavigate, useSearchParams} from "react-router-dom";

import DocumentViewer from "../../Components/doc-viewer/DocumentViewer";
import EventContainer from "../../Components/doc-viewer/EventContainer";
import BoundedDocuments from "../../Components/doc-viewer/BoundedDocuments";
import DocumentSides from "../../Components/doc-viewer/DocumentSides";
import ContractTypeSelect from "../../Components/doc-viewer/ContractTypeSelect";
import DocumentsModal from "../../Components/doc-viewer/DocumentsModal";
import DocumentLoadScreen from "../../Components/doc-viewer/utils/DocumentLoadScreen";
import SelectCurrency from "../../Components/forms/SelectCurrency";

import {parseContractResponse} from "../../Helpers/contracts";

import {APP_URL, useBearerToken} from "../../API/requests";

import './styles/documents.scss';
import {NEW_OBLIGATION_TEMPLATE} from "../../Helpers/Constants/global";

const customIdentifierTemplate = {
    sectors: [8, 4, 4, 4, 12],
    radix: 16,
};

const Control = () => {
    document.title = `Поставить на контроль | Дельта Про`;

    const navigate = useNavigate();

    const axiosInstance = useBearerToken();
    const [searchParams, setSearchParams] = useSearchParams();

    const [contract, setContract] = useState({});
    const [sidesList, setSidesList] = useState([]);
    const [eventsList, setEventsList] = useState([]);
    const [contractType, setContractType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [activeDocument, setActiveDocument] = useState('');
    const [isOnload, setIsOnload] = useState(true);
    const [status, setStatus] = useState(null);
    const [title, setTitle] = useState(null);

    const [newObligationsList, setNewObligationsList] = useState(0);

    const [contractSum, setContractSum] = useState(null);
    const [contractCurrency, setContractCurrency] = useState('RUB');

    useEffect(() => {
        const selectedContract = searchParams.get('contract');
        axiosInstance.get(`${APP_URL}contracts/${selectedContract}?expand_events=true`).then((response) => {
            const {data} = response;

            if (data?.contract) {
                const contractInfo = data;
                if (contractInfo.contract) {
                    setActiveDocument(contractInfo.contract);
                    setContract(contractInfo);
                }

                const contractResponse = parseContractResponse(contractInfo);
                setSidesList(contractResponse.sides);
                setEventsList(contractResponse.events);
                setContractType(contractResponse.type);
                setStatus(data.status);
                setTitle(data.name_of_contract);

                const eventWithSum = data.events.filter((eventEntity) => {
                    return eventEntity.sum !== null;
                })

                if (eventWithSum.length > 0) {
                    if (eventWithSum[0].currency) {
                        // console.log(eventWithSum[0].currency);
                        setContractCurrency(eventWithSum[0].currency);
                    }

                    setContractSum(Number(eventWithSum[0].sum).toFixed(0));
                }
            }
        })
    }, []);

    useEffect(() => {
        if (contract) {
            const contractResponse = parseContractResponse(contract);
            setEventsList(contractResponse.events);
        }
    }, [newObligationsList]);

    /**
     * Обработчик постановки документа на контроль
     */
    const handleDocumentControl = () => {
        const newContract = contract;

        newContract.is_on_control = (!newContract.is_on_control);

        newContract.events.forEach((event) => {
            event.who = [];

            if (!event.contract) {
                event.contract = newContract.id;
            }
        });

        axiosInstance.patch(
            `${APP_URL}contracts/${contract.id}`,
            newContract
        ).then(() => {
            //return navigate('/monitoring');
        }).catch((e) => console.log(e));
    }

    const handleModalShow = (modalType) => {
        setModalOpen(true);
        setModalType(modalType);
    }

    const handleModalClose = () => setModalOpen(false);
    const handleDocumentLoad = () => setIsOnload(false);

    const handleContractSumChange = (e) => {
        const sumToUpdate = contractSum;

        if (e.target && e.target.value) {
            const newContract = contract;

            newContract.events = Object.values(newContract.events).map((event, index) => {
                const apiSumParsed = sumToUpdate.split('.')[0];
                // console.log(Number(event.sum).toFixed(0), Number(apiSumParsed).toFixed(0));
                if (Number(event.sum).toFixed(0) === Number(apiSumParsed).toFixed(0)) {
                    const updatedSum = e.target.value.replace(/[^\d,-]/g, '', '');
                    setContractSum(updatedSum);
                    event.sum = e.target.value.replace(/[^\d,-]/g, '', '');
                }

                return event;
            });
            setContract(newContract);
        }
    }

    const handleEventsChange = (eventsListUpdated) => {
        const contractOnUpdate = contract;

        eventsListUpdated.forEach((eventUpdated, index) => {
            const eventObject = eventUpdated.event;
            const contractEvent = contractOnUpdate.events[index];
            contractEvent.type_date = (eventObject.type === 'in_date' || eventObject.type === 'until_date') ? 'абсолютная' : 'относительная';
            contractEvent.date = (eventObject.date) ? eventObject.date : contractEvent.date;
            contractEvent.days_count = (eventObject.period) ? eventObject.period : contractEvent.days_count;
            // console.log(contractEvent);
        });
        // setEventsList(eventsListUpdated);
    }

    const handleEventsDelete = (eventIndex) => {
        const contractToUpdate = contract;
        contractToUpdate.events = contractToUpdate.events.splice(0, eventIndex);
        setContract(contractToUpdate);
        setNewObligationsList(newObligationsList - 1);
    }

    const handleCustomChange = (eventIndex, value) => {
        const contractToUpdate = contract;
        contractToUpdate.events[eventIndex].name_events = value;
        contractToUpdate.events[eventIndex].type = value;
        setContract(contractToUpdate);
    }

    /**
     * Ручка для создания кастомного обязательства
     */
    const handleObligationCreate = () => {
        const {sectors, radix} = customIdentifierTemplate;

        /**
         * @param size
         * @returns {string}
         */
        const generateIdSector = size => [...Array(size)].map(
            () => Math.floor(Math.random() * radix).toString(radix)
        ).join('');

        /**
         * @type {string[]}
         */
        const obligationRandomId = sectors.map(
            (sectorLength) => generateIdSector(sectorLength)
        );

        /** @type {{date: string, previous_event_index: string, type_day: string, name_of_contract: string, is_obligation: string, name_events: string, created_at: string, sum: null, type: null, number: string, updated_at: string, is_unread: boolean, currency: string, days_count: null, id: string, type_date: string, who: []}} */
        const obligationTemplate = {
            ...NEW_OBLIGATION_TEMPLATE,
            ...{custom: true },
        };

        setNewObligationsList(newObligationsList + 1);

        const contractToUpdate = contract;
        contractToUpdate.events.push(obligationTemplate);
        setContract(contractToUpdate);
    }

    const handleSidesChange = (sidesList) => {
        const newContract = contract;
        if (sidesList.length > 0) {
            sidesList.forEach((side, index) => {
                if (side.updated) {
                    Object.keys(newContract.sides_of_contract[index]).forEach((objectKey) => {
                        const updatedValue = side.updated[(objectKey === 'INN') ? 'inn' : objectKey];
                        if (updatedValue) {
                            newContract.sides_of_contract[index][objectKey] = updatedValue;
                        }
                    });
                }
            });
        }

        setContract(newContract);
    }

    const handleContractTypeChange = (selectedValue) => {
        const selectedElement = document.querySelector(`option[value="${selectedValue}"]`);

        if (selectedElement) {
            const newContract = contract;
            newContract.type_of_contract = selectedElement.innerText;
            setContract(newContract);
        }
    }

    return (
        <>
            <DocumentsModal modalOpen={modalOpen} modalType={modalType} onModalClose={handleModalClose}/>
            <DocumentLoadScreen isOnLoad={isOnload} neuralEventsPresented={status && status === 'success'}/>

            <div className={clsx(`documents__panel`, {'panel__onload': isOnload})}>
                <a className={`button__back`} href={`/dashboard`}>
                    <img src={`/img/arrow__back.svg`} alt={``}/>
                </a>

                <div className={`documents__viewer-container`}>
                    <div className={`documents__viewer-title`}>
                        {title}
                    </div>
                    {activeDocument && (
                        <DocumentViewer
                            documentUri={activeDocument}
                            callbackOnDocumentLoad={handleDocumentLoad}
                        />
                    )}
                </div>

                <div className={`documents__events-list`}>
                    <DocumentSides callbackOnEdit={handleSidesChange} sidesList={sidesList}/>

                    <div className={`documents__events-element`}>
                        <div className={`documents__event-title`}>
                            ВИД ДОГОВОРА
                        </div>

                        <div className={`documents__event-sides`}>
                            <div className={`event-sides__row`} data-row-id={1}>
                                <ContractTypeSelect
                                    contractType={contractType}
                                    callbackOnChange={handleContractTypeChange}/>
                            </div>
                        </div>
                    </div>

                    {contractSum && (
                        <div className={`documents__events-element`}>
                            <div className={`documents__event-title`}>СУММА ДОГОВОРА</div>

                            <div className={`documents__event-sides`}>
                                <div className={`event-sides__row row__wrappable`} data-row-id={1}>
                                    <input
                                        type={'text'}
                                        className={`event-sides__input input__datetime`}
                                        onChange={handleContractSumChange}
                                        defaultValue={contractSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ?? 0.0}
                                    />

                                    <SelectCurrency
                                        defaultValue={contractCurrency ?? 'RUB'}
                                        className={`event-sides__select select__date-type`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <EventContainer
                        eventsList={eventsList}
                        handleEventsChange={handleEventsChange}
                        onEventDelete={handleEventsDelete}
                        onCustomChange={handleCustomChange}
                    />

                    <div className={'documents__events-element'}>
                        <button className={`button__add-obligation`} onClick={handleObligationCreate}>
                            <img src={'../../img/icon__add-event.svg'} alt={``} />
                            Обязательство
                        </button>
                    </div>

                    <BoundedDocuments onModalShow={handleModalShow}/>

                    <button className={`documents__button-control`} onClick={handleDocumentControl}>
                        {(activeDocument && activeDocument.is_on_control === 1)
                            ? 'СНЯТЬ С КОНТРОЛЯ'
                            : 'ПОСТАВИТЬ НА КОНТРОЛЬ'
                        }
                    </button>

                </div>
            </div>
        </>
    )
}

export default Control;