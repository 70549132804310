import React, {useEffect, useRef} from "react";
import DashboardContent from "../Components/dashboard/DashboardContent";
import DashboardModalWindow from "../Components/dashboard/DashboardModalWindow";

import './styles/dashboard.scss';

const Dashboard = () => {
    const inputDocumentRef = useRef(null);

    return (
        <>
            <DashboardModalWindow/>
            <DashboardContent inputDocumentRef={inputDocumentRef}/>
        </>
    );
}

export default Dashboard;