import React from "react";

import ClaimSendDocument from "./ClaimSendDocument";

/**
 * Список документов для формы отправки претензии
 * @param props
 * @returns {Element}
 * @constructor
 */
const DocumentsList = props => {
    const { documents, deleteDocument, handleClickToUploadFiles, handleFilesChange, fileInputRef } = props;

    return (
        <div className={`claim-send__documents-wrapper`}>
            <div className={`claim-send__documents`}>
                <div className={`claim-send__documents-content`}>
                    {documents && documents.map((document) => (
                        <ClaimSendDocument
                            key={document.id}
                            document={document}
                            deleteDocumentCallback={() => deleteDocument(document.id)}
                        />
                    ))}
                </div>
                <button className={`claim-send__add-document`} onClick={handleClickToUploadFiles}>
                    Добавить документ
                </button>
                <input type="file" hidden onChange={handleFilesChange} multiple ref={fileInputRef}/>
            </div>

            {/* Блок нужен для отступа вертикального скролбара от правого края блока */}
            <div className={`claim-send__empty-block`}></div>
        </div>
    )
}

export default DocumentsList;