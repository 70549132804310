import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import clsx from "clsx";

import ViolationIcon from "./ViolationIcon";
import IgnoreClaimButton from "./IgnoreClaimButton";

import '../../Pages/Documents/styles/monitoringClaimEdit.scss';
import '../../Pages/Documents/styles/monitoringClaimEdit.css';

const MonitoringClaimEditEvent = ({event, onEventSave}) => {
    const [eventState, setEventState] = useState(event);
    const [isIgnored, setIsIgnored] = useState(event.is_ignored ?? false);
    const sumRef = useRef(null);
    const dateRef = useRef(null);
    const nameRef = useRef(null);

    useEffect(() => {

    }, []);

    const handleDisableControl = () => {
        const eventToUpdate = eventState;
        if (!eventToUpdate.is_ignored) {
            eventToUpdate.is_ignored = true;
        } else {
            delete eventToUpdate.is_ignored;
        }

        setEventState(eventToUpdate);
        setIsIgnored(eventToUpdate?.is_ignored ?? false);
        onEventSave(eventToUpdate);
    }

    const saveEvent = (e) => {
        e.preventDefault();
        const eventToUpdate = eventState;
        eventToUpdate.name_events = nameRef?.current.value;

        if (event.sum) {
            eventToUpdate.sum = sumRef?.current.value;
        }

        eventToUpdate.date = dateRef?.current.value;
        eventToUpdate.custom = true;

        setEventState(eventToUpdate);
        onEventSave(eventToUpdate);
    }

    const dateParsed = (eventDate) => eventDate && eventDate?.split('.')?.reverse()?.join('-')

    return (
        <>
            <div className={clsx({'event__not-controlling': isIgnored}, `content-row`)}>
                <div className={`content-row__top`}>
                    <h3>{event.type}</h3>
                    <div className={`content-row__top-inputs`}>

                        {event.sum && (
                            <div className={`content-row__top-inputs--price-block`}>
                                <input ref={sumRef} type="text" className={`content-row__top-inputs--price`}
                                       defaultValue={event.sum}/>
                                <p>руб.</p>
                            </div>
                        )}

                        <input ref={dateRef} type="date" className={`content-row__top-inputs--calendar`} defaultValue={dateParsed(event.date)}/>

                        {event.name_events && (
                            <div className={`content-row__top-inputs--document-name-block`}>
                                <input ref={nameRef} type="text" className={`content-row__top-inputs--document-name`} defaultValue={event.name_events}/>
                                <img src={`../../../../img/monitoring/input__edit.svg`} alt={``}/>
                            </div>
                        )}

                        <IgnoreClaimButton isIgnored={isIgnored} handleDisableControl={handleDisableControl} />

                    </div>
                    <div className={`content-row__top-actions`}>
                        <img src={`../../../../img/monitoring/tip__icon.svg`} alt={``}/>

                        <button className={`top-actions__save-button`} onClick={saveEvent}>
                            Сохранить
                        </button>
                    </div>
                </div>
                <div className={`content-row__bottom`}>
                    <img src={`../../../../img/monitoring/warning__icon.svg`} alt={``}/>
                    {(event?.violated) ? (
                        <ViolationIcon period={event?.violation_date ?? moment.now()}></ViolationIcon>
                    ) : (
                        <p>Нарушение: не выявлено</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default MonitoringClaimEditEvent;