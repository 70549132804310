import React from "react";
import Sidemenu from "../Components/sidemenu/Sidemenu";

import './styles/counterparties.scss';

const Counterparties = () => {

    const tableHeadCaptions = ['', 'Трек-номер', 'Получатель', 'Дата отправки', 'Отчет о доставке', 'Списано с аванса'];
    const reportMock = `http://185.84.162.3:4036/media/contracts/020ba3f8-c2bc-4d96-ae17-6b1c424b1dd9.docx`;
    const counterPartiesMock = [
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'},
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'},
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'},
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'},
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'},
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'},
        {trackNumber: '1223', receiver: 'тест', date: '12.12.2024', report: reportMock, price: '100'}
    ]

    return (
        <>
            <div className={`claim-edit__container`}>
                <Sidemenu/>

                <div className={`counterparties__container`}>
                    <table className={`counterparties__table`}>
                        <thead>
                        <tr>{tableHeadCaptions.map((tableHeadCaption) => (<th>{tableHeadCaption}</th>))}</tr>
                        </thead>
                        <tbody>
                        {counterPartiesMock.map((counterParty, index) => {
                            return (
                                <tr>
                                    <td>{index}</td>
                                    <td>{counterParty.trackNumber}</td>
                                    <td>{counterParty.receiver}</td>
                                    <td>{counterParty.date}</td>
                                    <td><a href={counterParty.report}>Скачать</a></td>
                                    <td>{counterParty.price}</td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>

                    <button className={`counterparties__button`}>
                        Пересчитать остаток депозита на начало текущего месяца
                    </button>
                </div>

            </div>
        </>
    );
}

export default Counterparties;