import React, {useEffect, useState} from "react";
import Sidemenu from "../Components/sidemenu/Sidemenu";
import IntegrationServiceContainer from "../Components/settings/IntegrationServiceContainer";

import './styles/integations.scss';
import {APP_URL, useBearerToken} from "../API/requests";

const Integrations = () => {
    const axiosInstance = useBearerToken();

    const services = [
        {title: `1С: Бухгалтерия`, active: true, settigs: {key: `242354326346`, username: `1c-user`}},
        {title: `Банк-клиент`, active: true, settigs: {key: `242354326346`, username: `1c-user`}},
        {title: `Контур.Диадок`, active: true, settigs: {key: `242354326346`, username: `1c-user`}},
        {title: `Мой.арбитр`, active: true, settigs: {key: `242354326346`, username: `1c-user`}},
        {title: `Почта России`, active: false, settigs: {key: `242354326346`, username: `1c-user`}},
    ];

    const [servicesList, setServicesList] = useState([]);

    useEffect(() => {
        axiosInstance.get(`${APP_URL}me/integrations/`).then((response) => {
            const { data } = response;

            if (data && data.length > 0) {
                setServicesList(data);
            }
        }).catch((error) => {
            console.log(`error: ${error}`);
        })
    }, []);

    const handleStatusChange = (e) => {
        console.log(e.target);
    }

    return (
        <div className={`settings__container`}>
            <Sidemenu/>

            <div className={`settings__content-wrapper`}>
                <h1>
                    Настройка интеграции с внешними сервисами
                </h1>

                <section className={`integrations__container`}>
                    <h1>Сервисы:</h1>
                    <div className={`integrations__list`}>
                        {servicesList && servicesList.length > 0 && (
                            servicesList.map((service, index) => {
                                return (
                                    <IntegrationServiceContainer
                                        key={`service-container__${index}`}
                                        service={service}
                                        onStatusChange={handleStatusChange}
                                    />
                                );
                            })
                        )}
                    </div>

                    <button className={`integrations__button-save`}>
                        Сохранить
                    </button>
                </section>
            </div>
        </div>
    );
}

export default Integrations;