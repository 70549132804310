import React, {useEffect, useRef, useState} from "react";

import './styles/claimSend.scss';
import {useNavigate} from "react-router-dom";

const ClaimSendPopupCancel = ({
                                  togglePopupCallback
                              }) => {
    const navigate = useNavigate();

    const togglePopup = () => {
        togglePopupCallback('cancel')
    }

    const handleControlCancel = () => {
        return navigate('/claims/create');
    }

    return (
        <>
            <div className={`claim-send__popup-wrapper`}>
                <div className={`claim-send__popup claim-send__popup--cancel`}>
                    <div className={`claim-send__popup__header`}>
                        <h2>Вы уверены, что хотите отменить контроль?</h2>
                        <img
                            className={`claim-send__popup__header--close-btn`}
                            onClick={togglePopup}
                            src={`/img/monitoring/close__icon.svg`}
                            alt={``}
                        />
                    </div>
                    <div className={`claim-send__popup__content`}>
                        <p>Настройки контроля будут сброшены.</p>
                        <p>Претензия будет доступена в разделе Документы</p>
                    </div>
                    <div className={`claim-send__popup-actions`}>
                        <button
                            className={`claim-send__popup-actions-btn claim-send__popup-actions-btn--red claim-send__popup-actions-btn--sm`}
                        >Нет
                        </button>
                        <button
                            className={`claim-send__popup-actions-btn claim-send__popup-actions-btn--transparent claim-send__popup-actions-btn--sm`}
                            onClick={handleControlCancel}
                        >
                            Да
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClaimSendPopupCancel;