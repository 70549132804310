import RoutesList from "./Routes/RoutesList";

import "./App.scss";
import "./App.css";
import NotificationList from "./Components/notifications/NotificationList";

const App = () => {
    return (
        <div className="App">
            <div className={`app__container`}>
                <RoutesList />
                <NotificationList />
            </div>
        </div>
    );
};

export default App;
