import { useState } from "react";

const Hint = ({ text }) => {
    const [isOpenHint, setIsOpenHint] = useState(false);
    const [iconUrl, setIconUrl] = useState("/img/claims/controls__question.svg");

    const handleMouseEnter = () => {
        setIconUrl("/img/claims/controls__question_hover.svg");
        setIsOpenHint(true);
    };

    const handleMouseLeave = () => {
        setIconUrl("/img/claims/controls__question.svg");
        setIsOpenHint(false);
    };

    return (
        <div className={`control-button__question`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isOpenHint && <div className="hint-modal">{text}</div>}
            <img src={iconUrl} alt={``} />
        </div>
    );
};

export default Hint;
