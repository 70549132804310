import React, {useEffect, useState} from "react";
import {APP_URL, useBearerToken} from "../../API/requests";
import {useSearchParams} from "react-router-dom";
import DocumentViewer from "../../Components/doc-viewer/DocumentViewer";
import DocumentLoadScreen from "../../Components/doc-viewer/utils/DocumentLoadScreen";
import clsx from "clsx";

const Preview = ({}) => {
    const axiosInstance = useBearerToken();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeDocument, setActiveDocument] = useState(null);
    const [isOnload, setIsOnload] = useState(true);
    const [status, setStatus] = useState(null);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        const selectedContract = searchParams.get('contract');
        axiosInstance.get(`${APP_URL}contracts/${selectedContract}/`).then((response) => {
            const {data} = response;
            if (data?.contract) {
                setActiveDocument(data.contract);
                setStatus(data.status);
                setTitle(data.name_of_contract);
            }
        })
    }, []);

    const handleDocumentLoad = () => {
        setIsOnload(false);
    }

    const handlePrint = () => {
    }

    return (
        <>

            <DocumentLoadScreen
                isOnLoad={isOnload}
                neuralEventsPresented={status && status === 'success'}
                isPreview={true}
            />

            <div className={clsx(`documents__panel documents__panel-preview`, {'panel__onload': isOnload })}>
                <a className={`button__back`} href={`/monitoring`}>
                    <img src={`/img/arrow__back.svg`} alt={``}/>
                </a>

                <div className={`documents__viewer-container`}>
                    <div className={`documents__viewer-title`}>
                        {title}
                    </div>
                    <DocumentViewer
                        documentUri={activeDocument}
                        callbackOnDocumentLoad={handleDocumentLoad}
                    />

                    <div className={`document__viewer-buttons`}>
                        <a href={'http://185.84.162.3:443' + activeDocument} download={true} target={`_blank`} className={`actions__button`}>
                            скачать
                            <img src={`/img/monitoring/download.svg`} className={`actions__button-icon`} alt=''/>
                        </a>
                        <button className={`actions__button`} onClick={handlePrint}>
                            распечатать
                            <img src={`/img/monitoring/print.svg`} className={`actions__button-icon`} alt=''/>

                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Preview;