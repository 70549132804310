import React from "react";

const SelectCurrency = ({defaultValue, className}) => {
    return (
        <select
            className={className}
            defaultValue={defaultValue}
        >
            <option value={'RUB'}>руб.</option>
            <option value={'USD'}>долл. США</option>
            <option value={'EUR'}>евро</option>
            <option value={'GBP'}>британский фунт стерлингов</option>
            <option value={'CHF'}>швейцарский франк</option>
            <option value={'CNY'}>китайский юань</option>
            <option value={'JPY'}>японская иена</option>
        </select>
    );
}

export default SelectCurrency;