import React from "react";
import clsx from "clsx";

const DashboardModalWindow = ({ isOpen }) => {
    return (
        <div className={clsx({ 'modal__open': isOpen }, `dashboard__modal-container`)}>
            <div className={`dashboard__modal-wrapper`}>

            </div>
        </div>
    );
}

export default DashboardModalWindow;