import React, {useState} from "react";
import clsx from "clsx";

const ModalSpecificationContent = () => {
    const [file, setFile] = useState(null);
    const [buttonActive, setButtonActive] = useState(false);

    const handleLoadFile = () => {

    };

    return (
        <>
            <div className={`documents__modal-title`}>
                Загрузить документ
            </div>
            <input
                type={`file`}
                className={`form__input-hidden`}
            />
            <button
                onClick={handleLoadFile}
                className={`button__load`}
            >
                Выбрать расположение
            </button>
            <button
                className={`button__save-reaction with__full-width`}
                disabled={!buttonActive}
            >
                Загрузить
            </button>
        </>
    );
}

export default ModalSpecificationContent;