import React from "react";
import clsx from "clsx";
import CustomProgress from "./CustomProgress";

import {STORAGE_URL} from "../../API/requests";

const ModalFileForm = ({
                           avatarPreview,
                           fileRef,
                           fileInputText,
                           responseIcon,
                           progressVisible,
                           progressValue,
                           callbackOnFileUpload,
                           callbackOnFilenameChange
                       }) => {

    const handleFileUpload = () => {
        callbackOnFileUpload();
    }
    const handleFilenameChange = () => {
        callbackOnFilenameChange();
    }
    return (
        <>
            {avatarPreview && (
                <div className={`avatar__preview`}>
                    <img src={`${STORAGE_URL}/${avatarPreview}`} alt={``}/>
                </div>
            )}
            <div className={`modal-input__file`}>
                <div
                    ref={fileRef}
                    className={`modal-input__title`}
                    onClick={handleFileUpload}
                >
                    <input
                        type={`file`}
                        name={`avatar`}
                        className={`form__input-hidden`}
                        onChange={handleFilenameChange}
                    />

                    <div className={`modal-input__title-text`}>
                        <div className={`title-text__content`}>
                            {fileInputText}
                        </div>
                        <div className={clsx(
                            {[`hidden`]: !progressVisible},
                            `title-text__percentage`
                        )}>
                            {progressValue} %
                        </div>
                    </div>
                    <div className={`modal-input__title-image`}>
                        <img
                            src={`/img/upload__${(responseIcon)}.svg`}
                            alt={``}
                        />
                    </div>
                </div>

                <div className={clsx({[`hidden`]: !progressVisible}, `modal-input__progress`)}>
                    <CustomProgress value={progressValue}/>
                </div>
            </div>
        </>
    )
}

export default ModalFileForm;