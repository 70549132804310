import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider, {getTheme} from "./Helpers/Providers/ThemeProvider";
import {Provider} from "react-redux";

import {store} from "./Redux/reduxStore";

const root = ReactDOM.createRoot(document.getElementById('root'));

// эта строчка нужна для того, чтобы избежать пролага применения темной темы, вызывающего мерцание
document.querySelector('html').setAttribute('data-theme', getTheme());

root.render(
  <React.StrictMode>
      <ThemeProvider>
          <Provider store={store}>
              <App />
          </Provider>
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
