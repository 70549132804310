import React, {useEffect, useState} from "react";

import MonitoringButton from "./MonitoringButton";
import {useNavigate} from "react-router-dom";
import {APP_URL, useBearerToken} from "../../API/requests";

const MonitoringActions = ({
                               statesList,
                               selectedEntity,
                               callbackOnEntityChange,
                               callbackOnEntityDelete,
                               activeTypeFilter,
                               handleStopCourtsControl
                           }) => {
    const [currentStatesList, setCurrentStatesList] = useState([]);
    const navigate = useNavigate();
    const axiosInstance = useBearerToken();

    useEffect(() => {
        setCurrentStatesList(statesList);
    }, []);

    const resolveButtonStatus = (button) => {
        const {id} = button;
        let disabledByDefault = !selectedEntity;
        if (id === "control") {
            if (selectedEntity && selectedEntity.is_on_control) {
                disabledByDefault = true;
            }
        }

        if (id === "stop_control") {
            if (selectedEntity && !selectedEntity.is_on_control) {
                disabledByDefault = true;
            }
        }

        if (id === "claim") {
            if (selectedEntity && !selectedEntity.claims) {
                disabledByDefault = true;
            }
        }

        return disabledByDefault;
    };

    const handleControl = (e) => {
        return navigate(`/control?contract=${selectedEntity.id}`);
    };

    const handleStopControl = (e) => {
        axiosInstance
            .patch(`${APP_URL}contracts/${selectedEntity.id}`, {
                is_on_control: false
            })
            .then((response) => {
                const {data} = response;
                callbackOnEntityChange(selectedEntity, {is_on_control: false});
            });
    };

    const handleEdit = (e) => {
        return navigate(`/monitoring/${selectedEntity.id}/claims/edit`);
    };

    const handleCreateClaim = (e) => {
    };

    const handlePreview = () => {
        return navigate(`/preview?contract=${selectedEntity.id}`);
    };

    const handleTakeToCourt = () => {
        return navigate(`/monitoring/${selectedEntity.id}/take-to-court`);
    };

    const handleDownload = () => {
        const temporaryLink = document.createElement("a");
        temporaryLink.setAttribute("href", `http://185.84.162.3:443${selectedEntity.contract}`);
        temporaryLink.setAttribute("download", true);
        temporaryLink.setAttribute("target", "_blank");
        temporaryLink.click();
        return 0;
    };

    const handleDelete = () => {
        return axiosInstance.delete(`${APP_URL}contracts/${selectedEntity.id}`).then((response) => {
            const {data} = response;

            if (data) {
                callbackOnEntityDelete(selectedEntity.id);
            }
        })
    }

    const buttons = {
        contracts: {
            topList: [
                {id: `control`, title: `Поставить на контроль`, icon: `control`, action: handleControl},
                {id: `edit`, title: `Редактировать`, icon: `edit`, action: handleEdit},
                {id: `stop_control`, title: `Отменить контроль`, icon: `stop_control`, action: handleStopControl},
                {id: `claim`, title: `Создать претензию`, icon: `claim`, action: handleCreateClaim}
            ]
        },
        claims: {
            topList: [
                {id: `control`, title: `Поставить на контроль`, icon: `control`, action: handleControl},
                {id: `edit`, title: `Редактировать`, icon: `edit`, action: handleEdit},
                {id: `stop_control`, title: `Отменить контроль`, icon: `stop_control`, action: handleStopControl},
                {id: `claim`, title: `Создать претензию`, icon: `claim`, action: handleCreateClaim}
            ]
        },
        "incoming-claims": {
            topList: [
                {id: `control`, title: `Поставить на контроль`, icon: `control`, action: handleControl},
                {id: `edit`, title: `Редактировать`, icon: `edit`, action: handleEdit},
                {id: `stop_control`, title: `Отменить контроль`, icon: `stop_control`, action: handleStopControl},
                {id: `claim`, title: `Создать претензию`, icon: `claim`, action: handleCreateClaim}
            ]
        },
        courts: {
            topList: [
                {id: `court`, title: `Подать в суд`, icon: `court`, action: handleTakeToCourt},
                {
                    id: `stop_courts_control`,
                    title: `Отменить контроль`,
                    icon: `stop_control`,
                    action: handleStopCourtsControl
                }
            ]
        }
    };

    const buttonsBottomList = [
        {id: `preview`, title: `Предпросмотр`, icon: `preview`, action: handlePreview},
        {id: `print`, title: `Печать`, icon: `print`},
        {id: `download`, title: `Скачать`, icon: `download`, action: handleDownload},
        {id: `delete`, title: `Удалить`, icon: `delete`, action: handleDelete},
    ];

    return (
        <div className={`monitoring__actions`}>
            <div className={`actions__content`}>
                <div className={`actions__content-top`}>
                    <div className={`actions__title-small`}>Название</div>
                    <div className={`actions__title-large`}>
                        {selectedEntity ? selectedEntity.name_of_contract : "Название сущности"}
                    </div>

                    <div className={`actions__buttons-top`}>
                        {buttons[activeTypeFilter].topList.map((button) => {
                            const {id, icon, title, action} = button;
                            const disabledByDefault =
                                selectedEntity && selectedEntity.status === "success"
                                    ? resolveButtonStatus(button)
                                    : true;

                            return (
                                <MonitoringButton
                                    key={id}
                                    icon={icon}
                                    title={title}
                                    action={action}
                                    disabled={disabledByDefault}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={`actions__content-bottom`}>
                    <div className={`actions__buttons-bottom`}>
                        {buttonsBottomList.map((button) => {
                            const {id, icon, title, action} = button;
                            return (
                                <MonitoringButton
                                    key={id}
                                    icon={icon}
                                    title={title}
                                    action={action}
                                    disabled={!selectedEntity}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitoringActions;
