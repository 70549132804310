/**
 * Начальное состояние формы регистрации
 * @type {[{display: boolean, name: string, placeholder: string, type: string},{display: boolean, name: string, placeholder: string, type: string, allowHide: boolean},{display: boolean, name: string, placeholder: string, type: string, allowHide: boolean},{display: boolean, name: string, placeholder: string, type: string}]}
 */
export const registerFormState = [
    {
        display: true,
        placeholder: `Электронная почта`,
        type: 'email',
        name: 'email',
    },
    {
        display: false,
        placeholder: `Придумайте пароль`,
        type: `password`,
        name: `password`,
        allowHide: true,
    },
    {
        display: false,
        placeholder: `Подтвердите пароль`,
        type: `password`,
        name: `password_repeat`,
        allowHide: true,
    },
    {
        display: false,
        placeholder: `Имя Фамилия`,
        type: `text`,
        name: `username`,
    },
]

export const loginFormState = [
    {
        display: true,
        placeholder: `Электронная почта`,
        type: 'email',
        name: 'email',
    },
    {
        display: false,
        placeholder: `Введите пароль`,
        type: `password`,
        name: `password`,
        allowHide: true,
    },
]

export const resetPasswordFormState = [
    {
        display: true,
        placeholder: `Электронная почта`,
        type: `email`,
        name: `email`,
    }
];

export const passwordTokenFormState = [
    {
        display: true,
        placeholder: `Пароль`,
        type: `password`,
        name: `password`,
    },
    {
        display: true,
        placeholder: `Подтвердите пароль`,
        type: `password`,
        name: `password_repeat`,
    }
];

export const registerPromoFormState = [
    {
        display: true,
        placeholder: `Промокод`,
        type: 'text',
        name: 'promo',
    },
    {
        display: true,
        placeholder: `Электронная почта`,
        type: 'email',
        name: 'email',
    },
    {
        display: false,
        placeholder: `Придумайте пароль`,
        type: `password`,
        name: `password`,
        allowHide: true,
    },
    {
        display: false,
        placeholder: `Подтвердите пароль`,
        type: `password`,
        name: `password_repeat`,
        allowHide: true,
    },
    {
        display: false,
        placeholder: `Имя Фамилия`,
        type: `text`,
        name: `username`,
    },
]